const updateObjectWithProperties = <T = Record<string, any>>(
  object: T,
  properties: Record<string, any>,
  excludes: string[] = []
) => {
  for (const key in properties) {
    if (!excludes.includes(key)) object[key] = properties[key]
  }
  return object
}

export default updateObjectWithProperties
