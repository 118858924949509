import axios, { type AxiosRequestConfig } from "axios"

interface Param {
  url: string
  callback?: (data: any) => void
  config?: AxiosRequestConfig
}

export default function ({
  url,
  callback = (data) => {},
  config = {}
}: Param) {
  return new Promise((resolve, reject) => {
    const runFetch = (retry = 0) => {
      try {
        if (retry > 3) return reject("Failed to fetch after 3 tries")
        axios
          .get(url, config)
          .then(response => {
            callback(response)
            resolve(response)
          })
          .catch(() => runFetch(retry + 1))
      } catch (error) {}
    }
    runFetch(0)
  })
}