<template>
  <div class="animated fadeIn wrapped-with-jyqvqqkvtk">
    <VTable
      :title="$t('files')"
      :subtitle="$t('files_subtitle')"
      :add-text="$t('add_files')"
      :columns="tableColumns"
      :action-delete="false"
      :show-add-button="true"
      :add-button-click="() => (showModals.upload = true)"
      :make-form="() => ({})"
      :table-options="tableOptions"
      :data="fileStore.all"
      :row-click="() => {}"
    >
      <template #actions="{ row }">
        <div class="col-lg-12 button-box text-nowrap">
          <!--
          TODO: Confirm what is this preview button for
          Previous frontend has this button which is supported by `/v1/files/{id}/images` endpoint
          But python3 backend doesn't have this endpoint

          <CButton
            v-if="row.extension"
            size="sm"
            color="primary"
            @click.stop="openViewer(row)"
          >
            <i class="fa fa-eye" />
            Preview
          </CButton> -->
          <CButton
            class="ms-2"
            color="primary"
            size="sm"
            @click.stop.prevent="fileStore.download(row.id)"
          >
            <i class="fa fa-download" />
            {{ $t("download") }}
          </CButton>
        </div>
      </template>
      <template #size="{ row }">
        <span>{{ (row.size / 1024).toFixed(2) }} KB</span>
      </template>
      <template #access="{ row }">
        <CBadge v-if="row.access.length === 0" color="secondary">
          {{ $t("private") }}
        </CBadge>
        <CBadge v-if="row.access.length === 1" color="secondary">
          {{ row.access.length }} {{ $t("user") }}
        </CBadge>
        <CBadge v-if="row.access.length > 1" color="secondary">
          {{ row.access.length }} {{ $t("users") }}
        </CBadge>
      </template>
    </VTable>

    <UploadModal
      v-model="showModals.upload"
      @uploaded="(_, file) => addOrUpdate(fileStore.all, file, ['id'])"
      @error="onFileError"
    />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted, ref } from "vue-demi"
import type { File, VTableOptions } from "@/interfaces"
import { fileStore, toastStore } from "@/store"
import { dateFilterAlgorithm, dateToFilterString, addOrUpdate, errorHandler } from "@/libraries/helpers"
import VTable from "@/components/VTables.vue"
import UploadModal from "@/components/modals/UploadModal.vue"

const i18n = useI18n()

useMeta({ title: i18n.t("files") })

const tableColumns = ["name", "extension", "date__created", "access", "size", "actions"]
const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    extension: i18n.t("extension"),
    date__created: i18n.t("created"),
    access: i18n.t("access"),
    size: i18n.t("size"),
    actions: i18n.t("actions"),
  },
  sortable: ["name", "date__created", "access", "size", "extension"],
  filterable: ["name", "date__created", "access", "size", "extension"],
  filterAlgorithm: {
    date__created: (row: File, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    size(row: File, query: string) {
      const str = (row.size / 1024).toFixed(2).toLowerCase()
      return str.includes(query.toLowerCase())
    },
    access(row: File, query: string) {
      let str = "private"
      if (row.access.length >= 1)
        str = `${row.access.length} user${row.access.length > 1 ? "s" : ""}`
      return str.includes(query.toLowerCase())
    },
  },
  customFilters: [
    {
      name: "all",
      callback(row: File, query: string) {
        const values = [
          row.name || "",
          row.extension || "",
          dateToFilterString(row, "created"),
          row.access?.length >= 1
            ? `${row.access.length} user${row.access.length > 1 ? "s" : ""}`
            : "private",
          (row.size / 1024).toFixed(2),
        ]
        return values.join("###").toLowerCase().includes(query.toLowerCase())
      },
    },
  ],
}

const showModals = ref({
  upload: false,
})

const onFileError = (file: any) => {
  const { xhr: response } = file
  const status = response?.status || 500
  toastStore.toasts.push({
    color: "danger",
    message: i18n.t("failed_to_upload", { message: errorHandler.generateErrorMessage(status) || response?.message || JSON.stringify(response || {}) } )
  })
}

onMounted(() => {
  fileStore.fetchAll()
})
</script>

<style lang="scss">
.wrapped-with-jyqvqqkvtk {
  .viewer-modal .viewer {
    position: absolute;
    z-index: 10;
    height: 100%;
    width: 100%;
  }

  .viewer-modal img.origin {
    position: absolute;
    right: 0;
    bottom: 0;
    border: none;
    width: 200px;
    height: 200px;
    pointer-events: none;
  }
}
</style>
