import { defineStore } from "pinia"
import { forEach } from "lodash-es"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { addOrUpdate, paginatedFetch } from "@/libraries/helpers"

export default defineStore("plan", {
  state: () => ({
    all: [],
  }),
  getters: {
  },
  actions: {
    async fetchAll({ persist = false } = {}) {
      await paginatedFetch({
        url: "/v1/plans",
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
  },
})
