<template>
  <div
    id="whole-page-dropzone"
    class="wrapped-with-a547dae7sdf dropzone"
    :class="{ visible }"
  >
    <div class="dz-default dz-message">
      <CIcon icon="fa-cloud-upload" class="fa-4x" size="xxl"/>
      <br />
      <CButton color="primary" shape="rounded-pill">
        <h4 class="mb-0">{{ $t("drop_files_to_upload") }}</h4>
      </CButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue-demi"
import { Dropzone } from "dropzone"
import { globalStore } from "@/store"
import { defaultDropzoneOptions } from "@/constants"

const emit = defineEmits(["initialized", "addedfile", "success", "complete", "processing", "error"])

const visible = ref<boolean>(false)
const dropzone = ref<any>(null)

function addEventListeners() {
  dropzone.value.on(
    "addedfile",
    file => {
      // remove added file if still busy
      if (globalStore.wholePageDropzone.busy) return dropzone.value.removeFile(file);

      Object.prototype.hasOwnProperty.call(globalStore.wholePageDropzone.actions, "addedfile")
        ? globalStore.wholePageDropzone.actions.addedfile(file)
        : emit("addedfile", file)
    }
  )

  dropzone.value.on(
    "success",
    (file, response) => Object.prototype.hasOwnProperty.call(globalStore.wholePageDropzone.actions, "addedfile")
      ? globalStore.wholePageDropzone.actions.success(file, response)
      : emit("success", file, response)
  )

  dropzone.value.on(
    "error",
    (file, error) => Object.prototype.hasOwnProperty.call(globalStore.wholePageDropzone.actions, "error")
      ? globalStore.wholePageDropzone.actions.error(file, error)
      : emit("error", file, error)
  )
}

const initialize = () => {
  const isInitialized = !!dropzone.value
  if (isInitialized) return
  dropzone.value = new Dropzone(`#app-container`, {
    ...globalStore.wholePageDropzone.options || {},
    ...defaultDropzoneOptions(),
    acceptedFiles: ".step,.stp,.dxf",
    drop() {
      visible.value = false
      return this.element.classList.remove("dz-drag-hover")
    },
    dragenter() {
      visible.value = true
      return this.element.classList.add("dz-drag-hover");
    },
    dragleave(event) {
      if (event.target.id === "whole-page-dropzone") {
        visible.value = false
        return this.element.classList.remove("dz-drag-hover")
      }
      return null
    },
  })
  addEventListeners()
  emit("initialized", dropzone.value)
}

onMounted(() => {
  initialize()
})

onUnmounted(() => {
  dropzone.value.destroy()
})
</script>

<style lang="scss">
.wrapped-with-a547dae7sdf {
  &.dropzone {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999999;
    background-color: rgba(32, 168, 216, 0.25);
    font-family: "Arial", sans-serif;
    color: #777;
    &.visible {
      display: flex;
      justify-content: center;
      & .dz-message {
        position: absolute;
        bottom: 0px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .fa-icon {
        color: rgba(32, 168, 216, 1);
        animation-name: uploadIconAnimation;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        position: relative;
      }
      .btn {
        margin-top: 0.5rem;
        padding: 1rem 4rem;
        color: rgb(176 220 240);
      }
    }
  }
}
@keyframes uploadIconAnimation {
  0%   { top: 0; }
  25%  { top: 15px; }
  50%  { top: 0; }
  75%  { top: 15px; }
  100% { top: 0; }
}
</style>
