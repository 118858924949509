import type { File, Material, Organization } from "."
import type { Modify, Nullify, Require } from "./_utils"

export const partOperationOptions = [
  "LABELING",
  "PREPARATION",
  "PACKING",
  "ASSEMBLY",
  "INSERT",
  "BRUSH",
  "DEBUR",
  "ROLL",
  "BEND",
  "CUT",
  "OTHER",
] as const

export const partSectionTypeOptions = ["RECTANGULAR", "SQUARE", "ROUND"] as const

export const partTypeOptions = ["ASSEMBLY", "TUBE", "SHEET", "OTHER"] as const

type _UpdatePart = {
  name?: string
  reference?: string
  description?: string
}

type _CreatePart = Modify<
  _UpdatePart,
  {
    source: number // File ID of the source file
    customer?: number // Organization ID of the customer
  }
>

export type ComponentTree = {
  name: string
  level: number // integer
  index: number // integer
  count: number // integer
  reference: number // integer
  components: ComponentTree[]
  translation: number[]
  orientation: number[]
  is_assembly: boolean
}

type _PartParent = {
  part: number // Part ID of the parent part
  reference: number // The index reference of the parent component
  index: number // The index of the parent component
  shape: number // The shape index in the parent component
}

export type _BasePart = Modify<
  _CreatePart,
  {
    id: number // id
    filename: string // Filename of the original file

    parent?: _PartParent

    material?: number // Material ID of the part
    area: number // Constraints: Min 0, The area of the final part in mm^2
    volume: number // Constraints: Min 0, The volume of the final part in mm^3
    weight: number // Constraints: Min 0, The weight of the final part in kg
    width: number // Constraints: Min 0, The width of the final part in mm
    height: number // Constraints: Min 0, The height of the final part in mm
    length: number // Constraints: Min 0, The length of a tube part in mm

    operations: typeof partOperationOptions[number][]

    bends: {
      // The bends in case of a bended sheet part
      deduction: number // Constraints: Min 0, The bend deduction applied to the bend
      angle: number // The angle of the bend in degrees
      group: number // A common group number a bend belongs to
      k_factor: number // Constraints: Min 0, The k-factor applied to the bend
      allowance: number // Constraints: Min 0, The bend allowance applied to the bend
      radius: number // Constraints: Min 0, The inner radius of the bend in mm
      length: number // Constraints: Min 0, The length of the bend in mm
    }[]

    section?: {
      outer_radius: number // Constraints: Min 0, The outer radius of the corners or tube in mm
      thickness: number // Constraints: Min 0, The thickness in mm
      hash: string // Section hash for this specific shape
      inner_radius: number // Constraints: Min 0, The inner radius of the corners or tube in mm
      length: number // Constraints: Min 0, The length of the to be used section in mm
      type: typeof partSectionTypeOptions[number]
      height: number // Constraints: Min 0, The outer height of the section in mm
      width: number // Constraints: Min 0, The outer width of the section in mm
    }

    pattern?: {
      holes: {
        // The holes of the unfolded part
        area: number // The surface area of the contour in mm^2
        boundary: number // Constraints: Min 0, The length of the contour boundary in mm
      }[]

      area: number // Constraints: Min 0, The surface area of the unfolded part in mm^2
      angle: number // The angle to to indicate the preferred orientation in degrees
      boundary: number // Constraints: Min 0, The length of the unfolded part boundaries in mm
      thickness: number // Constraints: Min 0, The thickness in mm

      contour: {
        area: number // The surface area of the contour in mm^2
        boundary: number // Constraints: Min 0, The length of the contour boundary in mm
      }

      height: number // Constraints: Min 0, The height in mm
      width: number // Constraints: Min 0, The width in mm

      origin: {
        y: number // The y coordinate in mm
        x: number // The x coordinate in mm
      }
    }
    tree?: ComponentTree
    type: typeof partTypeOptions[number]
    created: Date
    updated: Date
    created_by: number // user_id
    updated_by: number // user_id
  }
>

type _AssemblyPart = Modify<
  Require<Nullify<_BasePart, "pattern" | "section">, "tree">,
  {
    type: "ASSEMBLY"
  }
>

type _OtherPart = Modify<
  Nullify<_BasePart, "pattern" | "tree" | "section">,
  {
    type: "OTHER"
  }
>

type _SheetPart = Modify<
  Nullify<Require<_BasePart, "pattern">, "tree" | "section">,
  {
    type: "SHEET"
  }
>

type _TubePart = Modify<
  Nullify<Require<_BasePart, "pattern" | "section">, "tree">,
  {
    type: "TUBE"
  }
>

type _Part = _AssemblyPart | _OtherPart | _SheetPart | _TubePart

export type CreatePart = Modify<
  _CreatePart,
  {
    source: File
    customer?: Organization
  }
>

type PartParent = Modify<
  _PartParent,
  {
    part: Part
  }
>

export type Part = Modify<
  _Part,
  CreatePart & {
    parent: PartParent | null
    material?: Material
    getImage2D: () => string
    getImage3D: () => string
    getModel3D: () => string
  }
>