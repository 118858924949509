<template>
  <Datepicker v-bind="$attrs" :alt-position="calculatePosition">
    <!--
      This is for handling slots added on component usage
    -->
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Datepicker>
</template>

<script lang="ts" setup>
import "@vuepic/vue-datepicker/dist/main.css"
import Datepicker from "@vuepic/vue-datepicker"
import { nextTick } from "vue-demi"

const calculatePosition = (el: HTMLElement) => {
  const calendarHeight = 210
  const multiplier = 1.5 // based on trial and error, this is the best multiplier for positioning the calendar
  const appElement = document.getElementById("app-body")
  const appElementBoundingRect = appElement.getBoundingClientRect()

  const boundingRect = el.getBoundingClientRect()

  let top = boundingRect.height * multiplier
  if (boundingRect.top + top + calendarHeight > appElementBoundingRect.height) {
    top = -calendarHeight * multiplier - boundingRect.height
    nextTick(() => {
      const arrowElement = document.querySelector(".dp__arrow_top")
      if (arrowElement) {
        arrowElement.classList.remove("dp__arrow_top")
        arrowElement.classList.add("dp__arrow_bottom")
      }
    })
  }

  return {
    top,
    left: 0,
    transform: "transformX(0)",
  }
}
</script>
