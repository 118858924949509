<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('tools')"
      :subtitle="$t('bend') + ' ' + $t('tools')"
      :add-text="$t('add_tool')"
      :edit-text="$t('edit_tool')"
      :columns="tableColumns"
      :merge-options="mergeOptions"
      :action-delete="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :action-edit="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :make-form="makeForm"
      :show-merge-options="true"
      :table-options="tableOptions"
      :data="toolStore.mappedData"
      :add="toolStore.add"
      :update="toolStore.update"
      :remove="({ id }) => toolStore.remove(id)"
      :default-sort="{ column: 'name', ascending: true }"
      @merge-done="toolStore.modifyData"
    >
      <template #reference="data">
        <span v-if="data.row.reference">{{ data.row.reference }}</span>
        <div v-else class="text-body-secondary small">no reference</div>
      </template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { pick as _pick, max as _max } from "lodash-es"
import { onMounted, computed } from "vue-demi"
import type { Tool, MergerSchema, VTableOptions } from "@/interfaces"
import { toolStore, materialStore, authStore } from "@/store"
import VTables from "@/components/VTables.vue"
import { dateFilterAlgorithm, dateToFilterString } from "@/libraries/helpers"

const i18n = useI18n()
useMeta({ title: i18n.t("tools") })

const tableColumns = [
  "name",
  "reference",
  "description",
  /* "materials", "values", */
  "date__created",
  "date__updated",
]
const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    reference: i18n.t("reference"),
    description: i18n.t("description"),
    materials: i18n.t("materials"),
    values: i18n.t("values"),
    date__created: i18n.t("created"),
    date__updated: i18n.t("updated"),
  },
  sortable: ["name", "reference", "description", "date__created", "date__updated"],
  filterAlgorithm: {
    date__created: (row: Tool, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    date__updated: (row: Tool, query: string) =>
      dateFilterAlgorithm(row, query, "updated"),
  },
  customFilters: [
    {
      name: "all",
      callback(row: Tool, query: string) {
        return [
          row.name || "",
          row.reference || "",
          row.description || "",
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const makeForm = (data?: Tool) => ({
  id: "tools-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      placeholder: "",
      defaultValue: data?.name,
      validations: ["required"],
    },
    reference: {
      type: "text",
      label: i18n.t("reference"),
      placeholder: "",
      defaultValue: data?.reference,
    },
    description: {
      type: "text",
      label: i18n.t("description"),
      placeholder: "",
      defaultValue: data?.description,
    },
    materials: {
      type: "multiselect",
      label: i18n.t("raw_materials"),
      placeholder: i18n.t("select_or_start_typing"),
      options: materialStore.all,
      defaultValue: data?.materials,
      selectOptions: {
        multiple: true,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: false,
      },
    },
    values: {
      type: "table",
      label: i18n.t("values"),
      defaultValue: data?.values,
      tableOptions: {
        columns: ["thickness", "radius", "k_factor"],
        options: {
          skin: "table table-hover",
          sortIcon: {
            base: "fa fa-lg",
            up: "fa-sort-asc",
            down: "fa-sort-desc",
            is: "fa-sort",
          },
          headings: {
            thickness: i18n.t("thickness"),
            radius: "Radius",
            k_factor: "K-factor",
          },
        },
      },
    },
  },
  valueProcessor: values => {
    delete values.isTrusted
    return values
  },
})

const mergeOptions = computed(() => ({
  whitelist: ["id", "name", "reference", "description", "materials", "values"],
  schema: {
    id: {
      type: "integer",
    },
    name: {
      type: "string",
    },
    reference: {
      type: "string",
    },
    description: {
      type: "string",
    },
    materials: {
      type: "namedObjectArray",
      options: materialStore.all,
    },
    thickness: {
      type: "array",
    },
    radius: {
      type: "array",
    },
    k_factor: {
      type: "array",
    },
  } as MergerSchema,
  dataProcessor: data =>
    data.map(d => {
      let thickness = []
      let radius = []
      let k_factor = []
      if (d.values) {
        thickness = d.values.map(v => v.thickness)
        radius = d.values.map(v => v.radius)
        k_factor = d.values.map(v => v.k_factor)
      }
      return { ...d, thickness, radius, k_factor }
    }),
  valueProcessor: payloads =>
    payloads.map(d => {
      const newPayload: any = _pick(d, [
        "id",
        "name",
        "reference",
        "description",
        "materials",
      ])
      newPayload.values = []

      const valuesLength = _max([
        d.thickness?.length || 0,
        d.radius?.length || 0,
        d.k_factor?.length || 0,
      ])
      for (let i = 0; i < valuesLength; i++) {
        newPayload.values.push({
          thickness: parseFloat(d.thickness[i] || 0),
          radius: parseFloat(d.radius[i] || 0),
          k_factor: parseFloat(d.k_factor[i] || 0),
        })
      }

      return { ...newPayload }
    }),
}))

onMounted(() => {
  toolStore.fetchAll()
})
</script>
