import { formatDistance } from "date-fns"


export default function (value: any) {
  return value
    ? formatDistance(
        new Date(String(value)),
        new Date(Date.now() + new Date().getTimezoneOffset() * 60 * 1000), // get utc datetime
        { addSuffix: true }
      )
    : value
}
