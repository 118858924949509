import type { MaybeRef } from "@vueuse/core"
import type { Manufacturer, Order, Organization, Modify } from "."

export type RegisterUser = {
  email: string // email
  username: string
  password: string

  recaptcha?: string // The reCAPTCHA response retrieved from google
  nickname?: string // Short organization nickname to be used in URI and mailing

  is_company?: boolean // Boolean representing if the user is registering as a private person or as a company
  is_manufacturer?: boolean // Indicates if user is registering as a manufacturer
}

type _CreateUser = {
  email: string // email
  username: string
  password: string

  first_name?: string
  middle_name?: string
  last_name?: string

  organization: number // organization_id

  is_admin?: boolean
  is_internal?: boolean
  is_manufacturer?: boolean
  is_moderator?: boolean
  is_customer?: boolean
  is_verified?: boolean
  is_active?: boolean
}

export type _User = Modify<
  Required<_CreateUser>,
  {
    id: number
    created: Date
    updated: Date
    current_password: string
    token: string
  }
>

export const authGrantTypeOptions = ["password", "refresh_token"] as const

export type PasswordCredentials = {
  username: string
  password: string
  grant_type: typeof authGrantTypeOptions[0]
}

export type RefreshTokenCredentials = {
  grant_type: typeof authGrantTypeOptions[1]
}

export type Credentials = PasswordCredentials | RefreshTokenCredentials

export type RegisterPayload = {
  username: string
  recaptcha?: string
  password: string
  nickname?: string
  email: string
  is_company: boolean
  is_manufacturer: boolean
}

export type ResetPasswordPayload = {
  account: string
}

export type AccessToken = {
  access_token: string
  refresh_token: string
}

export type CreateUser = Modify<_CreateUser, { organization: Organization }>
export type User = Modify<
  _User,
  {
    parentManufacturer?: Manufacturer
    organization: Organization
    isCustomerOf(order?: MaybeRef<Order>): boolean
    isManufacturerOf(order?: MaybeRef<Order>): boolean
    isCreatorOf(order?: MaybeRef<Order>): boolean
  }
>

export const ROLES = {
  ADMIN: "admin",
  MANUFACTURER: "manufacturer",
  CUSTOMER: "customer",
  MANUFACTURER_MODERATOR: "manufacturer-moderator",
  CUSTOMER_MODERATOR: "customer-moderator",
}
