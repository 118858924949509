import type { ComponentTree } from "@/interfaces"

class PartHelpers {
  assemblyTreeLookup(tree: ComponentTree, reference: number): ComponentTree | null {
    if (tree.index === reference) return tree
    for (const component of tree.components) {
      const result = this.assemblyTreeLookup(component, reference)
      if (result) return result
    }
  }
  countTotalByProperty(tree: ComponentTree, currentValue: number = 0, query: any, property: string = "reference"): number {
    if (tree[property] === query) currentValue ++
    for (const component of tree.components) {
      currentValue = this.countTotalByProperty(component, currentValue, query, property)
    }
    return currentValue
  }
}

export default new PartHelpers()
