<template>
  <div class="app d-flex flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol :md="6" class="d-flex justify-content-center">
          <div class="clearfix">
            <h1 class="display-3 me-4">404</h1>
            <h4 class="pt-3">{{ $t("404_title") }}</h4>
            <p class="text-medium-emphasis">
              {{ $t("404_text") }}
            </p>
          </div>
        </CCol>
      </CRow>
      <CRow class="justify-content-center mt-4">
        <CCol :lg="3" :md="4">
          <div class="d-grid">
            <CButton color="primary" block @click="back">
              {{ $t("take_me_back") }}
            </CButton>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script lang="ts" setup>
import { useMeta } from "vue-meta"
import { globalStore } from "@/store"

useMeta({ title: "404" })

function back() {
  // if last time it's has a valid manufacturer and changed to invalid manufacturer
  // when going back, it will goes to the valid manufacturer
  if (
    globalStore.localStorage?.last_valid_manufacturer
    && localStorage["manufacturer"] !== globalStore.localStorage?.last_valid_manufacturer
  )
    localStorage["manufacturer"] = globalStore.localStorage?.last_valid_manufacturer

  location.replace("/")
}
</script>

<style scoped>
.app {
  background-image: url("/img/bg/pattern.svg");
  background-size: cover;
  min-height: 125vh;
}
</style>
