import { defineStore } from "pinia"
import axios from "axios"
import { forEach, pullAt } from "lodash-es"
import type { Address, CreateAddress, CreateOrganization, Organization } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { addressStore, manufacturerStore, requestStore } from "."
import { addOrUpdate, generateTemporaryId, getId, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"

interface CreateAddressWithShipping extends CreateAddress {
  is_shipping?: boolean
  is_billing?: boolean
}


const cacheKey = "organizations"

const mapData = (obj: Organization) => ({
  ...obj,
  addresses: addressStore.all.filter(address => obj?.addresses?.includes(address.id)),
  manufacturers: manufacturerStore.all.filter(manufacturer =>
    obj?.manufacturers?.includes(manufacturer.id)
  ),
})

export default defineStore("organization", {
  state: () => ({
    current: null,
    all: [],
  }),
  getters: {
    mappedData: state => state.all.map(mapData),
    mappedCurrent: state => mapData(state.current),
  },
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Organization[]
    },
    fetchCurrent() {
      return new Promise((resolve, reject) => {
        const url = `/v1/organizations/current`
        requestStore.modifyRunningFetches({
          url,
          persist: true,
          prefetching: true,
          abortController: new AbortController(),
        })
        axios
          .get(url)
          .then(async ({ data }) => {
            this.current = data
            resolve(this.current)
          })
          .catch(reject)
          .finally(() => requestStore.removeRunningFetch(url))
      })
    },
    async fetchAll({ persist = false, prefetching = false } = {}) {
      await paginatedFetch({
        url: "/v1/organizations",
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    fetchByIds(ids: number[]) {
      return new Promise((resolve, reject) => {
        const requests = []
        forEach(ids, id => requests.push(axios.get(`/v1/organizations/${id}`)))

        Promise.all(requests)
          .then(responses => {
            const data = responses.map(r => r.data)
            forEach(data, v => addOrUpdate(this.all, v, ["id"]))
          })
          .catch(reject)
      })
    },
    add(values: CreateOrganization) {
      values.addresses = values.addresses?.map(getId)
      values.manufacturers = values.manufacturers?.map(getId)
      if (values.pricing) values.pricing = getId(values.pricing)
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/organizations",
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    update(values: Organization) {
      values.addresses = values.addresses?.map(getId)
      values.manufacturers = values.manufacturers?.map(getId)
      if (values.pricing) values.pricing = getId(values.pricing)
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/organizations/${values.id}`,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    addAddress(organizationId: number, values: CreateAddress) {
      const index = this.all.findIndex(o => o.id === organizationId)
      if (index === -1) return
      const manufacturerIndex = manufacturerStore.all.findIndex(
        m => m.id === organizationId
      )

      const tempId = generateTemporaryId()
      const newIndex = this.all[index].addresses.length
      const newManufacturerAddressIndex =
        manufacturerIndex !== -1
          ? manufacturerStore.all[manufacturerIndex].addresses.length
          : -1
      return new Promise<Address>((resolve, reject) => {
        return optimisticAdd({
          allObjects: [addressStore.all],
          url: `/v1/organizations/${organizationId}/addresses`,
          values,
          tempId,
          addToRequestStore: true,
          optimisticAddCallback: () => {
            this.all[index].addresses.push(tempId)
            if (manufacturerIndex !== -1)
              manufacturerStore.all[manufacturerIndex].addresses.push(tempId)
          },
        })
          .then(newAddress => {
            this.all[index].addresses[newIndex] = +(newAddress as Address).id
            if (manufacturerIndex !== -1)
              manufacturerStore.all[manufacturerIndex].addresses[
                newManufacturerAddressIndex
              ] = +(newAddress as Address).id
            setCache(cacheKey, this.all)
            resolve(newAddress as Address)
          })
          .catch(err => {
            pullAt(this.all[index].addresses, newIndex)
            if (manufacturerIndex !== -1)
              pullAt(
                manufacturerStore.all[manufacturerIndex].addresses,
                newManufacturerAddressIndex
              )
            reject(err)
          })
          .finally(() => {
            if (organizationId === this.current?.id) {
              this.current.addresses = this.all[index].addresses
            }
          })
      })
    },
    async addAndActivateAddress(id: number, values: CreateAddressWithShipping) {
      const { is_shipping, is_billing } = values
      if (Object.prototype.hasOwnProperty.call(values, "is_shipping")) delete values.is_shipping
      if (Object.prototype.hasOwnProperty.call(values, "is_billing")) delete values.is_billing
      
      const newAddress = await this.addAddress(id, values)

      return addressStore.update(
        { id: newAddress.id, is_active: true, is_shipping, is_billing },
        { addToRequestStore: true }
      )
    },
    removeAddress(organizationId: number, id: number) {
      const index = this.all.findIndex(m => m.id === organizationId)
      if (index === -1) return
      const manufacturerIndex = manufacturerStore.all.findIndex(
        m => m.id === organizationId
      )

      const addressIndex = this.all[index].addresses.findIndex(l => l.id === id)
      const manufacturerAddressIndex =
        manufacturerIndex !== -1
          ? manufacturerStore.all[manufacturerIndex].addresses.length
          : -1
      optimisticDelete({
        allObjects: [addressStore.all],
        url: `/v1/addresses/${id}`,
        id,
        optimisticDeleteCallback: () => {
          pullAt(this.all[index].addresses, [addressIndex])
          pullAt(manufacturerStore.all[manufacturerIndex].addresses, [
            manufacturerAddressIndex,
          ])
        },
      })
        .then(() => setCache(cacheKey, this.all))
        .catch(() => {
          this.all[index].addresses.splice(addressIndex, 0, id)
          manufacturerStore.all[index].addresses.splice(manufacturerAddressIndex, 0, id)
        })
    },
  },
})
