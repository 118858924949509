import { manuStore } from "@/store"

export default function (id) {
  let index = manuStore.sheets.findIndex(x => x.id == id)
  if (index >= 0) {
    return manuStore.sheets[index]
  }

  index = manuStore.profiles.findIndex(x => x.id == id)
  if (index >= 0) {
    return manuStore.profiles[index]
  }

  return {}
}
