<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="row">
    <div class="col-sm-12">
      <CInputGroup>
        <CFormInput
          :id="props.name"
          v-model="inputValue"
          :name="props.name"
          :placeholder="props.options.placeholder || ''"
          :readonly="props.options.readonly"
          @blur.stop="changeIsEditing(false)"
        />
        <div
          v-show="!is_editing"
          class="form-control"
          style="
            border-radius: 0.25rem;
            height: 100%;
            min-height: 34px;
            padding: 0.375rem 0.75rem;
          "
          @click.stop="changeIsEditing(true)"
          v-html="parsed"
        />
        <CInputGroupText
          class="cursor-pointer"
          @click.stop="changeIsEditing(!is_editing)"
        >
          <i
            class="fa fa-eye"
            :class="{
              'fa-eye': is_editing,
              'fa-edit': !is_editing,
            }"
          />
        </CInputGroupText>
      </CInputGroup>
    </div>

    <div v-if="props.options.variables" class="col small text-body-secondary">
      {{ $t("please_describe_the_equation_using_only_the_following_variables") }}:
      <strong>{{ props.options.variables.join(", ") }}</strong>
      . {{ $t("you_also_have_the_following_constants_available") }}:
      <strong>PI, E</strong>
      . {{ $t("finally_you_can_use_the_following_function") }}:
      <strong>
        sin(), cos(), tan(), asin(), acos(), atan(), log(), abs(), ceil(), floor(),
        round(), exp(), min(), max(), count(), sum()
      </strong>
      .
    </div>

    <div v-if="!props.options.variables" class="col small text-body-secondary">
      {{ $t("please_describe_the_equation_without_variables") }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watchEffect, nextTick, ref, computed } from "vue-demi"
import { debounce } from "lodash-es"

const props = withDefaults(
  defineProps<{
    modelValue?: string
    options?: any
    name?: string
  }>(),
  {
    modelValue: "",
    options: () => ({}),
    name: "equation-field"
  }
)

const emit = defineEmits(["update:modelValue"])

const inputValue: any = computed({
  get: () => props.modelValue,
  set: (v) => emit("update:modelValue", v),
})

const parsed = computed(() => {
  if (!inputValue.value) return " "

  const tokens = {
    add: "\\+",
    sub: "\\-",
    mul: "\\*",
    div: "\\/",
    pow: "\\^",
    mod: "\\%",
    l_paren: "\\(",
    r_paren: "\\)",
    // "l_brack": "\\[",
    // "r_brack": "\\]",
    filtered: "[a-zA-Z_][\\.a-zA-Z0-9_]*(\\[[^\\]]*?\\])[\\.a-zA-Z0-9_]*[a-zA-Z0-9_]*",
    number: "[0-9]+(\\.[0-9]+)?",
    variable: "[a-zA-Z_][\\.a-zA-Z0-9_]*[a-zA-Z0-9_]*",
    // "filtered": "\\[[^\\]]*\\]"
  }

  const tags = {
    add: "<strong> + </strong>",
    sub: "<strong> - </strong>",
    mul: "<strong> &times </strong>",
    div: "<strong> / </strong>",
    pow: "<strong> ^ </strong>",
    mod: "<strong> % </strong>",
    l_paren: "<strong> ( </strong>",
    r_paren: "<strong> ) </strong>",
    l_brack: "<strong> [ </strong>",
    r_brack: "<strong> ] </strong>",
  }

  const constants = ["PI", "E"]
  const functions = [
    "sin",
    "cos",
    "tan",
    "asin",
    "acos",
    "atan",
    "log",
    "abs",
    "ceil",
    "floor",
    "round",
    "exp",
    "min",
    "max",
    "count",
    "sum",
  ]

  // Get all matches, will ignore the rest
  const regex = new RegExp(Object.values(tokens).join("|"), "g")
  const matches = inputValue.value.match(regex)

  // Build output
  let result = " "
  const brackets = 0

  if (matches) {
    for (let i = 0; i < matches.length; i++) {
      const match = matches[i]

      for (const key in tokens) {
        if (match.match(tokens[key])) {
          if (key == "number") {
            result += `<span class='badge bg-primary'>${match}</span>`
          } else if (key == "filtered") {
            const submatches = match.match(tokens.variable)

            for (let j = 0; j < submatches.length; j++) {
              var submatch = submatches[j]
              break
            }

            // Filtered value with a valid base value
            if (props.options.variables.indexOf(submatch) > -1) {
              result += `<span class='badge bg-success'>${match}</span>`

              // Filtered value with an invalid base value
            } else {
              result += `<span class='badge bg-danger'>${match}</span>`
            }

            // result += "<span class='badge bg-danger'>" + match + "</span>"
          } else if (key == "variable") {
            // Variable found
            if (props.options.variables.indexOf(match) > -1) {
              result += `<span class='badge bg-success'>${match}</span>`

              // Constant or function descriptor found
            } else if (constants.indexOf(match) > -1 || functions.indexOf(match) > -1) {
              result += `<span class='badge bg-primary'>${match}</span>`
              
              // Invalid input
            } else {
              result += `<span class='badge bg-danger'>${match}</span>`
            }
          } else {
            result += tags[key]
          }

          break
        }
      }
    }
  }
  return result || " "
})

const is_editing = ref(false)
const changeIsEditing = debounce((v: boolean) => {
  is_editing.value = v
}, 100)
watchEffect(() => {
  if (is_editing.value) {
    nextTick(() => {
      const inputEl = document.getElementById(props.name)
      if (inputEl) inputEl.focus()
    })
  }
})
</script>
