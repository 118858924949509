export const PRICING_EQUATION_VARIABLES = [
  "width",
  "height",
  "length",
  "area",
  "volume",
  "weight",
  "quantity",
  "pattern.origin.x",
  "pattern.origin.y",
  "pattern.width",
  "pattern.height",
  "pattern.thickness",
  "pattern.contour.boundary",
  "pattern.contour.area",
  "pattern.holes",
  "pattern.other",
  "pattern.area",
  "pattern.boundary",
  "bends",
  "cutting_time",
]