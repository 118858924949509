<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('plates')"
      :subtitle="$t('all') + ' ' + $t('plates')"
      :add-text="$t('add_plate')"
      :edit-text="$t('edit_plate')"
      :columns="tableColumns"
      :action-delete="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :action-edit="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin"
      :show-add-button="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin !== true"
      :merge-options="mergeOptions"
      :show-merge-options="true"
      :make-form="makeForm"
      :table-options="tableOptions"
      :data="sheetStore.mappedData"
      :add="sheetStore.add"
      :update="sheetStore.update"
      :remove="({ id }) => sheetStore.remove(id)"
      :default-sort="{ column: 'name', ascending: true }"
      @merge-done="sheetStore.modifyData"
    >
      <template #reference="{ row }">
        <b>{{ row.reference || "/" }}</b>
      </template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { onMounted, computed } from "vue-demi"
import { sheetCertificateOptions, type Sheet, type MergerSchema, type VTableOptions } from "@/interfaces"
import { sheetStore, materialStore, authStore, requestStore } from "@/store"
import VTables from "@/components/VTables.vue"
import { convertUnit, booleanCustomSorting, generateFilterAlgorithm, getListColumns } from "@/libraries/helpers"

const i18n = useI18n()
useMeta({ title: i18n.t("sheets") })

const tableColumns = [
  "name",
  "reference",
  "material",
  "thickness",
  "width",
  "height",
  "certificates",
  "cost",
  "boolean__is_available",
  "boolean__is_visible",
  "stock",
  "actions",
]
const tableOptions: VTableOptions = {
  headings: {
    name: i18n.t("name"),
    reference: i18n.t("reference"),
    material: i18n.t("material"),
    thickness: i18n.t("thickness_mm"),
    width: i18n.t("width_mm"),
    height: i18n.t("height_mm"),
    certificates: i18n.t("certificates"),
    cost: i18n.t("cost_e_m2_unit"),
    boolean__is_available: i18n.t("is_available"),
    boolean__is_visible: i18n.t("is_visible"),
    stock: i18n.t("stock"),
    actions: i18n.t("actions"),
  },
  sortable: tableColumns.filter(column => column !== "actions"),
  get filterable() {
    return this.sortable
  },
  customSorting: {
    material(ascending: boolean) {
      return function (a: Sheet, b: Sheet) {
        const aName = a.material?.name || ""
        const bName = b.material?.name || ""
        if (ascending) return aName >= bName ? 1 : -1
        return aName <= bName ? 1 : -1
      }
    },
    boolean__is_available: (ascending: boolean) => (a: Sheet, b: Sheet) =>
      booleanCustomSorting(ascending, a, b, "is_available"),
    boolean__is_visible: (ascending: boolean) => (a: Sheet, b: Sheet) =>
      booleanCustomSorting(ascending, a, b, "is_visible"),
  },
  get filterAlgorithm() {
    return {
      ...generateFilterAlgorithm(this.filterable, "boolean"),
      material(row: Sheet, query: string) {
        const str = (row.material?.name || "").toLowerCase()
        return str.includes(query.toLowerCase())
      },
      reference(row: Sheet, query: string) {
        if (query === "/") query = ""
        const str = (row.reference || "").toLowerCase()
        return str.includes(query.toLowerCase())
      },
      thickness(row: Sheet, query: string) {
        const str = convertUnit({ value: row.thickness, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
      width(row: Sheet, query: string) {
        const str = convertUnit({ value: row.width, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
      height(row: Sheet, query: string) {
        const str = convertUnit({ value: row.height, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
      cost(row: Sheet, query: string) {
        const str = convertUnit({ value: row.cost, conversionRate: 1 })
          .toFixed(2)
          .toLowerCase()
        return str.includes(query.toLowerCase())
      },
      certificates(row: Sheet, query: string) {
        // ### as separator
        const selected = row.certificates?.map(c => c.replace(/.*(\d)(\d)/, "$1.$2")) || []
        const str = selected.join("###").toLowerCase()
        return str.includes(query.toLowerCase())
      }
    }
  },
  get listColumns() {
    return getListColumns(this.filterable)
  },
  customFilters: [
    {
      name: "all",
      callback(row: Sheet, query: string) {
        return [
          row.name || "",
          row.reference || "/",
          row.material?.name || "",
          convertUnit({ value: row.thickness, conversionRate: 1 }).toFixed(2),
          convertUnit({ value: row.width, conversionRate: 1 }).toFixed(2),
          convertUnit({ value: row.height, conversionRate: 1 }).toFixed(2),
          (row.certificates || []).map(c => c.replace(/.*(\d)(\d)/, "$1.$2")),
          convertUnit({ value: row.cost, conversionRate: 1 }).toFixed(2),
          row.is_available,
          row.is_visible,
          row.stock,
        ]
          .join("###")
          .toLowerCase()
          .includes(query.toLowerCase())
      },
    },
  ],
}

const mergeOptions = computed(() => ({
  whitelist: [
    "id",
    "name",
    "reference",
    "material",
    "thickness",
    "width",
    "height",
    "certificates",
    "cost",
    "is_available",
    "is_visible",
    "stock",
  ],
  schema: {
    id: {
      type: "integer",
    },
    name: {
      type: "string",
    },
    reference: {
      type: "string",
    },
    material: {
      type: "namedObject",
      options: materialStore.all,
    },
    certificates: {
      type: "array",
      options: [...sheetCertificateOptions],
    },
    cost: {
      type: "number",
    },
    stock: {
      type: "integer",
    },
    height: {
      type: "number",
    },
    thickness: {
      type: "number",
    },
    width: {
      type: "number",
    },
    is_available: {
      type: "boolean",
    },
    is_visible: {
      type: "boolean",
    },
  } as MergerSchema,
}))

const makeForm = (data?: Sheet) => ({
  id: "sheets-page-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: data?.id,
    },
    name: {
      type: "text",
      label: i18n.t("name"),
      defaultValue: data?.name,
      validations: ["required"],
    },
    reference: {
      type: "text",
      label: i18n.t("reference"),
      defaultValue: data?.reference,
    },
    material: {
      type: "multiselect",
      label: i18n.t("material"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.material,
      options: materialStore.all,
      selectOptions: {
        multiple: false,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: true,
        openDirection: "bottom",
      },
      validations: ["required"],
    },
    thickness: {
      type: "number",
      label: i18n.t("thickness_mm"),
      defaultValue: data?.thickness,
      validations: ["required"],
    },
    cost: {
      type: "number",
      label: i18n.t("cost_e_m2_unit"),
      defaultValue: data?.cost,
      validations: ["required"],
    },
    stock: {
      type: "number",
      label: i18n.t("stock"),
      defaultValue: data?.stock,
      validations: ["required"],
    },
    width: {
      type: "number",
      label: i18n.t("width_mm"),
      defaultValue: data?.width,
      validations: ["required"],
    },
    height: {
      type: "number",
      label: i18n.t("height_mm"),
      defaultValue: data?.height,
      validations: ["required"],
    },
    certificates: {
      type: "multiselect",
      label: i18n.t("certificates"),
      placeholder: i18n.t("select_or_start_typing"),
      defaultValue: data?.certificates,
      options: sheetCertificateOptions.map(id => ({
        id,
        name: id.replace(/.*(\d)(\d)/, "$1.$2"),
      })),
      mapResultTo: "id",
      selectOptions: {
        multiple: true,
        trackBy: "id",
        label: "name",
        hideSelected: true,
        searchable: true,
        selectLabel: i18n.t("press_enter_select"),
        selectedLabel: i18n.t("selected"),
        deselectLabel: i18n.t("press_enter_remove"),
        closeOnSelect: false,
        openDirection: "bottom",
      },
    },
    is_available: {
      type: "checkbox",
      label: i18n.t("is_available"),
      defaultValue: data?.is_available ? data?.is_available : true,
    },
    is_visible: {
      type: "checkbox",
      defaultValue: data?.is_visible ? data?.is_visible : true,
      label: i18n.t("is_visible"),
    },
  },
})

onMounted(() => {
  if (!requestStore.isRequestExist(sheetStore.url)) sheetStore.fetchAll()
})
</script>
