export const mergerTypeOptions = [
  "namedObject",
  "array",
  "integer",
  "string",
  "boolean",
  "number",
  "namedObjectArray",
  "enum",
  "object",
] as const

export const mergerTypeOptionsObject = {
  NAMED_OBJECT: "namedObject",
  ARRAY: "array",
  INTEGER: "integer",
  STRING: "string",
  BOOLEAN: "boolean",
  NUMBER: "number",
  NAMED_OBJECT_ARRAY: "namedObjectArray",
  ENUM: "enum",
  OBJECT: "object"
}

interface MergerSchemaElement {
  type: (typeof mergerTypeOptions)[number]
  options?: any[] | ((id: number) => any[])
}

export type MergerSchema = Record<string, MergerSchemaElement>

export const mergerTrueBooleanOptions = ["yes", "true", "ja"]
export const mergerFalseBooleanOptions = ["no", "false", "nee"]
export const mergerBooleanOptions = [...mergerTrueBooleanOptions, ...mergerFalseBooleanOptions]
export const mergerDefaultIdColumn = "id"

export type MergerId = number
export type MergerColumn = string[] | boolean | number | string
export type MergerColumns = Record<string, MergerColumn>
export type MergerRows = Record<MergerId, MergerColumns>
export type MergerRawUploadedRows = Record<string, string>[]
export type MergerParsedUploadedRows = Record<MergerId, Record<string, number | string>>