import { defineStore } from "pinia"
import { forEach } from "lodash-es"
import type { Client, CreateClient } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { userStore } from "."
import { addOrUpdate, getId, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"

export default defineStore("client", {
  state: () => ({
    all: [],
  }),
  getters: {
    mappedData: state =>
      state.all.map(obj => {
        return {
          ...obj,
          user: userStore.all.find(user => user.id === getId(obj.user)),
        }
      }),
  },
  actions: {
    async fetchAll({ persist = false } = {}) {
      await paginatedFetch({
        url: "/v1/auth/oauth2/client",
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, { ...v, id: v.client_id }, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
    add(values: CreateClient) {
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/auth/oauth2/client",
        onSuccess: (data) => {
          const index = this.all.findIndex(v => v.client_id === data.client_id)
          this.all[index] = {
            ...this.all[index],
            id: this.all[index].client_id
          }
        },
      })
    },
    update(values: Client) {
      if (values.user) delete values.user
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/auth/oauth2/client/${values.id}`,
      })
    },
    remove(id: number) {
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/auth/oauth2/client/${id}`,
        id,
      })
    },
  },
})
