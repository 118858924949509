import { mergerTypeOptionsObject, mergerTrueBooleanOptions, type MergerSchema } from "@/interfaces"
import { isNil, isEqual, get } from "lodash-es"

class MergerHelpers {
  isNewRow(data: Record<string | number, any>, identifier: string | number): boolean {
    return !data[identifier]
  }
  isChanged(currentData: any, newData: any, schema: MergerSchema, column: string) {
    let oldValue = (currentData || {})[column]
    let newValue = newData[column]

    if (schema[column]?.type === mergerTypeOptionsObject.NAMED_OBJECT_ARRAY) oldValue = oldValue?.map((v: any) => v?.name)
    if (schema[column]?.type === mergerTypeOptionsObject.NAMED_OBJECT) oldValue = oldValue?.name
    if (schema[column]?.type === mergerTypeOptionsObject.BOOLEAN) {
      if (typeof oldValue === mergerTypeOptionsObject.STRING) oldValue = mergerTrueBooleanOptions.includes(oldValue.trim().toLowerCase())
      if (typeof newValue === mergerTypeOptionsObject.STRING) newValue = mergerTrueBooleanOptions.includes(newValue.trim().toLowerCase())
    }
    if (schema[column]?.type === mergerTypeOptionsObject.ENUM) {
      // make sure to check enum with string values
      if (isNil(oldValue)) oldValue = ""
      if (isNil(newValue)) newValue = ""
    }

    if (Array.isArray(oldValue)) oldValue = [...oldValue].map(String)
    if (Array.isArray(newValue)) newValue = [...newValue].map(String)

    return oldValue != newValue && !isEqual(oldValue, newValue)
  }
  getCurrentValueText(currentData: any, identifier: any, column: string) {
    const value = get(currentData, `${identifier}.${column}`)
    if (isNil(value)) return value
    if (value?.name) return value.name
    if (!Array.isArray(value)) return value
    if (typeof value === "object") return `\n- ${[...value.filter(v => !isNil(v)).map(v => (typeof v === "object" ? v.name : v))].join("\n- ")}`
    return `\n- ${[...value].join("\n- ")}`
  }
}

export default new MergerHelpers()
