import { forEach } from "lodash-es"
import { defineStore } from "pinia"

interface RunningFetchInterface {
  url: string
  persist: boolean
  prefetching: boolean
  abortController?: AbortController
}

export default defineStore("request", {
  state: () => ({
    runningFetches: <RunningFetchInterface[]>[],
    totalRequests: <number>0
  }),
  getters: {
    isRequestExist: state => (url: string) => {
      return !!state.runningFetches.find(f => f.url === url)
    },
    isPrefetching: state => {
      return (state.runningFetches.filter(f => /*f.persist === true*/f.prefetching === true) || []).length > 0
    }
  },
  actions: {
    modifyRunningFetches(payload: RunningFetchInterface, abortExisting: boolean = false) {
      const index = this.runningFetches.findIndex(f => f.url === payload.url)
      if (index === -1) {
        this.runningFetches.push(payload)
        return
      }
      if (abortExisting) this.cancelRunningFetches([payload.url])
      this.runningFetches[index] = payload
    },
    /*
    Remove running fetch from runningFetches array,
    if url defined, will remove the found url
    if url not defined, will remove the data that contains { persist: false } 
    */
    removeRunningFetch(url?: string, persist?: boolean) {
      this.runningFetches = this.runningFetches.filter(f => url ? f.url !== url : persist || f.persist)
    },
    cancelRunningFetches(urls: string[] = null) {
      forEach(this.runningFetches, f => {
        const runCancel = urls ? urls.includes(f.url) : true
        if (runCancel && f.abortController)
          f.abortController.abort("Cancel running fetches")
      })
    }
  },
})

