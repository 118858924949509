import type { Batch, Machine, Part, Profile, Sheet } from "@/interfaces"
import { pick } from "lodash-es"
import { getId } from "@/interfaces"

export function batchPrepareUpdatePayload(values: any, batch: Batch) {
  values = {
    ...pick(batch || {}, ["stock", "quantity", "machines", "certificates"]),
    ...values,
  }

  if (values.order) values.order = getId(values.order)
  if (values.part) values.part = getId(values.part) as unknown as Part
  if (values.machines) values.machines = values.machines.map(getId) as unknown as Machine[]
  if (values.stock) values.stock = getId(values.stock) as unknown as Sheet | Profile
  if (values.times) {
    values.times = values.times.map(time => ({
      ...time,
      machine: getId(time.machine) as unknown as Machine,
    }))
  }
  if (Object.hasOwnProperty.call(values, "parent")) values.parent = getId(values.parent)
  return values
}