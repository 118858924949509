import pinia from "@/pinia"
import makeAuthStore from "./auth"
import makeManuStore from "./manu"
import makeUserStore from "./user"
import makeSideStore from "./side"
import makeToastStore from "./toast"
import makeOrderStore from "./order"
import makeCustomerStore from "./customer"
import makeOrganizationStore from "./organization"
import makeGlobalStore from "./global"
import makeManufacturerStore from "./manufacturer"
import makeFileStore from "./file"
import makeNestingStore from "./nesting"
import makeSheetStore from "./sheet"
import makeProfileStore from "./profile"
import makeMaterialStore from "./material"
import makeMachineStore from "./machine"
import makeRequestStore from "./request"
import makeToolStore from "./tool"
import makePlanStore from "./plan"
import makeWebhookStore from "./webhook"
import makeAddressStore from "./address"
import makePricingStore from "./pricing"
import makeClientStore from "./client"
import makeTokenStore from "./token"
import makeLogStore from "./log"
import makeLimitStore from "./limit"
import makeSettingStore from "./setting"
import makeBatchStore from "./batch"
import makePartStore from "./part"

const authStore = makeAuthStore(pinia)
const manuStore = makeManuStore(pinia)
const manufacturerStore = makeManufacturerStore(pinia)
const sideStore = makeSideStore(pinia)
const userStore = makeUserStore(pinia)
const toastStore = makeToastStore(pinia)
const orderStore = makeOrderStore(pinia)
const customerStore = makeCustomerStore(pinia)
const organizationStore = makeOrganizationStore(pinia)
const globalStore = makeGlobalStore(pinia)
const fileStore = makeFileStore(pinia)
const nestingStore = makeNestingStore(pinia)
const sheetStore = makeSheetStore(pinia)
const profileStore = makeProfileStore(pinia)
const materialStore = makeMaterialStore(pinia)
const machineStore = makeMachineStore(pinia)
const requestStore = makeRequestStore(pinia)
const toolStore = makeToolStore(pinia)
const planStore = makePlanStore(pinia)
const webhookStore = makeWebhookStore(pinia)
const addressStore = makeAddressStore(pinia)
const pricingStore = makePricingStore(pinia)
const clientStore = makeClientStore(pinia)
const tokenStore = makeTokenStore(pinia)
const logStore = makeLogStore(pinia)
const limitStore = makeLimitStore(pinia)
const settingStore = makeSettingStore(pinia)
const batchStore = makeBatchStore(pinia)
const partStore = makePartStore(pinia)

sideStore.$subscribe((_, state) => {
  localStorage.showSidebar = state.showSidebar
})

const resetStore = () => {
  userStore.$reset()
  orderStore.$reset()
  customerStore.$reset()
  organizationStore.$reset()
  manufacturerStore.all = [] // only reset all because other properties still needed
  fileStore.$reset()
  nestingStore.$reset()
  sheetStore.$reset()
  profileStore.$reset()
  materialStore.$reset()
  machineStore.$reset()
  requestStore.$reset()
  toolStore.$reset()
  planStore.$reset()
  webhookStore.$reset()
  addressStore.$reset()
  pricingStore.$reset()
  clientStore.$reset()
  tokenStore.$reset()
  logStore.$reset()
  limitStore.$reset()
  settingStore.$reset()
  batchStore.$reset()
  partStore.$reset()
}
const initStore = () => {
  sheetStore.init()
  profileStore.init()
  materialStore.init()
  machineStore.init()
  addressStore.init()
  orderStore.init()
  partStore.init()
  userStore.init()
  manufacturerStore.init()
  organizationStore.init()
  toastStore.init()
  globalStore.init()
  fileStore.init()
}

export {
  authStore,
  manuStore,
  sideStore,
  userStore,
  toastStore,
  orderStore,
  customerStore,
  organizationStore,
  globalStore,
  manufacturerStore,
  fileStore,
  nestingStore,
  sheetStore,
  profileStore,
  materialStore,
  machineStore,
  requestStore,
  toolStore,
  planStore,
  webhookStore,
  addressStore,
  pricingStore,
  clientStore,
  tokenStore,
  logStore,
  limitStore,
  settingStore,
  batchStore,
  partStore,
  resetStore,
  initStore
}
