import { defineStore } from "pinia"
import { LOCALSTORAGE_GENERAL_KEY } from "@/constants"
import type { LocalStorageInterface } from "@/interfaces"
import type { Batch } from "@/interfaces"
import { requestStore } from "."
import { cloneDeep } from "lodash-es"

interface ComponentInterface {
  popover: PopoverComponentInterface
}

interface PopoverComponentInterface {
  show: boolean,
  batch: Batch
}

interface FooterOptions {
  totalData?: number
  perPage?: number
  currentPage?: number
  text?: string
  showPagination?: boolean
}

const defaultWholePageDropzone = {
  enabled: false,
  busy: false,
  options: {},
  actions: {
    addedfile: (file: File) => {},
    success: (originalFile: File, file: File) => {},
    error: (file: File, message: string) => {},
  },
}

const defaultPdfViewer = {
  show: false,
  loading: false,
  fileUrl: null,
  filename: "file.pdf"
}

const defaultFooter = {
  show: false,
  text: "",
  pagination: {
    totalData: 0,
    perPage: 0,
    currentPage: 0,
    show: true
  }
}

export default defineStore("global", {
  state: () => ({
    isDevelopment: false,
    localStorage: <LocalStorageInterface>{},
    components: <ComponentInterface>{
      popover: {
        show: false,
        batch: null,
      },
    },
    progressBar: 0,
    wholePageDropzone: cloneDeep(defaultWholePageDropzone),
    pdfViewer: cloneDeep(defaultPdfViewer),
    footer: cloneDeep(defaultFooter),
  }),
  getters: {},
  actions: {
    init() {
      if (!localStorage[LOCALSTORAGE_GENERAL_KEY]) return
      this.localStorage = JSON.parse(localStorage[LOCALSTORAGE_GENERAL_KEY])
    },
    setLocalStorage(payload: LocalStorageInterface) {
      for (const key in payload) {
        this.localStorage[key] = payload[key]
      }
      localStorage.setItem(LOCALSTORAGE_GENERAL_KEY, JSON.stringify(this.localStorage))
    },
    setComponent(property: keyof ComponentInterface, values: any) {
      if (!Object.prototype.hasOwnProperty.call(this.components, property)) return
      for (const key in values) {
        if (!Object.prototype.hasOwnProperty.call(this.components[property], key)) return
        this.components[property][key] = values[key]
      }
    },
    updateProgressBar() {
      if (this.progressBar >= 100) this.progressBar = 0

      const MIN_PROGRESS = 10
      let progress = 100 - requestStore.totalRequests * 10
      if (requestStore.totalRequests)
        progress = progress > MIN_PROGRESS ? progress : MIN_PROGRESS

      this.progressBar = progress
    },
    resetWholePageDropzone() {
      this.wholePageDropzone = defaultWholePageDropzone
    },
    reset(keys: string[] = ["wholePageDropzone", "pdfViewer", "footer"]) {
      if (keys.includes("wholePageDropzone")) this.wholePageDropzone = cloneDeep(defaultWholePageDropzone)
      if (keys.includes("pdfViewer")) this.pdfViewer = cloneDeep(defaultPdfViewer)
      if (keys.includes("footer")) this.footer = cloneDeep(defaultFooter)
    },
    changeFooter(options: FooterOptions) {
      const { totalData, perPage, currentPage, text: optionText, showPagination = true } = options
      let text = optionText
      if (!text) {
        const from = perPage * (currentPage - 1) + 1
        let to = perPage * currentPage
        if (to > totalData) to = totalData
        text = `Showing ${from} to ${to} of ${totalData} records`
        if (!optionText && totalData < perPage) text = `${totalData} records`
      }
      this.footer = {
        show: true,
        text,
        pagination: {
          show: showPagination,
          totalData,
          perPage,
          currentPage
        }
      }
    },
  },
})
