import type { Modify } from "."

export const materialTypeOptions = [
  "ORGANIC",
  "COMPOSIT",
  "CERAMIC",
  "PLASTIC",
  "ALUMINIUM",
  "STAINLESS",
  "STEEL",
  "OTHER",
] as const

export type CreateMaterial = {
  name: string
  code?: string
  description?: string
  density: number
  type: (typeof materialTypeOptions)[number]
}

export type Material = Modify<
  Required<CreateMaterial>,
  {
    id: number
    created: Date
    updated: Date
  }
>
