<template>
  <div 
    :id="props.id"
    class="table-field"
  >
    <v-client-table
      ref="table"
      :columns="options.columns"
      :data="datas"
      :options="options.options"
    >
      <template #afterBody>
        <tr class="VueTables__no-results">
          <td class="text-center pt-2" colspan="11">
            <a class="cursor-pointer" @click="addRow()">
              {{ $t("add") + " " + $t("row") }}
              <i style="" class="fa fa-plus ms-1" />
            </a>
          </td>
        </tr>
      </template>

      <template #thickness="data">
        <CFormInput
          v-model.number="data.row.thickness"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #radius="data">
        <CFormInput
          v-model.number="data.row.radius"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #k_factor="data">
        <CFormInput
          v-model.number="data.row.k_factor"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #pierce_time="data">
        <CFormInput
          v-model.number="data.row.pierce_time"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #area_small="data">
        <CFormInput
          v-model.number="data.row.area_small"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #area_medium="data">
        <CFormInput
          v-model.number="data.row.area_medium"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #area_large="data">
        <CFormInput
          v-model.number="data.row.area_large"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #feed_small="data">
        <CFormInput
          v-model.number="data.row.feed_small"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #feed_medium="data">
        <CFormInput
          v-model.number="data.row.feed_medium"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>

      <template #feed_large="data">
        <CFormInput
          v-model.number="data.row.feed_large"
          type="number"
          step="0.01"
          @input="updateRow(data.row)"
        />
      </template>
    </v-client-table>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"
import { cloneDeep } from "lodash-es"
import { v4 as uuidv4 } from "uuid"

const props = withDefaults(
  defineProps<{
    modelValue?: any[]
    tableOptions: any
    id?: string
  }>(),
  {
    modelValue: () => [],
    id: uuidv4() 
})

const emit = defineEmits(["update:modelValue"])

const inputValue: any = computed({
  get: () => props.modelValue,
  set: v => emit("update:modelValue", v),
})
const options = computed(() => ({
  ...props.tableOptions,
  options: {
    ...props.tableOptions.options,
    editableColumns: props.tableOptions.columns,
  },
}))
const datas = computed({
  get: () => (!inputValue.value) ? [] : cloneDeep(inputValue.value).map((d, index) => ({ ...d, index })),
  set(newValue: any) {
    inputValue.value = newValue.map(v => {
      delete v.index
      return v
    })
  },
})

const addRow = () => {
  const row = {}
  for (let i = 0; i < options.value.columns.length; i++) {
    row[options.value.columns[i]] = null
  }
  let newValue = cloneDeep(inputValue.value)
  if (newValue) newValue.push(row)
  else newValue = [row]
  inputValue.value = newValue
}
const updateRow = (newValue) => {
  const newDatas = cloneDeep(datas.value)
  newDatas[newValue.index] = newValue
  datas.value = newDatas
}
</script>

<style lang="scss">
.wrapped-with-tquheswgrd {
  &.field {
    tr:not(.VueTables__no-results) td {
      padding: 0;
    }
    input {
      border: none;
    }
  }
}
</style>
