import { isAfter, parseISO } from "date-fns"
import { dateFormat, daysAgo } from "."
import { i18n } from "@/plugins/i18n"

export const booleanFilterOptions = [
  { id: "true", text: i18n.t("yes") },
  { id: "false", text: i18n.t("no") },
]

export function dateToFilterString(row: any, key: string, separator = "###") {
  return dateFormat(row[key]) + separator + daysAgo(row[key])
}

export function dateFilterAlgorithm(row: any, query: string, key: string) {
  const str = dateToFilterString(row, key)
  return str.includes(query.toLowerCase())
}

export function booleanCustomSorting(ascending: boolean, a: any, b: any, key: string) {
  if (ascending) return a[key] ? 1 : -1
  return b[key] ? 1 : -1
}

export function dateCustomSorting(ascending: boolean, a: any, b: any, key: string) {
  const aDate = parseISO(a[key])
  const bDate = parseISO(b[key])

  if (ascending) return isAfter(aDate, bDate) ? 1 : -1
  return isAfter(bDate, aDate) ? 1 : -1
}

export function booleanFilter(value: boolean, query: string) {
  return query === "true" ? value : query === "false" ? !value : true
}

export function generateFilterAlgorithm(columns: string[], type: string) {
  let typeFilter: any = (column: string) => true
  if (type === "boolean") typeFilter = (column: string) => (row: any, query: string) => booleanFilter(row[column.replace(`${type}__`, "")], query)
  if (type === "date") typeFilter = (column: string) => (row: any, query: string) => dateFilterAlgorithm(row, query, column.replace(`${type}__`, ""))

  let filter = (column: string) => column.includes(`${type}__`)
  let reducer = (value: any, column: string) => ({
    ...value,
    [column]: typeFilter(column),
  })

  return columns
    .filter(filter)
    .reduce(reducer, {})
}

// Show filter as a dropdown
export function getListColumns(columns: string[]) {
    return columns
      .filter(column => column.includes("boolean__"))
      .reduce((value, column) => ({ ...value, [column]: booleanFilterOptions }), {})
}

export function getFilterEmitsOptions(filters: string[]) {
  const options: any = {}
  filters.forEach((filter) => {
    options[`filter::${filter}`] = null
  })
  return options
}