<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" @close="isVisible = false">
      <CModalHeader>
        <CModalTitle>
          {{ $t(`${props.data ? "edit" : "add"}_pricing`) }}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <DynamicFormWrapper :form="form" @submit="onSubmit" />
      </CModalBody>
      <CModalFooter class="justify-content-between">
        <div class="d-flex flex-grow-1">
          <CButton class="me-4" color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
        </div>
        <CButton v-if="props.data" class="me-4" color="danger">
          {{ $t("remove") }}
        </CButton>
        <CButton color="success" type="submit" :form="form.id">
          {{ $t("save") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { computed } from 'vue-demi'
import { useI18n } from 'vue-i18n'
import { PRICING_EQUATION_VARIABLES } from "@/constants"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    data?: any
    show?: boolean
  }>(),
  {
    data: null,
    show: false
  }
)

const emit = defineEmits(["close", "submit"])

const isVisible = computed({
  get: () => props.show,
  // deepcode ignore VueGetterDoesntReturn: no need to return value in setter
  set(v: boolean) {
    if (v == false) emit("close")
  }
})
const form = computed(() => ({
  id: "pricings-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: props.data?.id,
    },
    startup_amount: {
      type: "equation",
      label: i18n.t("startup_amount"),
      defaultValue: props.data?.startup_amount,
      validations: [],
      variables: PRICING_EQUATION_VARIABLES
    },
    discount_amount: {
      type: "equation",
      label: i18n.t("discount_amount"),
      defaultValue: props.data?.discount_amount,
      validations: [],
      variables: PRICING_EQUATION_VARIABLES
    },
    shipping_amount: {
      type: "equation",
      label: i18n.t("shipping_amount"),
      defaultValue: props.data?.shipping_amount,
      validations: [],
      variables: PRICING_EQUATION_VARIABLES
    },
    tax_rate: {
      type: "number",
      label: i18n.t("tax_rate"),
      defaultValue: props.data?.tax_rate,
      validations: [],
    },
  },
}))

const onSubmit = (values: any) => {
  isVisible.value = false
  emit("submit", values)
}
</script>
