<!-- eslint-disable vue/no-v-html -->
<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" size="lg" @close="isVisible = false">
      <CModalHeader>
        <CModalTitle>{{ $t("edit_batch") }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol :sm="4" class="d-flex justify-content-center align-items-center">
            <div v-if="!image3D" class="spinner-border" role="status" />
            <div
              v-else
              class="img-container"
              style="width: 100%"
              v-html="image3D"
            />
          </CCol>
          <CCol :sm="8">
            <CRow v-if="props.batch?.times && props.batch?.times.length">
              <CCol />
              <CCol>
                <label>{{ $t("startup_time") }}:</label>
              </CCol>
              <CCol>
                <label>{{ $t("cycle_time") }}:</label>
              </CCol>
            </CRow>
            <VueDraggable
              v-model="values.times"
              class="mt-2"
              item-key="id"
            >
              <template #item="{ element, index }">
                <CRow class="mb-2">
                  <CCol sm="4" style="cursor: grab">
                    {{ element.machine?.name }}
                  </CCol>

                  <CCol sm="4">
                    <div class="form-group">
                      <input
                        v-model="values.times[index].startup_time"
                        name="startup-time-input"
                        class="form-control"
                        type="number"
                      />
                    </div>
                  </CCol>
                  <CCol sm="4">
                    <div class="form-group">
                      <input
                        v-model="values.times[index].cycle_time"
                        name="cycle-time-input"
                        class="form-control"
                        type="number"
                      />
                    </div>
                  </CCol>
                </CRow>
              </template>
            </VueDraggable>

            <CRow class="flex-column">
              <CCol>
                <CFormLabel class="text-body-secondary" for="batch-quantity-input">{{ $t("quantity") }}</CFormLabel>
              </CCol>
              <CCol>
                <CFormInput
                  id="batch-quantity-input"
                  v-model.number="values.quantity"
                  type="number"
                  min="1"
                />
              </CCol>
            </CRow>

            <CRow class="flex-column">
              <CCol>
                <CFormLabel class="text-body-secondary" for="batch-unit-amount-input">{{ $t("price") }}</CFormLabel>
              </CCol>
              <CCol>
                <CFormInput
                  id="batch-unit-amount-input"
                  v-model.number="values.unit_amount"
                  type="number"
                  min="0"
                />
              </CCol>
            </CRow>
          </CCol>
        </CRow>
        <hr class="bigHr" />
        <CRow>
          <CCol class="justify-content-end d-flex">
            <h5>{{ toCurrency(totalAmount) }}</h5>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter class="justify-content-between">
        <div class="d-flex flex-grow-1">
          <CButton class="me-4" color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
        </div>
        <CButton class="me-4" color="danger" @click="remove()">
          {{ $t("remove") }}
        </CButton>
        <CButton color="success" @click="update">
          {{ $t("save") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { watch, ref, computed } from "vue-demi"
import sweetalert from "sweetalert2"
import VueDraggable from "vuedraggable"
import type { Batch } from "@/interfaces"
import { batchStore, partStore } from "@/store"
import { toCurrency } from "@/libraries/helpers"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

const emit = defineEmits(["close", "updated", "removed"])

const props = withDefaults(
  defineProps<{
    show?: boolean
    batch?: Batch
  }>(),
  {
    show: false,
    batch: null,
  }
)
watch(() => props.batch,
  () => {
    reset()
  }
)

const values = ref(null)
const reset = () => {
  values.value = {
    id: props.batch?.id || null,
    stock: props.batch?.stock || null,
    quantity: props.batch?.quantity || 0,
    unit_amount: props.batch?.unit_amount || 0,
    times: props.batch?.times || [],
  }
}

const isVisible = computed({
  get: () => props.show,
  set: (v: boolean) => {
    reset()
    if (v == false) emit("close")
  },
})
const totalAmount = computed(() => values.value.quantity * values.value.unit_amount)
const image3D = computed(() => partStore.all3DImages[props.batch?.part?.id])

const update = () => {
  const payload = {
    ...values.value,
    total_amount: values.value.quantity * values.value.unit_amount
  }
  batchStore.update(payload)
  emit("updated", props.batch)
  isVisible.value = false
}
const remove = () => {
  sweetalert
    .fire({
      title: i18n.t("remove_batch"),
      text: i18n.t("remove_batch_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: i18n.t("yes_please"),
      cancelButtonText: i18n.t("no_thanks"),
    })
    .then(result => {
      if (result.isConfirmed) {
        batchStore.remove(props.batch.id).then(() => {
          emit("removed")
          isVisible.value = false
        }).catch()
      }
    })
    .catch()
}
</script>
