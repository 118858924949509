<template>
  <li v-if="props.tree.index !== null && props.tree.index !== undefined" class="tree-item">
    <div
      :class="{
        bold: isFolder,
        underline: props.tree.index === props.selected,
      }"
    >
      <span
        class="part-name"
        @click="isFolder ? toggleFolder() : emit('clicked', props.tree.index)"
      >
        {{ props.tree.name }}
      </span>
      <i
        v-if="!isFolder"
        class="fa ms-2 icon"
        :class="[show ? 'fa-eye' : 'fa-eye-slash']"
        @click="toggleItem"
      />

      <i
        v-if="isFolder"
        class="fa ms-2 icon"
        :class="[collapsed ? 'fa-circle-plus' : 'fa-circle-minus']"
        @click="toggleFolder"
      />

      <i
        v-if="!isFolder"
        class="fa ms-2 icon"
        :class="[props.tree.index === props.selected ? 'fa-circle-check' : 'fa-circle-info']"
        @click="emit('clicked', props.tree.index)"
      />
    </div>
    <ul v-if="isFolder" v-show="!collapsed">
      <tree-item
        v-for="(component, index) in props.tree.components"
        :key="index"
        class="item"
        :tree="component"
        :selected="props.selected"
        @clicked="v => emit('clicked', v)"
        @toggled="v => emit('toggled', v)"
      />
    </ul>
  </li>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue-demi"
import type { ComponentTree } from "@/interfaces"

const emit = defineEmits(["clicked", "toggled"])

const props = defineProps<{ tree: ComponentTree, selected: number }>()

const collapsed = ref<boolean>(true)
const isFolder = computed<boolean>(() => props.tree.components?.length > 0)
const toggleFolder = () => {
  if (isFolder.value) collapsed.value = !collapsed.value
}

const show = ref<boolean>(true)
const toggleItem = () => {
  show.value = !show.value
  emit("toggled", props.tree.index)
}
</script>

<style scoped lang="scss">
body {
  font-family: Menlo, Consolas, monospace;
  color: #444;
}
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
ul,
ul ul {
  list-style-type: none;
  background: url(/img/tree/vline.png) repeat-y;
  margin: 0 1em;
  padding: 0;
}

ul ul {
  margin-left: 10px;
}

ul li {
  margin: 0;
  padding: 0 12px;
  line-height: 20px;
  background: url(/img/tree/node.png) no-repeat;
}

ul li:last-child {
  background: url(/img/tree/lastnode.png) no-repeat;
}

.svg-icon,
.icon {
  width: 1em;
  height: 1em;
  position: relative;
  cursor: pointer;
  pointer-events: visible;
}

.part-name {
  pointer-events: visible;
}
</style>
