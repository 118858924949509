import type { Modify, Optional } from "./_utils"

export const fileTypeOptions = [
  "LOGO",
  "EXPORT",
  "ATTACHMENT",
  "ASSEMBLY",
  "MODEL",
  "DRAWING",
] as const

export type CreateFile = {}

export type File = Modify<
  Required<CreateFile>,
  {
    id: number // id
    name: string
    extension: string
    size: number // integer
    checksum: string
    organization: number // organization_id
    access?: number[] // organization_id[]
    type?: typeof fileTypeOptions[number]
    created?: Date
    updated?: Date
  }
>

export type FileTree = {
  id: number // integer
  created: Date
  updated: Date
  created_by: number // user_id
  updated_by: number // user_id
  root: number // integer
  name: string
  level: number // integer
  index: number // integer
  count: number // integer
  reference: number // integer
  components: FileTree[]
  is_assembly: boolean
  is_free: boolean
  is_shape: boolean
  is_compound: boolean
  is_component: boolean
  is_simple: boolean
  is_reference: boolean
  translation: number[]
  orientation: number[]
}

export const uploadFileStatus = {
  UPLOADING: "UPLOADING",
  PROCESSING: "PROCESSING",
  DUPLICATED: "DUPLICATED",
  ERROR: "ERROR",
  DONE: "DONE",
}

export type uploadedFileInterface = {
  file: File
  filename: string
  originalFile: File
  status: typeof uploadFileStatus[keyof typeof uploadFileStatus]
  error: string
  progress: number
  force: boolean
}

// Make some field optional for update
export type updateUploadedFileInterface = Optional<
  uploadedFileInterface,
  "file" | "filename" | "status" | "error" | "progress" | "force"
>