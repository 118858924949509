<template>
  <div 
    class="wrapped-with-gnaarmyqvz tooltip-box"
    :class="[`tooltip-box__justify-${props.justify}`]"
    @mouseover="handleMouseHover"
    @mouseleave="handleMouseLeave"
  >
    <slot />
    <div
      :id="id"
      class="tooltip tooltip__theme-dark top"
      :class="{
        'position-fixed': props.position === 'fixed',
      }"
      :style="{
        width: `${width}px`,
        left,
        top,
        visibility: visible ? 'visible' : 'hidden',
      }"
    >
      <span class="text" v-html="props.text" />
    </div>
    <div
      ref="placeholder_element"
      style="
        visibility: hidden;
        white-space: nowrap;
        position: absolute;
        width: auto;
        height: auto;
      "
      v-html="props.text"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue-demi'
import { v4 as uuidv4 } from 'uuid'

const props = withDefaults(
  defineProps<{
    text?: string
    justify?: string
    position?: string
  }>(),
  {
    text: "Tooltip",
    justify: "center",
    position: "absolute"
  }
)

const id = `tooltip-${uuidv4()}`

const placeholder_element = ref(null)

const width = ref(0)
const visible = ref(false)
const top = ref("auto")
const left = ref("50%")
const handleMouseHover = (e: Event) => {
  if (props.position === "fixed") {
    const boundingRect = (e.target as HTMLElement).getBoundingClientRect()
    const tooltip = document.getElementById(id)
    const tooltipBoundingRect = tooltip.getBoundingClientRect()

    // this is calculated based on trial and error
    top.value = `${boundingRect.top * 1.25 - tooltipBoundingRect.height * 1.25 - 10}px`
    left.value = `${boundingRect.left * 1.25 - tooltipBoundingRect.width * 3/5 + 4}px`
  }

  visible.value = true
}
const handleMouseLeave = () => {
  visible.value = false
}

onMounted(() => {
  if (!placeholder_element.value) {
    width.value = 0
    return
  }
  placeholder_element.value.style.fontSize = 12;
  width.value = placeholder_element.value.clientWidth + 12;
})
</script>

<style lang="scss">
.wrapped-with-gnaarmyqvz {
  .border-right {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
  &.tooltip-box {
    position: relative;
    display: flex;
    justify-content: center;
    &__justify-left {
      justify-content: flex-start;
      .tooltip {
        left: 1.1rem;
        transform: translateX(-100%);
      }
    }
  }

  .tooltip {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    border-radius: 3px;
    padding: 0.5rem;
    left: 50%;
    opacity: 1;
    transition: opacity 1s linear;
    position: absolute;
    z-index: var(--popover-z-index);
    box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
    .text {
      white-space: nowrap;
      font-size: 12px;
    }
    &.top:not(.position-fixed) {
      bottom: 100%;
      margin-bottom: 8px;
      transform: translateX(-47.5%);
      .text::after {
        top: 100%;
        transform: translateX(-47.5%);
      }
    }
    &.bottom:not(.position-fixed) {
      top: 100%;
      margin-top: 8px;
      transform: translateX(-50%);
      .text::after {
        bottom: 100%;
        transform: translate(0, 0) rotate(180deg);
      }
    }
    &__theme-light{
      background-color: #ffffff;
      color: #3f4254;
      & .text::after{
        border-color: #fff transparent transparent transparent;
      }
    }
    &__theme-dark{
      color: #ffffff;
      background-color: #393a3e;
      & .text::after{
        border-color: #393a3e transparent transparent transparent;
      }
    }
    &.position-fixed {
      position: fixed;
    }
  }

  .text::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
  }
}
</style>
