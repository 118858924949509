import { isPlainObject } from "lodash-es"
import { isRef, unref } from "vue"

function unrefCustomizer(value: any) {
  if (value == null) return value
  if (isRef(value)) return unref(value)
  if (Array.isArray(value)) return value.map(unrefCustomizer)

  if (isPlainObject(value))
    return Object.keys(value).reduce((newObj, key) => {
      newObj[key] = unrefCustomizer(value[key])
      return newObj
    }, {})

  return value
}

export default unrefCustomizer
