<template>
  <CCard
    class="cardHover cursor-pointer clickable-card"
    :class="[`text-${props.color}`]"
  >
    <CCardBody
      @click="emit('click')"
    >
      <slot />
    </CCardBody>
  </CCard>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    color?: string
  }>(),
  {
    color: "secondary"
  }
)

const emit = defineEmits(["click"])
</script>

<style lang="scss" scoped>
.cardHover {
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2);
  }
}
.clickable-card {
  &.text-primary {
    border-color: var(--primary)
  }
}
</style>
