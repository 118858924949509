<template>
  <CContainer fluid>
    <h3 class="mb-4">{{ $t("order_summary") }}</h3>
    <OrderWidgets />
  </CContainer>
</template>

<script lang="ts" setup>
import { onMounted } from 'vue-demi'
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"
import { orderStore } from '@/store'
import OrderWidgets from '@/components/OrderWidgets.vue'

const i18n = useI18n()
useMeta({ title: i18n.t("dashboard") })

onMounted(() => {
  orderStore.getSummary()
})
</script>
