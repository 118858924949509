import { defineStore } from "pinia"
import { cloneDeep, forEach } from "lodash-es"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import type { OptimisticAddOptionInterface, OptimisticUpdateOptionInterface, CreateWebhook, Webhook } from "@/interfaces"
import { addOrUpdate, bulkAddOrUpdate, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"

export default defineStore("webhook", {
  state: () => ({
    all: [],
  }),
  getters: {},
  actions: {
    modifyData(data = []) {
      const [webhooks] = bulkAddOrUpdate([cloneDeep(this.all)], data)

      this.all = webhooks
    },
    async fetchAll({ persist = false } = {}) {
      await paginatedFetch({
        url: "/v1/webhooks",
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
    add(values: CreateWebhook, options: OptimisticAddOptionInterface = {}) {
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/webhooks",
        ...options,
      })
    },
    update(values: Webhook, options: OptimisticUpdateOptionInterface = {}) {
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/webhooks/${values.id}`,
        ...options,
      })
    },
    remove(id: number) {
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/webhooks/${id}`,
        id,
      })
    },
  },
})
