import { defineStore } from "pinia"
import { forEach, pick } from "lodash-es"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import type { Address } from "@/interfaces"
import { addOrUpdate, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"
import type { OptimisticUpdateOptionInterface } from "@/interfaces"

const cacheKey = "addresses"

export default defineStore("address", {
  state: () => ({
    all: [],
  }),
  getters: {},
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Address[]
    },
    async fetchAll({ persist = false, prefetching = false } = {}) {
      await paginatedFetch({
        url: "/v1/addresses",
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchByManufacturerId(
      manufacturerId: number,
      { persist = false, prefetching = false } = {}
    ) {
      await paginatedFetch({
        url: `/v1/manufacturers/${manufacturerId}/addresses`,
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchByOrganizationId(
      organizationId: number,
      { persist = false, prefetching = false } = {}
    ) {
      await paginatedFetch({
        url: `/v1/organizations/${organizationId}/addresses`,
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    // will change any later
    update(values: any, options?: OptimisticUpdateOptionInterface) {
      // currently only "addToRequestStore" and "withoutOptimistic" are applicable
      const applicableOptions = pick(options, ["withoutOptimistic", "addToRequestStore"])
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/addresses/${values.id}`,
        addToRequestStore: true,
        onSuccess: () => {
          setCache(cacheKey, this.all)
          if (options?.onSuccess) options.onSuccess()
        },
        ...applicableOptions,
      })
    },
  },
})
