<template>
  <div>
    <ModalWrapper v-model="isVisible">
      <CModal :visible="isVisible" backdrop="static" @close="isVisible = false">
        <CModalHeader>
          <CModalTitle>{{ $t("user_settings") }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <DynamicFormWrapper :form="form" @submit="onSubmit" />
          <div class="d-grid">
            <CButton
              variant="outline"
              color="success"
              class="mt-4"
              @click="showChangePasswordModal = true"
            >
              <i class="fa fa-lock me-2" />
              {{ $t("change_password") }}
            </CButton>
          </div>
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" type="submit" :form="form.id">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
    <FormModal
      :show="showChangePasswordModal"
      :title="$t('change_password')"
      :form="changePasswordForm"
      :ok-button-text="$t('save')"
      :close-after-submit="false"
      @reset="showChangePasswordModal = false"
      @submit="onChangePasswordSubmit"
    >
      <template #before-body>
        <span class="text-danger" v-html="errors.changePassword" />
      </template>
      <template #after-body>
        <span class="text-danger" v-html="errors.confirmPassword" />
      </template>
    </FormModal>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n"
import { watchEffect, ref, computed } from "vue-demi"
import { userStore } from "@/store"
import type { User } from "@/interfaces"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import FormModal from "@/components/modals/FormModal.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

interface ChangePasswordPayload {
  current_password: string
  password: string
  confirm_password: string
}

const i18n = useI18n()
const emit = defineEmits(["close", "updated", "open"])
const props = defineProps<{ show: boolean }>()

const showChangePasswordModal = ref(false)
const errors = ref({
  confirmPassword: null,
  changePassword: null,
})
const changePasswordInputs = ref<ChangePasswordPayload>({
  current_password: null,
  password: null,
  confirm_password: null,
})

const isVisible = computed({
  get: () => props.show && !showChangePasswordModal.value,
  set: (v: boolean) => emit(v ? "open" : "close"),
})
const form = computed(() => ({
  id: "user-setting-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: userStore.current?.id,
    },
    username: {
      type: "text",
      label: i18n.t("username"),
      defaultValue: userStore.current?.username,
      disabled: true,
    },
    email: {
      type: "text",
      label: i18n.t("email"),
      defaultValue: userStore.current?.email,
      disabled: true,
    },
    first_name: {
      type: "text",
      label: i18n.t("first_name"),
      placeholder: "",
      defaultValue: userStore.current?.first_name,
    },
    middle_name: {
      type: "text",
      label: i18n.t("middle_name"),
      placeholder: "",
      defaultValue: userStore.current?.middle_name,
    },
    last_name: {
      type: "text",
      label: i18n.t("last_name"),
      placeholder: "",
      defaultValue: userStore.current?.last_name,
    },
  },
}))
const changePasswordForm = computed(() => ({
  id: "user-change-password-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: userStore.current?.id,
    },
    current_password: {
      type: "password",
      label: i18n.t("old_password"),
      validations: ["required"],
      defaultValue: changePasswordInputs.value.current_password,
    },
    password: {
      type: "password",
      label: i18n.t("new_password"),
      validations: ["required"],
      defaultValue: changePasswordInputs.value.password,
    },
    confirm_password: {
      type: "password",
      label: i18n.t("confirm_password"),
      validations: ["required"],
      defaultValue: changePasswordInputs.value.confirm_password,
    },
  },
}))

watchEffect(() => {
  if (!showChangePasswordModal.value) {
    errors.value.changePassword = null
    errors.value.confirmPassword = null
  }
})

const onChangePasswordSubmit = (values: ChangePasswordPayload) => {
  errors.value.confirmPassword = null
  errors.value.changePassword = null
  changePasswordInputs.value = {
    current_password: values.current_password,
    password: values.password,
    confirm_password: values.confirm_password,
  }

  if (values.password != values.confirm_password)
    return (errors.value.confirmPassword = i18n.t("retype_pass_not_same"))

  userStore.update({
    id: userStore.current.id,
    current_password: values.current_password,
    password: values.password,
  } as User)
    .then((data) => {
      userStore.current = data
      changePasswordInputs.value = {
        current_password: null,
        password: null,
        confirm_password: null,
      }
      showChangePasswordModal.value = false
    })
    .catch(() => {
      errors.value.changePassword = i18n.t("wrong_old_pasword")
    })
}

const onSubmit = (values: User) => {
  isVisible.value = false
  userStore.update(values).then((data) => {
    userStore.current = data
    emit("updated")
  }).catch()
}
</script>
