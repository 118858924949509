import type { Modify } from "./_utils"

export const webhookEventOptions = ["ORDER_DELETED", "ORDER_UPDATED", "ORDER_CREATED"] as const

export type CreateWebhook = {
  name: string // Name used to reffer to this webhook
  endpoint: string // The URL of the endpoint to which the webhook should be sent
  events: (typeof webhookEventOptions)[number][] // Events that will trigger this webhook
  is_active?: boolean // True if the webhook is active and sending requests
}

export type Webhook = Modify<
  Required<CreateWebhook>,
  {
    id: number
    signing_secret: string // The secret used to sign the request using HMAC SHA256
    created: Date
    updated: Date
  }
>
