import { defineStore } from "pinia"

export default defineStore("side", {
  state: () => ({
    foldSidebar: false,
    showSidebar: localStorage.showSidebar
      ? (JSON.parse(localStorage.showSidebar) as boolean)
      : true,
    objectUpdated: null,
  }),
  getters: {
    sideObjectUpdated: state => state.objectUpdated,
  },
  actions: {},
})
