<template>
  <CToaster
    class="wrapped-with-bkgsqevagw toaster"
    placement="top-end"
  >
    <CToast
      v-for="(toast, index) in toasts"
      :key="toast.id || index"
      :color="toast.color"
      :dismissable="toast.dismissable || true"
      :delay="toast.delay || 5000"
      :visible="true"
    >
      <CToastBody
        class="body"
      >
        <span
          v-if="toast.type === 'loading'"
          class="me-3"
        >
          <CSpinner />
        </span>
        <span v-html="toast.message" />
      </CToastBody>
    </CToast>
  </CToaster>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"
import { toastStore } from "@/store"
import type { Toast } from "@/interfaces"

const toasts = computed<Toast[]>(() => toastStore.toasts)
</script>

<style lang="scss" scoped>
.wrapped-with-bkgsqevagw {
  .body {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }
  &.toaster {
    z-index: var(--toast-z-index);
    & :deep(.bg-danger),
    & :deep(.bg-success),
    & :deep(.bg-info),
    & :deep(.bg-secondary),
    & :deep(.bg-dark) {
      color: white;
    }
  }
}
</style>
