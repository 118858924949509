import { getUnixTime } from "date-fns"

export function isJwtExpired(token: string) {
  try {
    const parsedJwt = JSON.parse(window.atob(token.split(".")[1]))
    return (parsedJwt.exp <= getUnixTime(new Date()))
  } catch (e) {
    return true
  }
}
