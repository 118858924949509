import { forEach } from "lodash-es"

export default function(all: Record<string, any>[], values: Record<string, any>, checks: string[]) {
  const findIndex = () => {
    return all.findIndex(v => {
      let exist = true
      forEach(checks, key => {
        if (v[key] !== values[key]) exist = false
      })
      return exist
    })
  }
  const index = findIndex()
  if (index === -1) all.push(values)
  else all[index] = values
  return all
}