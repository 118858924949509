export default function (value: any) {
  if (typeof value !== "number") {
    return value
  }
  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  })
  return formatter.format(value)
}
