import { featureConfigs } from "@/configs";

let pricingMenu = null
if (featureConfigs.pricings?.enabled) {
  pricingMenu = {
    component: "CNavItem",
    name: "pricings",
    to: "/pricings",
    icon: "cilMoney",
  }
}

export default {
  admin: [
    {
      component: "CNavTitle",
      name: "Admin",
    },
    {
      component: "CNavItem",
      name: "manufacturers",
      to: "/admin/manufacturers",
      icon: "cilFactory",
    },
    {
      component: "CNavItem",
      name: "organizations",
      to: "/admin/organizations",
      icon: "cilBuilding",
    },
    {
      component: "CNavItem",
      name: "customers",
      to: "/customers",
      icon: "cilGroup",
    },
    {
      component: "CNavItem",
      name: "users",
      to: "/users",
      icon: "cilGroup",
    },
    {
      component: "CNavItem",
      name: "raw_materials",
      to: "/stock/raw",
      icon: "cilContrast",
    },
    {
      component: "CNavItem",
      name: "sheets",
      to: "/stock/sheets",
      icon: "cilLayers",
    },
    {
      component: "CNavItem",
      name: "profiles",
      to: "/stock/profiles",
      icon: "cilLineStyle",
    },
    {
      component: "CNavItem",
      name: "machines",
      to: "/machines",
      icon: "cilCog",
    },
    {
      component: "CNavTitle",
      name: "development",
    },
    {
      component: "CNavItem",
      name: "clients",
      to: "/admin/clients",
      icon: "cilInputPower",
    },
    {
      component: "CNavItem",
      name: "files",
      to: "/files",
      icon: "cilFile",
    },
    {
      component: "CNavItem",
      name: "tokens",
      to: "/admin/tokens",
      icon: "cilOpacity",
    },
    {
      component: "CNavItem",
      name: "logs",
      to: "/admin/logs",
      icon: "cilExcerpt",
    },
    {
      component: "CNavItem",
      name: "api_documentation",
      to: import.meta.env.VITE_APP_BACKEND_URL,
      icon: "cilBook",
      external: true,
      useRawText: true,
    },
  ],

  manufacturer: [
    // {
    //   component: "CNavTitle",
    //   name: "manufacturer",
    // },
    // {
    //   component: "CNavItem",
    //   name: "nestings",
    //   to: "/nestings/queue",
    //   icon: "cilTask",
    // },
  ],
  manufacturer_moderator: [
    {
      component: "CNavTitle",
      name: "stock",
    },
    {
      component: "CNavItem",
      name: "sheets",
      to: "/stock/sheets",
      icon: "cilLayers",
    },
    {
      component: "CNavItem",
      name: "profiles",
      to: "/stock/profiles",
      icon: "cilLineStyle",
    },
    {
      component: "CNavTitle",
      name: "settings",
    },
    {
      component: "CNavItem",
      name: "customers",
      to: "/customers",
      icon: "cilGroup",
    },
    {
      component: "CNavItem",
      name: "machines",
      to: "/machines",
      icon: "cilCog",
    },
    {
      component: "CNavItem",
      name: "tools",
      to: "/tools",
      icon: "cilBriefcase",
    },
    {
      component: "CNavItem",
      name: "users",
      to: "/users",
      icon: "cilGroup",
    },
    pricingMenu,
    // {
    //   component: "CNavTitle",
    //   name: "production",
    // },
    // {
    //   component: "CNavItem",
    //   name: "new_nestings",
    //   to: "/nestings/new",
    //   icon: "cilPlus",
    // },
    // {
    //   component: "CNavItem",
    //   name: "queue",
    //   to: "/nestings/queue",
    //   icon: "cilTask",
    // },
    // {
    //   component: "CNavItem",
    //   name: "plans",
    //   to: "/nestings/plans",
    //   icon: "cilPuzzle",
    // },
    {
      component: "CNavTitle",
      name: "integration",
    },
    {
      component: "CNavItem",
      name: "webhooks",
      to: "/webhooks",
      icon: "cilLink",
    },
    {
      component: "CNavItem",
      name: "api_documentation",
      to: import.meta.env.VITE_APP_BACKEND_URL,
      icon: "cilBook",
      external: true,
      useRawText: true,
    },
    // {
    //   component: 'CNavTitle',
    //   name: 'Manufacturer Moderator',
    // },
    // {
    //   component: 'CNavTitle',
    //   name: 'Settings',
    // },
  ].filter(v => v !== null),

  customer: [],
  customer_moderator: [
    // {
    //   component: 'CNavTitle',
    //   name: 'Customer Moderator',
    // },
    {
      component: "CNavTitle",
      name: "orders",
    },
    {
      component: "CNavItem",
      name: "drafts",
      to: "/orders/all?status=created",
      icon: "cilNotes",
    },
    {
      component: "CNavItem",
      name: "in_review",
      to: "/orders/all?status=in_review",
      icon: "cilMagnifyingGlass",
    },
    {
      component: "CNavItem",
      name: "confirmed",
      to: "/orders/all?status=confirmed",
      icon: "cilCheck",
    },
    {
      component: "CNavItem",
      name: "quoted",
      to: "/orders/all?status=quoted",
      icon: "cilList",
    },
    {
      component: "CNavItem",
      name: "ordered",
      to: "/orders/all?status=ordered",
      icon: "cilArrowThickFromLeft",
    },
    {
      component: "CNavItem",
      name: "produced",
      to: "/orders/all?status=produced",
      icon: "cilTruck",
    },
    {
      component: "CNavItem",
      name: "completed",
      to: "/orders/all?status=completed",
      icon: "cilCheck",
    },
    {
      component: "CNavTitle",
      name: "miscellaneous",
    },
    {
      component: "CNavItem",
      name: "files",
      to: "/files",
      icon: "cilFile",
    },
    {
      component: "CNavItem",
      name: "users",
      to: "/users",
      icon: "cilGroup",
    },
  ],
}
