export default [
  {
    code: "batches:read",
    description: "Read permissions for batches",
  },
  {
    code: "batches:write",
    description: "Write permissions for batches",
  },
  {
    code: "customers:read",
    description: "Read permissions for customers",
  },
  {
    code: "customers:write",
    description: "Write permissions for customers",
  },
  {
    code: "files:read",
    description: "Read permissions for files",
  },
  {
    code: "files:write",
    description: "Write permissions for files",
  },
  {
    code: "limits:read",
    description: "Read permissions for limits",
  },
  {
    code: "limits:write",
    description: "Write permissions for limits",
  },
  {
    code: "machines:read",
    description: "Read permissions for machines",
  },
  {
    code: "machines:write",
    description: "Write permissions for machines",
  },
  {
    code: "manufacturers:read",
    description: "Read permissions for manufacturers",
  },
  {
    code: "manufacturers:write",
    description: "Write permissions for manufacturers",
  },
  {
    code: "materials:read",
    description: "Read permissions for materials",
  },
  {
    code: "materials:write",
    description: "Write permissions for materials",
  },
  {
    code: "nestings:read",
    description: "Read permissions for nestings",
  },
  {
    code: "nestings:write",
    description: "Write permissions for nestings",
  },
  {
    code: "orders:read",
    description: "Read permissions for orders",
  },
  {
    code: "orders:write",
    description: "Write permissions for orders",
  },
  {
    code: "organizations:read",
    description: "Read permissions for organizations",
  },
  {
    code: "organizations:write",
    description: "Write permissions for organizations",
  },
  {
    code: "parts:read",
    description: "Read permissions for parts",
  },
  {
    code: "parts:write",
    description: "Write permissions for parts",
  },
  {
    code: "plans:read",
    description: "Read permissions for plans",
  },
  {
    code: "plans:write",
    description: "Write permissions for plans",
  },
  {
    code: "pricings:read",
    description: "Read permissions for pricings",
  },
  {
    code: "pricings:write",
    description: "Write permissions for pricings",
  },
  {
    code: "profiles:read",
    description: "Read permissions for profiles",
  },
  {
    code: "profiles:write",
    description: "Write permissions for profiles",
  },
  {
    code: "settings:read",
    description: "Read permissions for settings",
  },
  {
    code: "settings:write",
    description: "Write permissions for settings",
  },
  {
    code: "sheets:read",
    description: "Read permissions for sheets",
  },
  {
    code: "sheets:write",
    description: "Write permissions for sheets",
  },
  {
    code: "tools:read",
    description: "Read permissions for tools",
  },
  {
    code: "tools:write",
    description: "Write permissions for tools",
  },
  {
    code: "users:read",
    description: "Read permissions for users",
  },
  {
    code: "users:write",
    description: "Write permissions for users",
  },
  {
    code: "webhooks:read",
    description: "Read permissions for webhooks",
  },
  {
    code: "webhooks:write",
    description: "Write permissions for webhooks",
  },
]