import type { Idfy, PartialWithId } from "@/interfaces/_utils"
import { cloneDeep } from "lodash-es"
import updateObjectWithProperties from "./updateObjectWithProperties"

export type UpdateFn<Doc, UpdateDoc = Doc> = (
  data: PartialWithId<Idfy<UpdateDoc>>
) => Promise<Doc>

export type FindOriginalFn<Doc, UpdateDoc = Doc> = (
  data: PartialWithId<Idfy<UpdateDoc>>
) => Doc

export default function wrapUpdateFn<Doc, UpdateDoc = Doc>(
  updateFn: UpdateFn<Doc, UpdateDoc>,
  findOriginal: FindOriginalFn<Doc, UpdateDoc>
): UpdateFn<Doc, UpdateDoc> {
  return data => {
    const original = findOriginal(data)
    const backup = cloneDeep(original)
    updateObjectWithProperties(original, data, ["id"])
    return updateFn(data)
      .then(updated => Object.assign(original, updated))
      .catch(() => Object.assign(original, backup))
  }
}
