<template>
  <div
    class="wrapped-with-a547dae7a9b"
  >
    <div
      v-show="!loading"
      :id="props.id"
      class="dropzone"
    >
      <div class="dz-default dz-message">
        <span>
          <i class="fa fa-cloud-upload fa-2x" />
          <br />
          {{ $t("drag_and_drop_your_files_here") }} {{ $t("or") }}
          <br />
          <br />
          <CButton color="primary">
            <i class="fa fa-folder-open me-2" style="color: white" />
            {{ $t("upload_a_file") }}
          </CButton>
        </span>
      </div>
    </div>
    <div v-if="props.loading" class="dropzone loading">
      <CSpinner />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue-demi"
import { Dropzone } from "dropzone"
import { defaultDropzoneOptions } from "@/constants"

const props = withDefaults(
  defineProps<{
    id?: string
    options?: object
    loading?: boolean
  }>(),
  {
    id: "dropzone",
    options: () => ({}),
    loading: false
  }
)

const emit = defineEmits(["initialized", "addedfile", "success", "complete", "processing", "error"])

const dropzone = ref(null)

function addEventListeners() {
  dropzone.value.on("error", (file, message: string) => emit("error", file, message))
  dropzone.value.on("addedfile", file => emit("addedfile", file))
  dropzone.value.on("processing", file => emit("processing", file))
  dropzone.value.on("success", (file, response) => emit("success", file, response))
  dropzone.value.on("complete", file => emit("complete", file))
}

onMounted(() => {
  dropzone.value = new Dropzone(`#${props.id}`, {
    ...defaultDropzoneOptions(),
    ...props.options,
  })
  addEventListeners()
  emit("initialized", dropzone.value)
})
</script>

<style lang="scss">
.wrapped-with-a547dae7a9b {
  width: 100%;
  .dropzone {
    background-color: rgb(245, 245, 245);
    font-family: "Arial", sans-serif;
    color: #777;
    transition: background-color 0.2s linear;
    min-height: 200px;
    border: dashed;
    border-color: rgb(197, 197, 197);
    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
      background: darken($color: rgb(245, 245, 245), $amount: 1);
    }
  }
}
</style>
