<template>
  <div class="merger-table">
    <template v-if="processedUploadedData && Object.keys(processedUploadedData).length">
      <div
        class="py-2 show-changed-data-switch d-flex justify-content-end"
      >
        <CFormSwitch
          size="xl"
          :label="$t('only_show_changed_data')"
          id="formSwitchCheckDefault"
          :checked="only_show_changed_data"
          v-model="only_show_changed_data"
        />
      </div>
      <v-client-table
        :key="tableKey"
        :columns="columns"
        :data="data"
        :options="options"
      >
        <template
          v-for="(column, i) in (columns as string[])"
          :key="i"
          #[column]="{ row }"
        >
          <div
            class="d-flex align-items-start justify-content-between merger-table-cell"
          >
            <ul v-if="Array.isArray(row[column])" class="dashed mb-0 ps-4">
              <li v-for="item in row[column]" :key="item">
                {{ item }}
              </li>
            </ul>
            <span v-else>{{ row.isNewRow && column === identifier ? "NEW ROW" : row[column] }}</span>
            <Popover
              v-if="
                calculatedPropertiesOfColumns[row[identifier]][column].errorText
                || calculatedPropertiesOfColumns[row[identifier]][column].changedText
              "
              :text="
                calculatedPropertiesOfColumns[row[identifier]][column].errorText
                || calculatedPropertiesOfColumns[row[identifier]][column].changedText
                || $t('no_changes')
              "
              position="fixed"
            >
              <i class="fa fa-circle-info" />
            </Popover>
          </div>
        </template>
      </v-client-table>
    </template>
    <p v-else>{{ $t("there_is_no_data_to_merge_with") }}</p>
  </div>
</template>

<script lang="ts">
// TODO: Refactor this component to use Vue 3 Composition API
// Currently not doing it because this is a big component and it is not a priority

import { defineComponent } from 'vue'
import { MergerHelpers } from "@/libraries/helpers"
import {
  mergerDefaultIdColumn, type MergerColumn, type MergerColumns,
  mergerTypeOptions, mergerBooleanOptions, mergerTrueBooleanOptions
} from '@/interfaces'
import { get, cloneDeep, isEmpty, difference } from "lodash-es"
import Popover from "@/components/Popover.vue"
import { v4 as uuidv4 } from "uuid"
import { stringSimilarity as compareTwoStrings } from "string-similarity-js"

const STRING_SIMILARITY_THRESHOLD = 0.67

export default defineComponent({
  name: "MergerTable",
  components: {
    Popover
  },
  props: {
    currentData: {
      type: Object,
      required: true
    },
    uploadedData: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    identifier: {
      type: String,
      default: mergerDefaultIdColumn
    },
    schema: {
      type: Object,
      required: true
    },
    allowNewRow: {
      type: Boolean,
      default: true
    },
    customValidation: {
      type: Function,
    }
  },
  emits: ["initialized"],
  setup() {},
  data() {
    return {
      only_show_changed_data: true,
      tableKey: uuidv4(),
      processedUploadedData: {},
      changedData: [],
      validatedData: {},
      errors: {},
    }
  },
  computed: {
    options() {
      return {
        headings: {
          thickness: this.$t("thickness_mm")
        },
        texts: {
          noResults: "No changes",
        },
        pagination: { show: true },
        perPage: 9,
        sortable: this.columns,
        filterByColumn: true,
        sortIcon: {
          base: "fa fa-lg",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        skin: "table border p-1 mb-0",
        clientMultiSorting: false,
        cellClasses: this.columns.reduce((objects: any, column: any) => ({
          ...objects,
          [column]: [
            {
              class: "changed",
              condition: (row: any) => this.calculatedPropertiesOfColumns[row[this.identifier]][column].isChanged
            },
            {
              class: "error",
              condition: (row: any) => this.calculatedPropertiesOfColumns[row[this.identifier]][column].isError
            },
          ],
        }), {}),
      }
    },
    data() {
      return this.only_show_changed_data === false ? Object.values(this.processedUploadedData) : this.changedData
    },
    calculatedPropertiesOfColumns() {
      const _this = this
      const calculated = {}
      for (let i = 0; i < this.data.length; i++) {
        const d = this.data[i];
        calculated[d[this.identifier]] = {}

        for (let i = 0; i < this.columns.length; i++) {
          const column = (this.columns as string[])[i];
          calculated[d[this.identifier]][column] = {
            isChanged: d.isNewRow || MergerHelpers.isChanged(this.currentData[d[this.identifier]], d, this.schema, column),
            isError: (!this.allowNewRow && d.isNewRow) || get(this.errors, `${d[this.identifier]}.${column}`, false),
            errorText: (this.errors?.[d[this.identifier]]?.[column] || "").replaceAll("\n", "<br>"),
            get changedText() {
              if (!this.isChanged) return null
              if (d.isNewRow) return "NEW ROW"
              return (
                `${_this.$t("originally")}: ${MergerHelpers.getCurrentValueText(_this.currentData, d[_this.identifier], column) || "-"}`
              ).replaceAll("\n", "<br>")
            }
          }
        }
      }
      return calculated
    },
  },
  watch: {
    only_show_changed_data() {
      // refresh table to reset pagination
      this.tableKey = uuidv4()
    },
    data: {
      handler() {
        this.emitInitialized()
      },
      deep: true
    }
  },
  methods: {
    initialize() {
      this.errors = {}
      this.processedUploadedData = {}
      if (!this.uploadedData) return

      const identifiers = Object.keys(this.uploadedData)
      for (let i = 0; i < identifiers.length; i++) {
        const identifier = identifiers[i];

        this.processedUploadedData[identifier] = Object.keys(this.uploadedData[identifier])
          .filter(key => this.columns.includes(key))
          .reduce((row, key) => {
            if (
              this.uploadedData[identifier][key] == null ||
              (this.uploadedData[identifier][key] === "" && this.schema[key].type !== "string")
            ) {
              row[key] = ["array", "namedObjectArray"].includes(this.schema[key].type) ? [] : null
              return row
            }

            const map: Record<typeof mergerTypeOptions[number], (v: string) => MergerColumn> = {
              array: v => v.toString().split(","),
              boolean: v => v.toString().trim(),
              number: v => +v || v,
              integer: v => +v || v,
              enum: v => `${v}`.toUpperCase(),
              string: v => v || "",
              object: v => v,
              namedObject: v => v,
              namedObjectArray: v => v.split(","),
            }

            row[key] = map[this.schema[key].type](this.uploadedData[identifier][key])

            return row
          }, {} as MergerColumns)

        this.processedUploadedData[identifier].isNewRow = MergerHelpers.isNewRow(this.currentData, identifier)

        const data = this.processedUploadedData[identifier]
        
        if (
          Object.keys(data).some(
            column => {
              if (["isNewRow"].includes(column)) return false
              return data.isNewRow || MergerHelpers.isChanged(this.currentData[identifier], data, this.schema, column)
            }
          )
        ) {
          this.changedData.push(data)
        }

        if (!this.allowNewRow && data.isNewRow) {
          this.errors[identifier] = Object.keys(this.processedUploadedData[identifier]).reduce(
            (a, b) => ({ ...a, [b]: this.$t("new_row_are_not_allowed") }),
            {}
          )
          this.validatedData[identifier] = {}
          return
        }
        const { values, errors } = this.validateRow(cloneDeep(this.processedUploadedData[identifier]))

        this.errors[identifier] = errors
        this.validatedData[identifier] = values
      }
      this.emitInitialized()
    },
    validateRow(row: any) {
      let errors = {}

      for (const key in this.schema) {
        if (!row[key]) continue
        const schemaOptions = this.schema[key].options || []

        let options = typeof schemaOptions === "function" ? schemaOptions(row[this.identifier] as number) : schemaOptions

        if (["namedObject"].includes(this.schema[key].type)) {
          const value: any = options.find((o: any) => o.name === row[key])

          if (this.schema[key].type === "namedObject" && value === undefined) {
            const name = row[key] as string

            if (!name) {
              errors[key] = this.$t("variable_not_contain_value", { key })
              continue
            }

            // Using Set to remove any duplicates
            const namedOptions = [...new Set(options.map((v: any) => v.name as string))]
            const closestName =
              namedOptions.sort(
                (a, b) => compareTwoStrings(b as string, name) - compareTwoStrings(a as string, name)
              )[0] || ""

            errors[key] =
              compareTwoStrings(closestName as string, name) > STRING_SIMILARITY_THRESHOLD
              || !namedOptions
                ? this.$t("variable_not_valid_option_with_suggestion", {
                    value: name,
                    suggestion: closestName,
                  })
                : this.$t("variable_not_valid_option_with_options", {
                    value: name,
                    options: namedOptions.join("\n - "),
                  })
          }

          row[key] = value || row[key]
        }
        if (["array", "namedObjectArray"].includes(this.schema[key].type)) {
          const array = row[key] as string[]

          if (isEmpty(options)) continue
          const originalOptions = options
          options = this.schema[key].type === "array" ? options : options.map((o: any) => o.name)

          const diff = difference(array, options as string[])
          if (!diff.length) {
            if (this.schema[key].type === "namedObjectArray") {
              row[key] =
                originalOptions.filter(
                  o => !isEmpty(array.filter(v => v.toLowerCase() == o.name.toLowerCase()))
                ) || []
            }
            continue
          }

          if (diff.length === 1) {
            const closestName =
              options.sort(
                (a, b) => compareTwoStrings(b, diff[0]) - compareTwoStrings(a, diff[0])
              )[0] || ""

            errors[key] =
              compareTwoStrings(closestName, diff[0]) > STRING_SIMILARITY_THRESHOLD
                ? this.$t("variable_not_valid_option_with_suggestion", {
                    value: diff[0],
                    suggestion: closestName,
                  })
                : this.$t("variable_not_valid_option_with_options", {
                    value: diff[0],
                    options: options.join("\n - "),
                  })
            continue
          }

          const last = diff.pop()
          const summation = `${diff.join(", ")} and ${last}`
          errors[key] = this.$t("variable_not_valid_option_with_options", {
            value: summation,
            options: options.join("\n - "),
          })
        }
        if (this.schema[key].type == "enum") {
          const option = row[key] as string
          if (options.includes(option)) continue
          
          const closestName =
            options.sort(
              (a, b) => compareTwoStrings(b, option) - compareTwoStrings(a, option)
            )[0] || ""

          errors[key] =
            compareTwoStrings(closestName, option) > STRING_SIMILARITY_THRESHOLD
              ? this.$t("variable_not_valid_option_with_suggestion", {
                  value: option,
                  suggestion: closestName,
                })
              : this.$t("variable_not_valid_option_with_options", {
                  value: option,
                  options: options.join("\n - "),
                })
        }
        if (this.schema[key].type === "number") {
          // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
          if (isNaN(row[key] as number))
            errors[key] = this.$t("variable_not_valid_number", {
              value: row[key],
            })
        }
        if (this.schema[key].type === "integer") {
          // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
          if (isNaN(row[key] as number))
            errors[key] = this.$t("variable_not_valid_number", {
              value: row[key],
            })
          else if (row[key] !== parseInt(row[key] as string))
            errors[key] = this.$t("variable_should_be_round_number", {
              value: row[key],
            })
        }
        if (this.schema[key].type == "boolean") {
          const options: string[] = mergerBooleanOptions

          if (!row[key]) row[key] = ""

          const booleanName = (row[key] as string).toLowerCase()
          const closestBooleanName: string = options.sort(
            (a, b) => compareTwoStrings(b, booleanName) - compareTwoStrings(a, booleanName)
          )[0]

          const compareValue = compareTwoStrings(closestBooleanName, booleanName)
          if (compareValue == 1) {
            row[key] = mergerTrueBooleanOptions.includes(closestBooleanName)
            continue
          }

          errors[key] =
            compareValue > STRING_SIMILARITY_THRESHOLD
              ? this.$t("variable_not_valid_option_with_suggestion", {
                  value: row[key],
                  suggestion: closestBooleanName,
                })
              : this.$t("variable_not_valid_option_with_options", {
                  value: row[key],
                  options: options.join("\n - "),
                })
        }
      }

      if (this.customValidation) {
        const result = this.customValidation(row, errors)
        errors = result.errors
        row = result.values
      }
      return { errors, values: row }
    },
    emitInitialized() {
      this.$emit("initialized", {
        data: this.data,
        processedUploadedData: this.processedUploadedData,
        changedData: this.changedData,
        validatedData: this.validatedData,
        errors: this.errors
      })
    }
  },
  mounted() {
    this.initialize()
  }
})
</script>

<style lang="scss">
.show-changed-data-switch {
  .form-check-label {
    padding-top: 0!important;
    font-weight: 500;
    color: black;
  }
}
.merger-table {
  &-cell {
    padding: 0.5rem;
  }
  .changed {
    background: skyblue;
    .tooltip-box {
      display: block;
    }
  }
  .canValidate .changed {
    background: lightgreen;
  }
  .error {
    background: rgb(255, 131, 131) !important;
    .tooltip-box {
      display: block;
    }
  }
  .tooltip-box {
    display: none;
  }
  td:has(.merger-table-cell) {
    padding: 0;
  }
  .VuePagination__count {
    display: none;
  }
}

</style>