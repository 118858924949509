import { manuStore } from "@/store"

export default function (id) {
  const index = manuStore.materials.findIndex(x => x.id == id)
  if (index >= 0) {
    return manuStore.materials[index]
  }

  return {}
}
