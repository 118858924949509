import { defineStore } from "pinia"
import { forEach } from "lodash-es"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { addOrUpdate, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"
import type { CreatePricing, Pricing } from "@/interfaces"
import type { OptimisticAddOptionInterface, OptimisticUpdateOptionInterface } from "@/interfaces"
import { customerStore, userStore } from "."

export default defineStore("pricing", {
  state: () => ({
    all: [],
  }),
  getters: {
    mappedData: state =>
      state.all.map(v => ({
        ...v,
        created_by: userStore.all.find(u => u.id === v.created_by) || userStore.current,
        updated_by: userStore.all.find(u => u.id === v.updated_by) || userStore.current,
        created: v.created || new Date(),
        updated: v.updated || new Date(),
        customers: customerStore.all.filter(c => c.pricing === v.id), // customer that has related pricing applied
      })),
  },
  actions: {
    fetchAll({ persist = false } = {}) {
      paginatedFetch({
        url: "/v1/pricings",
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
    add(values: CreatePricing, options: OptimisticAddOptionInterface = {}) {
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/pricings",
        ...options,
      })
    },
    update(values: Pricing, options: OptimisticUpdateOptionInterface = {}) {
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/pricings/${values.id}`,
        ...options,
      })
    },
    remove(id: number) {
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/pricings/${id}`,
        id,
      })
    },
  },
})
