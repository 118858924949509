import { defineStore } from "pinia"
import axios from "axios"
import type { _Manufacturer } from "@/interfaces"

export default defineStore("manu", {
  state: () => ({
    sheets: [],
    profiles: [],
    machines: [],
    manufacturer: null as _Manufacturer,
  }),
  getters: {},
  actions: {
    getManufacturerByNickname(nickname: string) {
      return new Promise<_Manufacturer>((resolve, reject) => {
        if (nickname == undefined) {
          nickname = this.manufacturer?.nickname
        }

        if (nickname && this.manufacturer?.nickname === nickname)
          return resolve(this.manufacturer)

        axios
          .get<_Manufacturer>(`/v1/manufacturers/${nickname}`)
          .then(({ data }) => {
            this.manufacturer = data
            localStorage.setItem("manufacturer", nickname)
            resolve(this.manufacturer)
          })
          .catch(reject)
      })
    },
  },
})
