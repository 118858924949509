<template>
  <VueRecaptcha
    :sitekey="siteKey"
    :load-recaptcha-script="true"
    @verify="handleSuccess"
    @error="handleError"
  />
</template>

<script lang="ts" setup>
import { VueRecaptcha } from "vue-recaptcha"

const siteKey = "6Ld_J30UAAAAADkBJ0rXWoRdMsXUqcslmjMl-2ot"

const emit = defineEmits(["verified"])

const handleError = () => {
  console.error("generate recaptcha error")
}

const handleSuccess = (token: string) => {
  emit("verified", token)
}
</script>
