<template>
  <i
    v-if="iconIsFontAwesomeType"
    :class="['fa-icon', props.type ? props.type : 'fa-solid', icon, props.size]"
  />

  <BaseCIcon v-else />
</template>

<script lang="ts" setup>
import { computed, h } from "vue-demi"
import { asyncComputed } from "@vueuse/core"
import { dynamicallyImportIcon } from "@/libraries/helpers"

const props = withDefaults(defineProps<{
    icon: string
    size?: string
    customClasses?: string | string[] | object
    type?: string
  }>(),
  {
    customClasses: "",
    type: null
  }
)

const icon = asyncComputed<string | string[]>(async () => {
    if (props.icon.startsWith("fa")) return props.icon
    return await dynamicallyImportIcon(props.icon)
  }, ""
)

const iconIsFontAwesomeType = computed(
  () => typeof icon.value === "string" && icon.value.startsWith("fa")
)

// This component is based from @coreui/icons-vue
// Because it has some issue and seems not well maintained, so I created this component
const BaseCIcon = computed(() => {
  const iconCode = Array.isArray(icon.value) ? icon.value[1] || icon.value[0] : icon.value;
  const scale = Array.isArray(icon.value) && icon.value.length > 1 ? icon.value[0] : '64 64';
  return h('svg', {
    xmlns: 'http://www.w3.org/2000/svg',
    class: ["icon"],
    viewBox: `0 0 ${scale}`,
    innerHTML: iconCode,
    role: 'img',
  })
});
</script>
