<template>
  <div class="stepper-wrapper">
    <ol class="stepper">
      <li
        v-for="(item, i) in props.items"
        :key="i"
        class="stepper__item"
        :class="{
          active: isFinishedHandler(item, i),
          current: isCurrentHandler(item, i)
        }"
      >
        <i :class="item.icon" class="stepper__icon" />
        <h3 class="stepper__title">{{ item.title }}</h3>
        <p v-if="item.description" class="stepper__desc">
          {{ item.description }}
        </p>
      </li>
    </ol>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"

const props = withDefaults(
  defineProps<{
    items: any[]
    modelValue?: number | string
    isDone?: boolean
    isFinished?: (params: any, index: number) => boolean
    isCurrent?: (params: any, index: number) => boolean
  }>(),
  {
    modelValue: 0,
    isDone: false,
    isFinished: undefined,
    isCurrent: undefined,
  }
)

const isFinishedHandler = computed(() => props.isFinished ? props.isFinished : (_: any, i: number) => i < parseInt(props.modelValue as string) || props.isDone)
const isCurrentHandler = computed(() => props.isCurrent ? props.isCurrent : (_: any, i: number) => i == props.modelValue)
</script>

<style lang="scss" scoped>
$circle-size: clamp(1.5rem, 5vw, 3rem);
$spacing: 0.1rem;

.stepper {
  display: flex;
  padding: 0;
  margin: 0;
}

.stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  position: relative;

  &:before {
    --size: 3rem;
    content: "";
    display: block;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    background-color: lightgrey;
    margin: 0 auto 1rem;
  }
  &.current:before {
    background-color: #20a8d8;
  }
  &.active:before {
    background-color: #2eb85c;
  }

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc($circle-size / 2);
      width: calc(100% - $circle-size - calc($spacing * 2));
      left: calc(50% + calc($circle-size / 2 + $spacing));
      height: 3px;
      background-color: #e0e0e0;
      order: -1;
    }
    &.active:after {
      background-color: #2eb85c;
    }
  }
}

.stepper__icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 14px;
  color: white;
  font-size: 18px;
}

.stepper__title {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: $spacing;
  padding-right: $spacing;
}

/*** Non-demo CSS ***/

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 1rem;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
</style>
