<template>
  <CCard>
    <CCardBody>
      <CButton
        class="btn-space btn-sm floatRight"
        color="secondary"
        @click="visible = false"
      >
        <i class="fa fa-times" />
      </CButton>
      <div class="successBlock">
        <i class="fa fa-times fa-5x" style="color: red" />
        <h3>{{ $t("upload_error") }}</h3>
        <p>
          <span v-html="props.message" />
        </p>
      </div>
    </CCardBody>
  </CCard>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"

const props = defineProps<{ show: boolean, message: string }>()

const emit = defineEmits(["update:model-value"])

const visible = computed({
  get: () => props.show,
  set: (v) => emit("update:model-value", v)
})
</script>
