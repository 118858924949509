<template>
  <group :id="`model_${props.part.id}`">
    <inline
      v-if="model3D"
      :id="`inline_model_${props.part.id}`"
      :key="model3D"
      ref="inlineElement"
      :DEF="`model_${props.part.id}`"
      :nameSpaceName="props.part.id"
      :url="model3D"
      :mapDEFToID="false"
      contentType="model/x3d+xml"
    />
  </group>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue-demi"
import type { Part } from "@/interfaces"
import { partStore } from "@/store"

const props = defineProps<{ part: Part }>()
const inlineElement = ref<HTMLElement>()
defineExpose({ inlineElement })

const model3D = computed(() => partStore.all3DModels[props.part?.id])
</script>
