import { isPlainObject } from "lodash-es"

function mapStringsToDates(doc: any, pattern = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d/) {
  if (typeof doc === "string") {
    const match = doc.match(pattern)
    return match ? new Date(`${match[0]}.000Z`) : doc
  }

  if (Array.isArray(doc)) return doc.map(item => mapStringsToDates(item, pattern))

  if (!isPlainObject(doc)) return doc

  for (const key in doc) doc[key] = mapStringsToDates(doc[key])

  return doc
}

export default mapStringsToDates
