import { defineStore } from "pinia"
import type { Toast } from "@/interfaces"

export default defineStore("toasts", {
  state: () => ({
    toasts: [] as Toast[],
  }),
  actions: {
    init() {
      if (localStorage.error) this.toasts.push({ color: "danger", message: localStorage.error })
      localStorage.removeItem("error")
    },
  },
})
