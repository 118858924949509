import { manuStore } from "@/store"

export default function (id) {
  const index = manuStore.machines.findIndex(x => x.id == id)
  if (index >= 0) {
    return manuStore.machines[index]
  }

  return {}
}
