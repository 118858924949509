import { defineStore } from "pinia"
import { forEach } from "lodash-es"
import type { Setting } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { materialStore } from "."
import { addOrUpdate, getId, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"

export default defineStore("setting", {
  state: () => ({
    all: [],
  }),
  getters: {
    mappedData: state =>
      state.all.map(setting => {
        return {
          ...setting,
          materials: materialStore.all.filter(material =>
            setting.materials?.includes(material.id)
          ),
        }
      }),
  },
  actions: {
    fetchAll({ persist = false } = {}) {
      paginatedFetch({
        url: "/v1/settings",
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
    update(values: Setting) {
      values.materials = values.materials?.map(getId)
      optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/settings/${values.id}`,
      })
    },
  },
})
