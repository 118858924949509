<template>
  <div class="animated fadeIn">
    <VTables
      ref="table"
      :title="$t('purchase')"
      :subtitle="$t('all_orders')"
      :add-text="$t('start_order')"
      :edit-text="$t('edit_order')"
      :columns="tableColumns"
      :show-add-button="true"
      :row-click="
        ({ row }) =>
          router.push(`/orders/${(row as Order).id}${(row as Order).status === 'CREATED' ? '/edit' : ''}`)
      "
      :add-button-click="() => router.push('/orders/new')"
      :action-delete="allowDelete"
      :remove="({ id }) => orderStore.remove(id)"
      :make-form="() => ({})"
      :table-options="tableOptions"
      :default-sort="{ column: 'date__created', ascending: false }"
      :data="orderStore.mappedData"
    >
      <template #table-header>
        <CRow>
          <CCol class="d-flex justify-content-end align-items-center">
            <div class="text-body-secondary form-check form-check-inline statusDiv">
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-created"
                  class="form-check-input"
                  type="checkbox"
                  value="CREATED"
                />
                {{ $t("created") }} ({{
                  orderStore.mappedData.filter(row => row.status === "CREATED").length
                }})
              </label>
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-in_review"
                  class="form-check-input"
                  type="checkbox"
                  value="IN_REVIEW"
                />
                {{ $t("in_review") }} ({{
                  orderStore.mappedData.filter(row => row.status === "IN_REVIEW").length
                }})
              </label>
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-confirmed"
                  class="form-check-input"
                  type="checkbox"
                  value="CONFIRMED"
                />
                {{ $t("confirmed") }} ({{
                  orderStore.mappedData.filter(row => row.status === "CONFIRMED").length
                }})
              </label>
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-quoted"
                  class="form-check-input"
                  type="checkbox"
                  value="QUOTED"
                />
                {{ $t("quoted") }} ({{
                  orderStore.mappedData.filter(row => row.status === "QUOTED").length
                }})
              </label>
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-ordered"
                  class="form-check-input"
                  type="checkbox"
                  value="ORDERED"
                />
                {{ $t("ordered") }} ({{
                  orderStore.mappedData.filter(row => row.status === "ORDERED").length
                }})
              </label>
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-produced"
                  class="form-check-input"
                  type="checkbox"
                  value="PRODUCED"
                />
                {{ $t("produced") }} ({{
                  orderStore.mappedData.filter(row => row.status === "PRODUCED").length
                }})
              </label>
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-completed"
                  class="form-check-input"
                  type="checkbox"
                  value="COMPLETED"
                />
                {{ $t("completed") }} ({{
                  orderStore.mappedData.filter(row => row.status === "COMPLETED").length
                }})
              </label>
              <label class="form-check-label">
                <input
                  v-model="orderStore.statusFilters"
                  name="checkbox-cancelled"
                  class="form-check-input"
                  type="checkbox"
                  value="CANCELLED"
                />
                {{ $t("cancelled") }} ({{
                  orderStore.mappedData.filter(row => row.status === "CANCELLED").length
                }})
              </label>
            </div>
          </CCol>
        </CRow>
      </template>
      <template #reference="{ row }">
        <b v-if="row.reference">{{ row.reference }}</b>
        <b v-else>/</b>
        <div
          class="small text-body-secondary"
          style="cursor: pointer"
          @click.stop="router.push(`/orders/${row.id}/edit`)"
        >
          <i class="fa fa-copy" @click.stop="copyTextToClipboard(row.id, `${row.id} ${$t('copied_to_clipboard')}`)" />
          {{ row.id }}
        </div>
      </template>
      <template #total_amount="{ row }">
        <div>{{ row.formatted_total_amount.isValid ? row.formatted_total_amount.total : "/" }}</div>
      </template>
      <template #delivery_type="{ row }">
        <CBadge v-if="row.delivery_type === 'SHIPPING'" color="warning">
          {{ $t("delivery") }}
        </CBadge>
        <CBadge v-if="row.delivery_type == 'PICKUP'" color="info">
          {{ $t("pickup") }}
        </CBadge>
        <CBadge v-if="row.delivery_type == null" color="danger">
          {{ $t("not_set") }}
        </CBadge>
      </template>
    </VTables>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, computed, ref } from "vue-demi"
import { useRouter, useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import { forEach, difference } from "lodash-es"
import type { Order, VTableOptions } from "@/interfaces"
import { orderStore, authStore } from "@/store"
import {
  dateFilterAlgorithm,
  dateToFilterString,
  getDeliveryType,
  dateCustomSorting,
  copyTextToClipboard
} from "@/libraries/helpers"
import VTables from "@/components/VTables.vue"

const i18n = useI18n()
useMeta({ title: i18n.t("orders") })

const tableColumns = ref([
  "reference",
  "description",
  "customer",
  "status",
  "total_amount",
  "total_parts",
  "date__delivery_date",
  "delivery_type",
  "date__created",
  "date__updated",
])
const tableOptions = computed<VTableOptions>(() => ({
  headings: {
    date__created: i18n.t("added"),
    customer: i18n.t("customer"),
    order_id: i18n.t("order_no"),
    total_parts: i18n.t("total_parts"),
    date__delivery_date: i18n.t("delivery_date_small"),
    delivery_type: i18n.t("delivery_type_small"),
    total_amount: i18n.t("total"),
    date__updated: i18n.t("updated"),
    actions: i18n.t("actions"),
  },
  sortable: tableColumns.value.filter(c => c !== "actions"),
  filterable: tableColumns.value.filter(c => c !== "actions"),
  filterAlgorithm: {
    customer(row: Order, query: string) {
      const str = [row.customer?.name || "", row.customer?.id || ""]
        .join("###")
        .toLowerCase()
      return str.includes(query.toLowerCase())
    },
    status(row: Order, query: string) {
      const str = i18n.t(row.status.toLowerCase()).toLowerCase()
      return str.includes(query.toLowerCase())
    },
    reference(row: Order, query: string) {
      const str = (row.reference || "/").toLowerCase()
      return str.includes(query.toLowerCase())
    },
    total_amount(row: Order, query: string) {
      const str = row.formatted_total_amount?.isValid ? row.formatted_total_amount?.total : "/"
      return str.includes(query.toLowerCase())
    },
    delivery_type(row: Order, query: string) {
      const str = i18n.t(getDeliveryType(row.delivery_type).toLowerCase()).toLowerCase()
      return str.includes(query.toLowerCase())
    },
    date__delivery_date(row: Order, query: string) {
      return row.delivery_date !== null
        ? dateFilterAlgorithm(row, query, "delivery_date")
        : i18n.t("not_set").toLowerCase().includes(query.toLowerCase())
    },
    date__created: (row: Order, query: string) =>
      dateFilterAlgorithm(row, query, "created"),
    date__updated: (row: Order, query: string) =>
      dateFilterAlgorithm(row, query, "updated"),
  },
  customSorting: {
    date__created: (ascending: boolean) => (a: Order, b: Order) => dateCustomSorting(ascending, a, b, "created"),
    date__updated: (ascending: boolean) => (a: Order, b: Order) => dateCustomSorting(ascending, a, b, "updated"),
    date__delivery_date: (ascending: boolean) => (a: Order, b: Order) => dateCustomSorting(ascending, a, b, "delivery_date"),
    customer(ascending: boolean) {
      return function (a: Order, b: Order) {
        const aName = a.customer?.name || ""
        const bName = b.customer?.name || ""
        if (ascending) return aName >= bName ? 1 : -1
        return aName <= bName ? 1 : -1
      }
    },
    total_amount(ascending: boolean) {
      return function (a: Order, b: Order) {
        const aPrice = a.formatted_total_amount?.isValid ? a.formatted_total_amount?.rawTotal : 0
        const bPrice = b.formatted_total_amount?.isValid ? b.formatted_total_amount?.rawTotal : 0
        return ascending ? aPrice - bPrice : bPrice - aPrice
      }
    }
  },
  customFilters: [
    {
      name: "all",
      callback(row: Order, query: string) {
        const values = [
          row.reference || "/",
          row.description || "",
          [row.customer?.name || "", row.customer?.id || ""].join("###"),
          row.status || "",
          (row.batches || []).length.toString(),
          row.formatted_total_amount?.isValid ? row.formatted_total_amount?.total : "/",
          i18n
            .t(getDeliveryType(row.delivery_type).toLowerCase())
            .toLowerCase(),
          row.delivery_date !== null
            ? dateToFilterString(row, "delivery_date")
            : i18n.t("not_set"),
          dateToFilterString(row, "created"),
          dateToFilterString(row, "updated"),
        ]
        return values.join("###").toLowerCase().includes(query.toLowerCase())
      },
    },
  ],
}))

const router = useRouter()
const route = useRoute()

const changeStatusFilter = (status: string) => {
  if (!status) return
  orderStore.statusFilters = [
    "created",
    "in_review",
    "confirmed",
    "quoted",
    "ordered",
    "produced",
    "completed",
    "cancelled"
  ].includes(status)
    ? [(status).toUpperCase()]
    : []
}
router.beforeEach((r: typeof route) => {
  changeStatusFilter((r.query?.status) as string)
})
watch(
  () => orderStore.statusFilters,
  (currValue, prevValue) => {
    const uncheckedArray = difference(prevValue, currValue)
    const checkedArray = difference(currValue, prevValue)
    if (checkedArray.length > 0) {
      forEach(checkedArray, (status) => {
        orderStore.statusFilterChanged({ status, added: true })
      })
    }
    if (uncheckedArray.length > 0) {
      forEach(uncheckedArray, (status) => {
        orderStore.statusFilterChanged({ status, added: false })
      })
    }
  }
)

const allowDelete = computed(() => authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_admin)
onMounted(() => {
  if (allowDelete) {
    tableColumns.value.push("actions")
  }
  const defaultStatus = route.query?.status as string
  if (defaultStatus) {
    changeStatusFilter(defaultStatus)
  }
  forEach(orderStore.statusFilters, (status) => {
    orderStore.fetchByStatus({ status })
  })
})
</script>
