export default async function dynamicallyImportIcon(icon: string) {
  const iconName = icon.replace(/-(\w)/g, match => match[1].toUpperCase())
  const fileName = icon.replace(
    /([a-z])([A-Z])/g,
    match => `${match[0]}-${match[1].toLowerCase()}`
  )
  
  const { [iconName]: importedIcon } = await import(
    `../../../node_modules/@coreui/icons/dist/esm/free/${fileName}.js`
  )
  
  return importedIcon as string[]
}
