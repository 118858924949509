export interface RegisterInterface {
  username: string
  recaptcha: string
  password: string
  nickname: string
  email: string
  is_company: boolean
  is_manufacturer: boolean
}

export const authErrorReasons = {
  NONE: "NONE",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  WRONG_CREDENTIALS: "WRONG_CREDENTIALS",
  FAILED_TO_FETCH_REQUIRED_DATA: "FAILED_TO_FETCH_REQUIRED_DATA",
}
