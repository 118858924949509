import type { Order } from "@/interfaces"
import { toCurrency } from "@/libraries/helpers"

export const orderCosts = (order: Order) => {
  let subtotal = order?.total_amount - order?.shipping_amount
  let tax = order?.tax_amount
  let total = order?.total_amount + order?.shipping_amount + order?.tax_amount

  // these calculation based on the batches, and can be incorrect
  // all NOT QUOTED orders doesn't have fixed price yet
  if (["CREATED", "IN_REVIEW", "CONFIRMED"].includes(order?.status)) {
    subtotal = order?.batches?.reduce((a, { total_amount }) => a + total_amount, 0) ?? 0
    tax = (subtotal + (order?.shipping_amount ?? 0)) * 0.21
    total = subtotal + tax + (order?.shipping_amount ?? 0)
  }

  return {
    subtotal: toCurrency(subtotal) as string,
    rawSubtotal: subtotal,
    shipping: toCurrency(order?.shipping_amount ?? 0) as string,
    rawShipping: order?.shipping_amount,
    tax: toCurrency(tax) as string,
    rawTax: tax,
    total: toCurrency(total) as string,
    rawTotal: total,
    isValid: !["CREATED", "IN_REVIEW", "CONFIRMED"].includes(order?.status),
  }
}
