import { defineStore } from "pinia"
import axios from "axios"
import { saveAs } from "file-saver"
import { cloneDeep, forEach } from "lodash-es"
import type { File } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { addOrUpdate, paginatedFetch } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"

const cacheKey = "files"

export default defineStore("file", {
  state: () => ({
    all: [],
  }),
  getters: {},
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as File[]
    },
    fetchAll({ persist = false } = {}) {
      paginatedFetch({
        url: "/v1/files",
        persist,
        callback: data => {
          const files = cloneDeep(this.all)
          forEach(data || [], v => addOrUpdate(files, v, ["id"]))
          this.all = files
        },
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      .finally(() => setCache(cacheKey, this.all))
    },
    fetchByIds(ids: number[]) {
      return new Promise((resolve, reject) => {
        const fileRequests = []
        forEach(ids, id => fileRequests.push(axios.get(`/v1/files/${id}`)))

        Promise.all(fileRequests)
          .then(responses => {
            const existingFiles = cloneDeep(this.all)
            const files = responses.map(r => r.data)
            forEach(files, v => addOrUpdate(existingFiles, v, ["id"]))

            this.all = existingFiles
          })
          .catch(reject)
          .finally(() => setCache(cacheKey, this.all))
      })
    },
    download(fileId: number) {
      return new Promise((resolve, reject) => {
        if (!fileId) return reject("Error: file not found")

        axios
          .get(`/v1/files/${fileId}/data`)
          .then(({ data }) => {
            const fileObject = this.all.find(file => file.id === fileId)
            const blob = new Blob([data], { type: "text/plain;charset=utf-8" })
            saveAs(
              blob,
              fileObject ? `${fileObject.name}.${fileObject.extension}` : fileId
            )
          })
          .catch(reject)
      })
    },
    add(file: Blob) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append("file", file)
        axios
          .post("/v1/files", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            const files = cloneDeep(this.all)
            addOrUpdate(files, data, ["id"])
            this.all = files
            resolve(data)
          })
          .catch(reject)
      })
    },
  },
})
