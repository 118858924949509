import { defineStore } from "pinia"
import { forEach } from "lodash-es"
import type { CreateCustomer, CreateCustomerUser, Customer } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { addressStore, organizationStore, pricingStore } from "."
import { addOrUpdate, getId, optimisticAdd, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"

const mapData = (customer: Customer) => {
  return {
    ...customer,
    pricing: pricingStore.all.find(pricing => pricing.id === getId(customer.pricing)),
    addresses: addressStore.all.filter(address => customer.addresses?.includes(address.id)),
    manufacturers: organizationStore.mappedData.find(organization => organization.id === customer.id)?.manufacturers,
  }
}

export default defineStore("customer", {
  state: () => ({
    all: [],
    users: [],
  }),
  getters: {
    mappedData: state => state.all.map(mapData),
  },
  actions: {
    fetchAll({ persist = false } = {}) {
      paginatedFetch({
        url: "/v1/customers",
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
    fetchOne(id: number, { persist = false } = {}) {
      paginatedFetch({
        url: `/v1/customers/${id}`,
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
      })
    },
    fetchUsers(id: number, { persist = false } = {}) {
      if (!id) return
      this.users = []
      paginatedFetch({
        url: `/v1/customers/${id}/users`,
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.users, v, ["id"])),
        runCallbackCondition: data => this.users.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
    add(values: CreateCustomer) {
      return optimisticAdd({
        allObjects: [this.all, organizationStore.all],
        values,
        url: "/v1/customers",
      })
    },
    update(values: Customer) {
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/customers/${values.id}`,
      })
    },
    addUser(customerId: number, values: CreateCustomerUser) {
      return optimisticAdd({
        allObjects: [this.users],
        values,
        url: `/v1/customers/${customerId}/users`,
      })
    },
    addWithAddress(values: any) {
      return new Promise(async (resolve, reject) => {
        try {
          const customer = await this.add(values.customer)
          const address = await organizationStore.addAddress(getId(customer as Customer), values.address)
          if (!address.is_active) await addressStore.update({ id: address.id, is_active: true })
          resolve(customer)
        } catch (error) {
          reject(error)
        }
      })
    },
  },
})
