import { toastStore } from "@/store"

export default function(text: string, successText: string = "Copied to clipboard") {
  return new Promise(resolve => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toastStore.toasts.push({ color: "info", message: successText })
        resolve(true)
      })
      .catch()
  })
}