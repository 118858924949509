<template>
  <div class="wrapped-with-lpypvnfoqk">
    <ModalWrapper v-model="isVisible">
      <CModal
        id="visible-column-modal"
        :visible="isVisible"
        backdrop="static"
        @close="isVisible = false"
      >
        <CModalHeader>
          <CModalTitle>Visible columns</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <VueDraggable
            v-model="localOptions"
            item-key="id"
            @start="drag = true"
            @end="drag = false"
          >
            <template #item="{ element }">
              <div class="mb-3">
                <div class="visible-column-modal__checkbox-container">
                  <CFormCheck
                    :id="`checkbox-${element.name}`"
                    v-model="element.enabled"
                    class="form-check"
                    :button="undefined"
                    :label="capitalize(element.name)"
                    :checked="element.enabled"
                  />
                </div>
              </div>
            </template>
          </VueDraggable>
        </CModalBody>
        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" @click="save">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import VueDraggable from "vuedraggable"
import { capitalize } from "lodash-es"
import { watch, ref, computed } from "vue-demi"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const props = withDefaults(
  defineProps<{
    modelValue?: any[]
    options?: any
    show?: boolean
  }>(),
  {
    modelValue: () => [],
    options: () => [],
    show: false
  }
)

const emit = defineEmits(["update:modelValue", "show"])

const drag = ref(false)
const localOptions = ref(
  [...new Set(props.modelValue.concat(props.options))].map(option => ({
    name: option,
    enabled: props.modelValue.find(name => name === option),
  }))
)

const isVisible = computed<boolean>({
  get: () => props.show,
  set: (v) => emit("show", v),
})

watch(() => props.modelValue,
  () => {
    localOptions.value = localOptions.value.map(option => ({
      name: option.name,
      enabled: props.modelValue.find(name => name === option.name),
    }))
  },
  { deep: true }
)

const save = () => {
  isVisible.value = false
  emit(
    "update:modelValue",
    localOptions.value.filter(o => o.enabled).map(o => o.name)
  )
}
</script>

<style lang="scss">
.wrapped-with-lpypvnfoqk {
  .visible-column-modal {
    &__checkbox-container {
      .form-check {
        display: inline-flex;
        input {
          cursor: pointer;
        }
        label {
          cursor: move;
          margin: 2px 0.5rem;
        }
      }
    }
  }
}
</style>
