<template>
  <ul>
    <li v-for="t in props.total" :key="t" />
  </ul>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    total?: number
  }>(),
  {
    total: 3
  }
)
</script>

<style lang="scss" scoped>
ul {
  display: flex;
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 50%;
    animation: dots 2s ease-in-out infinite;
    background: #364253;
    @for $i from 1 through 10 {
      &:nth-child(3n + #{$i}) {
        animation-delay: (0.2 * $i) - 2s;
      }
    }
  }
}

@keyframes dots {
  0%,
  40%,
  100% {
    transform: scale(0.2);
  }
  20% {
    transform: scale(0.6);
  }
}
</style>
