import { defineStore } from "pinia"
import axios from "axios"
import { forEach } from "lodash-es"
import type { CreateManufacturer, Manufacturer, _Manufacturer } from "@/interfaces"
import { DEFAULT_LOGO_URL, UPDATE_DATA_THRESHOLD, BLANK_IMAGE_URL } from "@/constants"
import { addressStore, authStore, organizationStore } from "."
import { addOrUpdate, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch, sleep } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"
import { i18n } from "@/plugins/i18n"

const cacheKey = "manufacturers"

export default defineStore("manufacturer", {
  state: (): any => ({
    all: [],
    current: null as _Manufacturer,
    logoKey: Date.now(),
    fetchedLogo: null,
  }),
  getters: {
    mappedData: state =>
      state.all.map(obj => {
        return {
          ...obj,
          addresses: addressStore.all.filter(address => (obj.addresses || []).includes(address.id)),
        }
      }),
    manufacturerNickname: (): string => localStorage["manufacturer"],
    rawLogo(state) {
      if (this.fetchedLogo) {
        localStorage["manufacturer-logo"] = this.fetchedLogo
        return this.fetchedLogo
      }

      if (!this.manufacturerNickname || !state.current || !state.current?.logo) {
        localStorage["manufacturer-logo"] = BLANK_IMAGE_URL
        return BLANK_IMAGE_URL
      }

      return localStorage["manufacturer-logo"] || DEFAULT_LOGO_URL
    },
    logo(state) {
      const current_logo = (this.logo || "") as unknown as string

      if (current_logo.includes(this.rawLogo || "") && current_logo.includes((state.logoKey as string) || ""))
        return this.logo

      // blob:url returns FILE_NOT_FOUND error if a key query added
      return !(this.rawLogo || "").includes("blob") ? `${this.rawLogo}?key=${state.logoKey}` : this.rawLogo
    },
  },
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Manufacturer[]
    },
    getManufacturerByNickname(nickname?: string) {
      return new Promise<_Manufacturer>((resolve, reject) => {
        if (nickname === undefined) {
          nickname = this.manufacturerNickname
        }

        if (!nickname) return reject(i18n.t("manufacturer_nickname_not_found"))

        axios
          .get(`/v1/manufacturers/${nickname}`)
          .then(({ data }) => {
            this.current = data
            localStorage.setItem("manufacturer", nickname)
            resolve(data)
          })
          .catch(err => {
            localStorage.removeItem("manufacturer-logo")
            this.current = undefined
            reject(err)
          })
      })
    },
    async fetchAll({ persist = false, prefetching = false } = {}) {
      return new Promise((resolve, reject) => {
        paginatedFetch({
          url: "/v1/manufacturers",
          persist,
          prefetching,
          queryParams: {
            order_by: "created",
          },
          callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
          runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
        })
          .then(() => {
            setCache(cacheKey, this.all)
            resolve(this.all)
          })
          .catch(reject)
      })
    },
    fetchOne(id: number, { persist = false } = {}) {
      paginatedFetch({
        url: `/v1/manufacturers/${id}`,
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
      })
    },
    add(values: CreateManufacturer) {
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/manufacturers",
        onSuccess: data => {
          setCache(cacheKey, this.all)
          organizationStore.fetchByIds([data.id])
        },
      })
    },
    update(values: Manufacturer) {
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/manufacturers/${values.id}`,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    remove(id: number) {
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/manufacturers/${id}`,
        id,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    updateLogoUrlByNickname(nickname: string) {
      const manufacturer = this.all.find(m => m.nickname === nickname)
      if (!manufacturer) return
      const logoUrl = `${axios.defaults.baseURL}/v1/manufacturers/${nickname}/logo`
      localStorage["manufacturer-logo"] = logoUrl
    },
    getLogoByNickname(nickname?: string) {
      return new Promise(async resolve => {
        try {
          if (nickname === undefined) {
            nickname = this.manufacturerNickname
          }

          if (!nickname) return resolve(undefined)

          const response: any = await axios.get(`/v1/manufacturers/${nickname}/logo?key=${this.logoKey}`, {
            responseType: "blob",
          })

          if (!response?.data) return resolve(undefined)

          const logo = URL.createObjectURL(new Blob([response.data], { type: "text/plain" }))

          this.fetchedLogo = logo

          resolve(logo)
        } catch (error) {
          console.log(error)
        }
      })
    },
  },
})
