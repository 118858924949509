import { isEqual } from "lodash-es"

export default function (firstObject: any, secondObject: any) {
  let isSame = true
  for (const key in firstObject) {
    if (!Object.prototype.hasOwnProperty.call(secondObject, key)) {
      isSame = false
      break
    }
    console.log(
      key,
      typeof firstObject[key],
      isEqual(secondObject[key], firstObject[key]),
      secondObject[key],
      firstObject[key]
    )
    if (!isEqual(secondObject[key], firstObject[key])) {
      isSame = false
      break
    }
  }
  return isSame
}
