export const handleClickEventListener = (isVisible: boolean) => {
  if (isVisible)
    setTimeout(() => document.addEventListener("click", e => onOutsideClick(e)), 100)
  else document.removeEventListener("click", e => onOutsideClick(e))
}

export const onOutsideClick = (event: any, parentClass = "modal-content") => {
  const parentElement = document.querySelector(`.${parentClass}`)
  if (!parentElement) return console.error(`.${parentClass} element not found`)
  if (!parentElement.contains(event.target)) console.log("outside clicked")
}
