export const MULTISELECT_CONTAINER = ".table-responsive.VueTables__wrapper"

export function getMultiselectLeft(param: any) {
  return param.element.left - param.scrollableElement.scrollLeft + 38 + param.app.left
}

export function getMultiLineMultiselectTop(param: any) {
  return (
    param.element.top -
    param.app.top -
    param.app.scrollTop +
    param.body.height -
    param.app.height -
    30
  )
}

export function getMultiselectTop(param: any) {
  return (
    param.element.offsetTop -
    param.scrollableElement.scrollTop -
    param.app.scrollTop +
    267
  )
}
