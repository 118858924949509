<template>
  <div class="wrap position-fixed d-flex h-100 w-100">
    <div class="image-container">
      <img
        :key="manufacturerStore.logo"
        ref="logo"
        class="navbar-brand-full"
        :src="manufacturerStore.logo"
        :alt="`${manufacturerStore.current?.name} logo`"
        :style="[
          imageWidth > imageHeight
            ? `height: ${defaultImageSize}px`
            : `width: ${defaultImageSize}px`,
        ]"
        :data-fallback="BLANK_IMAGE_URL"
        onerror="this.src=this.dataset.fallback;"
      />
    </div>
    <div class="text-container">
      <DotsAnimatedLoading :total="10" />
      <span class="loading-text">{{ $t("loading_env") }}</span>
      <span class="copyright-text">
        {{ $t("powered_by") }} Smartpart
        <sup>&copy;</sup>
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, type Ref, computed } from "vue-demi"
import { BLANK_IMAGE_URL } from "@/constants"
import { manufacturerStore, authStore } from "@/store"
import DotsAnimatedLoading from "@/components/DotsAnimatedLoading.vue"

const emit = defineEmits(["done"])

const logo = ref<Ref>(null) // refs of logo element

const defaultImageSize = 120
const imageWidth = computed<number>(() => logo.value?.offsetWidth)
const imageHeight = computed<number>(() => logo.value?.offsetHeight)

onMounted(async () => {
  if (!authStore.isAuthenticated) return emit("done")
})
</script>

<style scoped lang="scss">
div.wrap {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: var(--prefetch-query-z-index);
}
.image-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  flex-direction: column;
  bottom: 0;
  row-gap: 16px;
  color: #364253;
  .loading-text {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: bold;
    font-family: Montserrat;
    margin-top: 14px;
    line-height: 50px;
    @media (min-width: 1200px) {
      font-size: 40px;
    }
  }
  .copyright-text {
    font-size: calc(1.275rem + 0.3vw);
    font-weight: 400;
    font-family: "Open Sans";
    line-height: 38px;
    @media (min-width: 1200px) {
      font-size: 28px;
    }
  }
  @media (min-height: 600px) {
    position: absolute;
  }
}
</style>
