<template>
  <div class="text-center pdf-viewer">
    <div
      v-if="props.pdfUrl"
      class="pdf-viewer__actions"
    >
      <div
        class="d-flex flex-1 justify-content-center"
      >
        <CButton
          class="border-radius__right__none"
          color="secondary"
          size="sm"
          @click.stop.prevent="calcScale('minus')"
        >
        <i class="fa fa-magnifying-glass-minus" />
        </CButton>
        <input
          v-model="formattedScale"
          class="form-control border-radius__none zoom-input"
          @click.stop
          @focus="showScalePercentage = false"
          @blur="showScalePercentage = true"
        />
        <CButton
          class="border-radius__left__none"
          color="secondary"
          size="sm"
          @click.stop.prevent="calcScale('plus')"
        >
          <i class="fa fa-magnifying-glass-plus" />
        </CButton>

        <CButton
          class="ms-3"
          color="secondary"
          size="sm"
          @click.stop.prevent="fitTo('width')"
        >
          <i class="fa fa-arrows-left-right" />
          {{ $t("fit_to_width") }}
        </CButton>
        <CButton
          class="ms-3"
          color="secondary"
          size="sm"
          @click.stop.prevent="fitTo('height')"
        >
          <i class="fa fa-arrows-up-down" />
          {{ $t("fit_to_height") }}
        </CButton>
      </div>
      <div
        class="position-absolute"
        style="right: 1rem;"
      >
        <CButton
          color="primary"
          size="sm"
          @click.stop.prevent="download()"
        >
          <i class="fa fa-download" />
          {{ $t("download") }}
        </CButton>
      </div>
    </div>
    <!--
      .value of pdf and pages need to be defined because pdf and pages are reactive
      and is a copy of shallowRef on usePDF function
     -->
    <div
      v-for="(page, index) in pages.value"
      :key="index"
      :id="`page-${index + 1}`"
    >
      <VuePDF
        :pdf="pdf.value"
        :page="page"
        :scale="scale / 100"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch, onMounted } from "vue-demi"
import { saveAs } from "file-saver"
import { usePDF, VuePDF } from "@tato30/vue-pdf"

const props = withDefaults(
  defineProps<{
    filename?: string
    pdfUrl?: string
  }>(),
  {
    filename: "file.pdf",
    pdfUrl: null
  }
)

const pdf = ref(null)
const pages = ref<any>([])
const scale = ref(150)
const showScalePercentage = ref(true)

const formattedScale = computed({
  get: () => `${scale.value}${showScalePercentage.value ? "%" : ""}`,
  set(v: string) {
    if (!v) return 0
    let newScale = parseFloat(v)
    newScale = newScale > 300 ? 300 : newScale
    scale.value = Math.abs(newScale)
  }
})

const computePdf = () => {
  if (!props.pdfUrl) return
  const { pdf: newPdf, pages: newPages }: any = usePDF(props.pdfUrl);
  pdf.value = newPdf
  pages.value = newPages
}
const download = () => {
  saveAs(props.pdfUrl, props.filename)
}
const calcScale = (operation: "minus" | "plus" = "plus") => {
  const multiplier = Math.floor(scale.value / 25)
  let newScale = 25 * (multiplier + (operation === "plus" ? 1 : -1))
  newScale = newScale > 300 ? 300 : newScale
  scale.value = newScale < 25 ? 25 : newScale
}
const fitTo = (target: "width" | "height" = "width") => {
  const container = document.querySelector(".pdf-viewer") as any
  const element = document.querySelector("#page-1") as any
  if (!container || !element) return
  const containerLength = target === "width" ? container.parentElement.offsetWidth : container.parentElement.offsetHeight - 33.6
  const elementLength = target === "width" ? element.offsetWidth : element.offsetHeight
  scale.value = Math.floor(containerLength / elementLength * scale.value)
}

watch(
  () => props.pdfUrl,
  () => {
    if (props.pdfUrl) computePdf()
  }
)

onMounted(() => {
  if (props.pdfUrl) computePdf()
})
</script>

<style lang="scss">
.pdf-viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__actions {
    height: 3rem;
    width: 100%;
    background: white;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    position: sticky;
    top: 0px;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0px 1rem;
  }
  input.zoom-input {
    width: 60px;
    height: 27.5px;
  }
}
</style>