<template>
  <div
    v-show="show"
    class="wrapped-with-duqepuprba"
  >
    <ModalWrapper v-model="show">
      <CModal
        fullscreen
        backdrop="static"
        :visible="show"
        :class="{ show }"
        @close="show = false"
      >
        <CModalHeader>
          <CModalTitle>{{ $t("preview_text", { text: pdfViewer.filename }) }}</CModalTitle>
        </CModalHeader>
        <CModalBody
          class="p-0" 
          :class="{
            loading: pdfViewer.loading
          }"
        >
          <PdfViewer
            v-if="!pdfViewer.loading"
            :pdfUrl="pdfViewer.fileUrl"
            :filename="pdfViewer.filename"
          />
          <CSpinner v-else class="my-4" />
        </CModalBody>
      </CModal>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue-demi"
import { globalStore } from "@/store"
import PdfViewer from '@/components/PdfViewer.vue'
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const pdfViewer = computed(() => globalStore.pdfViewer)
const show = computed({
  get: () => globalStore.pdfViewer.show,
  set: (v: boolean) => globalStore.pdfViewer.show = v
})

watch(show, v => {
  if (!v) globalStore.reset(["pdfViewer"])
})
</script>

<style lang="scss">
.wrapped-with-duqepuprba {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  .modal-fullscreen {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-content {
      height: 90%;
      .modal-body {
        background: rgba(0,0,0,0.1);
        &.loading {
          display: flex;
          justify-content: center;
          align-items: center;
          .spinner-border {
            width: 4rem;
            height:4rem;
          }
        }
      }
    }
  }

}
</style>
