<template>
  <div>
    <ModalWrapper v-model="isVisible">
      <CModal :visible="isVisible" backdrop="static" @close="() => (isVisible = false)">
        <CModalHeader>
          <CModalTitle>{{ $t("create_order_for") }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CRow>
            <CCol>
              <template v-if="authStore.authenticatedUser?.is_manufacturer">
                <CCard class="text-body-secondary cardHover" @click="onClick(organizationStore.current)">
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <h5 class="pt-1">
                          <i class="mr-1 fa fa-building" />
                          {{ organizationStore.current?.name }}
                        </h5>
                        <div class="small">#{{ organizationStore.current?.company_number }}</div>
                      </CCol>
                      <CCol
                        class="d-flex flex-column justify-content-end align-items-end"
                      >
                        <div v-if="organizationStore.current?.phone" class="mb-1 small text-end">
                          {{ organizationStore.current?.phone }}
                          <i class="ms-2 fa fa-phone" />
                        </div>
                        <div v-if="organizationStore.current?.email" class="mb-1 small text-end">
                          {{ organizationStore.current?.email }}
                          <i class="ms-2 fa fa-envelope" />
                        </div>
                        <div v-if="organizationStore.current?.domain" class="small text-end">
                          {{ organizationStore.current?.domain }}
                          <i class="ms-2 fa fa-globe" />
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
                <CCard
                  v-if="
                    authStore.authenticatedUser?.is_customer &&
                    customerManufacturer &&
                    customerManufacturer?.id != organizationStore.current?.id
                  "
                  class="text-body-secondary cardHover mt-2"
                  @click="onClick(customerManufacturer)"
                >
                  <CCardBody>
                    <CRow>
                      <CCol>
                        <h5 class="pt-1">
                          <i class="mr-1 fa fa-building" />
                          {{ customerManufacturer?.name }}
                        </h5>
                        <div class="small">
                          #{{ customerManufacturer?.company_number }}
                        </div>
                      </CCol>
                      <CCol
                        class="d-flex flex-column justify-content-end align-items-end"
                      >
                        <div
                          v-if="customerManufacturer?.phone"
                          class="mb-1 small text-end"
                        >
                          {{ customerManufacturer?.phone }}
                          <i class="ms-2 fa fa-phone" />
                        </div>
                        <div
                          v-if="customerManufacturer?.email"
                          class="mb-1 small text-end"
                        >
                          {{ customerManufacturer?.email }}
                          <i class="ms-2 fa fa-envelope" />
                        </div>
                        <div v-if="customerManufacturer?.domain" class="small text-end">
                          {{ customerManufacturer?.domain }}
                          <i class="ms-2 fa fa-globe" />
                        </div>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
                <hr class="bigHr" />
              </template>
              <CRow class="mb-2">
                <CCol :sm="4">
                  <h5 class="mt-2 ms-2">
                    {{ authStore.authenticatedUser?.is_manufacturer ? $t("customers") : $t("manufacturers") }}
                  </h5>
                </CCol>
                <CCol :sm="8">
                  <CRow>
                    <CCol>
                      <CFormInput
                        v-model="search"
                        :placeholder="$t('search')"
                        aria-label="Search"
                      />
                    </CCol>
                    <CCol v-if="authStore.authenticatedUser?.is_manufacturer" :sm="4" class="d-grid">
                      <CButton
                        color="success"
                        @click="() => (showAddCustomerModal = true)"
                      >
                        <i class="fa fa-plus" />
                        {{ $t("add") }}
                      </CButton>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow
                v-if="filteredDatas.length > 0"
                style="max-height: 50vh; overflow-y: scroll"
              >
                <CCol>
                  <template v-for="(customer, index) in filteredDatas">
                    <CCard
                      v-if="organizationStore.current?.id != customer.id"
                      :key="index"
                      class="mt-2 text-body-secondary cardHover"
                    >
                      <CCardBody @click="onClick(customer)">
                        <CRow>
                          <CCol>
                            <h5 class="pt-1">
                              <i class="mr-1 fa fa-building" />
                              {{ customer.name }}
                            </h5>
                            <div class="small">#{{ customer.company_number }}</div>
                          </CCol>
                          <CCol
                            class="d-flex flex-column justify-content-end align-items-end"
                          >
                            <div v-if="customer.phone" class="mb-1 small">
                              {{ customer.phone }}
                              <i class="ms-2 fa fa-phone" />
                            </div>
                            <div v-if="customer.email" class="mb-1 small">
                              {{ customer.email }}
                              <i class="ms-2 fa fa-envelope" />
                            </div>
                            <div v-if="customer.domain" class="small">
                              {{ customer.domain }}
                              <i class="ms-2 fa fa-globe" />
                            </div>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </template>
                </CCol>
              </CRow>

              <CRow v-if="filteredDatas.length == 0" class="py-3">
                <CCol>
                  <div class="text-body-secondary text-center">
                    {{ $t("no_results") }}
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CModalBody>
        <CModalFooter />
      </CModal>
    </ModalWrapper>

    <CustomerAddFormModal
      :show="showAddCustomerModal"
      @close="() => (showAddCustomerModal = false)"
      @submit="addCustomer"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, ref, computed } from "vue-demi"
import type { Address, Customer, Organization } from "@/interfaces"
import { organizationStore, authStore, customerStore, addressStore } from "@/store"
import { getId } from "@/interfaces"
import CustomerAddFormModal from "./CustomerAddFormModal.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const props = withDefaults(
  defineProps<{
    modelValue?: boolean
  }>(),
  {
    modelValue: false,
  },
)

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: boolean): void
  (e: "picked", value: Customer): void
}>()

const search = ref("")
const showAddCustomerModal = ref(false)

const customerManufacturer = computed(() => authStore.authenticatedUser?.parentManufacturer)

const isVisible = computed<boolean>({
  get: () => props.modelValue,
  set: (v) => emit("update:modelValue", v),
})

const filteredDatas = computed(() => {
  let datas: any = []
  if (authStore.authenticatedUser?.is_manufacturer) {
    datas = customerStore.all
  }
  return datas.filter(o => o.name.toLowerCase().includes(search.value.toLowerCase()))
})

watch(showAddCustomerModal,
  () => {
    isVisible.value = !showAddCustomerModal.value
  }
)

const onClick = (v: Customer | Organization) => {
  emit("picked", v)
  isVisible.value = false
}

const addCustomer = (v: { customer: Customer, address: Address }) => {
  customerStore.add(v.customer).then(customer => {
    organizationStore.addAddress(getId(customer as Customer), v.address).then(new_address => {
      if (!new_address.is_active) addressStore.update({ id: new_address.id, is_active: true })
      showAddCustomerModal.value = false
    }).catch()
  }).catch()
}

onMounted(() => {
  customerStore.fetchAll()
})
</script>
