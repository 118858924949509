import type { DebouncedFunc, DebounceSettings } from "lodash-es"
import { debounce } from "lodash-es"

export default function <T>(
  func: (args: T[]) => any,
  wait?: number,
  options?: DebounceSettings
): DebouncedFunc<(args: T) => any> {
  let merged: T[] = []

  const d1 = debounce(
    () => {
      func(merged)
      merged = []
    },
    wait,
    options
  )

  const d2 = debounce((arg: T) => {
    merged.push(arg)
    d1()
  })

  d2.cancel = d1.cancel.bind(d1)
  d2.flush = d1.flush.bind(d1)

  return d2
}
