<template>
  <ModalWrapper v-model="isVisible">
    <CModal
      :size="size"
      :visible="isVisible"
      backdrop="static"
      :class="{ isVisible }"
      @close="cancel"
    >
      <CModalHeader>
        <CModalTitle>{{ props.title }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <slot name="before-body" />
        <DynamicFormWrapper
          :form="form"
          @submit="submit"
          @change="values => emit('change', values)"
          @reload="values => emit('reload', values)"
        />
        <slot name="after-body" />
      </CModalBody>
      <CModalFooter class="justify-content-between">
        <CButton color="secondary" @click="cancel">
          {{ props.cancelButtonText || $t("cancel") }}
        </CButton>
        <CButton color="success" type="submit" :form="form.id">
          {{ props.okButtonText || $t("add") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"


const props = withDefaults(
  defineProps<{
    show: boolean
    title: string
    size?: string
    form: any
    okButtonText?: string
    cancelButtonText?: string
    closeAfterSubmit?: boolean
  }>(),
  {
    form: {},
    closeAfterSubmit: true,
  }
)

const emit = defineEmits(["reset", "submit", "change", "reload"])

let isVisible = computed({
  get: () => props.show,
  set: (v: boolean) => {
    if (v == false) emit("reset")
  },
})

function submit(values: any) {
  emit("submit", values)
  if (props.closeAfterSubmit) isVisible.value = false
}
function cancel() {
  emit("change", {})
  isVisible.value = false
}
</script>
