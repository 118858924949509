<template>
	<VueMultiselect
		ref="originalMultiselect"
		v-bind="$attrs"
		class="position-relative"
		@open="repositionDropDown"
  >
    <!--
      This is for handling slots added on component usage
    -->
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </VueMultiselect>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, onUnmounted, ref, type Ref } from "vue-demi"
import VueMultiselect from 'vue-multiselect'

interface BodyParam {
  height: number
}
interface AppParam {
  scrollTop: number
  height: number
}
interface ElementParam {
  top: number
  width: number
}
interface ScrollableElementParam {
  left: number
}

interface calculationParam {
  body: BodyParam
  app: AppParam
  element: ElementParam
  scrollableElement
}

const props = withDefaults(
  defineProps<{
    reposition?: boolean
    scrollableContainerSelector?: string
    calculateTop?: (param: calculationParam) => number
    calculateLeft?: (param: calculationParam) => number
  }>(),
  {
    reposition: false,
    scrollableContainerSelector: null,
    calculateTop: () => 0,
    calculateLeft: () => 0
  }
)

const originalMultiselect = ref<Ref>(null)

const scrollableContainer = ref(null)

function repositionDropDown() {
  // reset
  if (originalMultiselect.value) {
    originalMultiselect.value.$refs.list.style.width = `120px`;
    originalMultiselect.value.$refs.list.style.height = `0px`;
      originalMultiselect.value.$refs.list.style.bottom = '0px';
  }
  nextTick(() => {
    const appElement = document.getElementById("app-body")
    const appElementBoundingRect: any = appElement.getBoundingClientRect() || {}

    const containerElement = appElement.children[0]
    const containerElementBoundingRect: any = containerElement?.getBoundingClientRect() || {}

    const element = originalMultiselect.value.$el.getBoundingClientRect();
    const width = originalMultiselect.value.$el.clientWidth;

    const param = {
      body: {
        height: document.body.offsetHeight
      },
      app: {
        top: containerElementBoundingRect?.top || 0,
        left: appElementBoundingRect?.left ? 225 : 0,
        scrollTop: appElement?.scrollTop || 0,
        height: appElementBoundingRect?.height || 0
      },
      element: {
        left: originalMultiselect.value.$el.offsetLeft,
        offsetTop: originalMultiselect.value.$el.offsetTop,
        top: element.top,
        width: originalMultiselect.value.$el.clientWidth,
        height: originalMultiselect.value.$refs.list.clientHeight
      },
      scrollableElement: {
        scrollLeft: scrollableContainer.value?.scrollLeft || 0,
        scrollTop: scrollableContainer.value?.scrollTop || 0,
      }
    }

    const left = props.calculateLeft(param)
    const top = props.calculateTop(param)
    if (originalMultiselect.value) {
      originalMultiselect.value.$refs.list.style.width = `${width}px`;
      originalMultiselect.value.$refs.list.style.minHeight = `100px`;
      originalMultiselect.value.$refs.list.style.height = `auto`;
      originalMultiselect.value.$refs.list.style.position = 'fixed';
      originalMultiselect.value.$refs.list.style.bottom = 'auto';
      originalMultiselect.value.$refs.list.style.top = `${top}px`;
      originalMultiselect.value.$refs.list.style.left = `${left}px`;
    }
    window.scrollTo(0,0)
  })
}

onMounted(() => {
  scrollableContainer.value =
    props.scrollableContainerSelector
      ? document.querySelector(props.scrollableContainerSelector) || window
      : window;

  scrollableContainer.value.addEventListener('scroll', repositionDropDown, { passive: true });
})

onUnmounted(() => {
  scrollableContainer.value.removeEventListener('scroll', repositionDropDown);
})
</script>