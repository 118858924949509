import type { DebouncedFunc, ThrottleSettings } from "lodash-es"
import { throttle } from "lodash-es"

export default function <T extends (...args: any[]) => any>(
  func: T,
  wait?: number,
  options?: ThrottleSettings & {
    key: (...args: Parameters<T>) => string | number
  }
) {
  const dict: Record<string | number, DebouncedFunc<T>> = {}

  return (...args: Parameters<T>) => {
    const key = options?.key(...args) || JSON.stringify(args)
    dict[key] ??= throttle(func, wait, options)
    return dict[key](...args)
  }
}
