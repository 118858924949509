import type { MaybeRef } from "@vueuse/core"
import type { MaybeReadonly } from "@/interfaces/_utils"
import { unref } from "vue"
import type { _User, User } from "@/interfaces"

export default function (maybeRefUser: MaybeRef<MaybeReadonly<_User | User>>) {
  const user = unref(maybeRefUser)
  if (!user) return undefined

  if (user.is_admin) return "admin"

  if (user.is_manufacturer)
    return user.is_moderator ? "manufacturer_moderator" : "manufacturer"

  if (user.is_customer) return user.is_moderator ? "customer_moderator" : "customer"
}
