<template>
  <div class="animated fadeIn wrapped-with-entpgmpdnq">
    <!-- STEPPER -->
    <CRow v-if="order?.status != 'CANCELLED'">
      <CCol>
        <Stepper
          v-model="stepper.current"
          class="mb-4 pb-3"
          :items="stepper.items"
          :is-done="stepper.current == 5"
        />
      </CCol>
    </CRow>
    <!-- END STEPPER -->

    <CCard>
      <CCardBody>
        <CRow class="flex-row">
          <CCol class="d-flex align-items-center">
            <h5 class="mb-0">Order No. {{ orderId }}</h5>
            <CBadge
              v-if="authStore.authenticatedUser?.is_manufacturer || authStore.authenticatedUser?.is_customer"
              class="cursor-pointer ms-2"
              color="dark"
              @click.stop="orderStore.exports(orderId, 'preview-pdf')"
            >
              PDF
            </CBadge>
            <CBadge class="cursor-pointer ms-2" color="dark">
              <ExcelDownloader
                type="excel"
                :filename="orderId + '.xlsx'"
                :data="batches"
                :fields="excelFields"
              >
                XLSX
              </ExcelDownloader>
            </CBadge>
            <CBadge v-if="authStore.authenticatedUser?.is_manufacturer" class="cursor-pointer ms-2" color="dark">
              <ExcelDownloader
                type="csv"
                :filename="orderId + '.csv'"
                :data="batches"
                :fields="excelFields"
              >
                CSV
              </ExcelDownloader>
            </CBadge>
            <CBadge
              v-if="authStore.authenticatedUser?.is_manufacturer"
              class="cursor-pointer ms-2"
              color="dark"
              @click.stop="orderStore.exportJson(orderId)"
            >
              JSON
            </CBadge>
            <CBadge
              v-if="authStore.authenticatedUser?.is_manufacturer"
              class="cursor-pointer ms-2"
              color="dark"
              @click.stop="orderStore.exportZip(orderId)"
            >
              ZIP
            </CBadge>
          </CCol>
          <CCol class="text-end">
            <CBadge :color="orderBadge?.color">{{ orderBadge?.text }}</CBadge>
          </CCol>
        </CRow>
        <CRow>
          <CCol v-if="authStore.authenticatedUser?.is_manufacturer">
            <CCard>
              <CCardHeader>
                <b>{{ $t("customer_details") }}</b>
              </CCardHeader>
              <CCardBody class="d-flex flex-column">
                <!-- Show customer name and billing address -->
                <template v-if="order?.customer">
                  <strong>{{ order?.customer?.name }}</strong>
                  <p
                    v-for="(address, i) in activeCustomerAddresses.filter(v => v.is_billing)" 
                    :key="i"
                  >
                    {{ address.line_1 }} {{ address.line_2 }}
                    <br />
                    {{ address.postcode }}, {{ address.city }}
                    <br />
                    {{ address.country }}
                  </p>
                </template>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol v-if="authStore.authenticatedUser?.is_customer && order?.manufacturer">
            <CCard>
              <CCardHeader>
                <b>{{ $t("manufacturer_details") }}</b>
              </CCardHeader>
              <CCardBody>
                <strong>{{ order?.manufacturer?.name }}</strong>
                <template v-for="(address, i) in order?.manufacturer?.addresses" :key="i">
                  <p v-if="address.is_billing && address.is_active" :key="i">
                    {{ address.line_1 }} {{ address.line_2 }}
                    <br />
                    {{ address.postcode }}, {{ address.city }}
                    <br />
                    {{ address.country }}
                  </p>
                </template>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol>
            <CCol>
              <CCard>
                <CCardHeader>
                  <b>{{ deliveryType ? $t(`${(deliveryType || "").toLowerCase()}_details`) : "" }}</b>
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <!-- Delivery type SHIPPING use customer details, PICKUP use manufacturer details  -->
                    <CCol class="d-flex flex-column">
                      <strong>{{ deliveryType === "SHIPPING" ? order?.customer?.name : order?.manufacturer?.name }}</strong>
                      <p
                        v-if="deliveryAddress"
                        class="mb-2"
                      >
                        {{ deliveryAddress.line_1 }} {{ deliveryAddress.line_2 }}
                        <br />
                        {{ deliveryAddress.postcode }}, {{ deliveryAddress.city }}
                        {{ deliveryAddress.province }}
                        <br />
                        {{ deliveryAddress.country }}
                      </p>

                      <a
                        v-if="allowChangeAddress || allowAddAddress"
                        class="text-primary cursor-pointer"
                        @click="showModals.pickAddress = true"
                      >
                        {{
                          $t(`${addressPickerOptionAddresses.length >= 1 || !allowAddAddress ? "change" : "add"}_address`)
                        }}
                      </a>
                    </CCol>
                  </CRow>
                  <CRow class="mt-4">
                    <CCol>
                      <CFormCheck
                        id="deliveryTypeShipping"
                        inline
                        type="radio"
                        :label="$t('delivery')"
                        :checked="deliveryType == 'SHIPPING'"
                        :disabled="deliveryTypeInputDisabled"
                        @click="deliveryType = 'SHIPPING'"
                      />
                      <CFormCheck
                        id="deliveryTypePickup"
                        inline
                        type="radio"
                        :label="$t('pickup')"
                        :checked="deliveryType == 'PICKUP'"
                        :disabled="deliveryTypeInputDisabled"
                        @click="deliveryType = 'PICKUP'"
                      />
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </CCol>
        </CRow>
        <hr class="bigHr mt-4" />
        <CRow>
          <CCol>
            <ButtonSwitch
              :value="treeView"
              :items="[
                { value: false, text: $t('list_view') },
                { value: true, text: $t('tree_view') }
              ]"
              @update:model-value="v => treeView = v"
            />
          </CCol>
        </CRow>
        <hr class="bigHr mt-3" />
        <CRow>
          <CCol>
            <ReviewBatchTable
              :order-id="orderId"
              :tree-view="treeView"
              @row-click="editRow"
            />
            <p v-if="batches.length > 0" class="small text-body-secondary">
              <i class="fa fa-check text-success" />
              {{ $t("valid_parts") }}
              <i class="fa fa-exclamation-triangle text-danger" />
              {{ $t("need_action") }}
            </p>
          </CCol>
        </CRow>

        <hr class="bigHr" />
        <CRow class="align-items-center">
          <CCol>
            <h6>{{ $t(deliveryType == 'SHIPPING' ? "delivery_date" : "pickup_date") }}</h6>
            <WrappedDatepicker
              v-model="shippingDate"
              name="shipping-date-input"
              class="dateInput"
              :format="date => dateFormat(date || shippingDate, 'dd MMM yyyy')"
              auto-apply
              :enable-time-picker="false"
              position="left"
              :disabled-week-days="[0,6]"
              :min-date="new Date()"
              no-today
            />
          </CCol>
          <CCol :sm="2">
            <CRow>
              <CCol>
                <span class="text-body-secondary">{{ $t("subtotal") }}</span>
              </CCol>
              <CCol class="d-flex justify-content-end">
                <span class="text-body-secondary">{{ order?.formatted_total_amount?.subtotal }}</span>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <span class="text-body-secondary">{{ $t("shipping_costs") }}</span>
              </CCol>
              <CCol class="d-flex justify-content-end">
                <span class="text-body-secondary">{{ order?.formatted_total_amount?.shipping }}</span>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <span class="text-body-secondary">{{ $t("tax_21") }}</span>
              </CCol>
              <CCol class="d-flex justify-content-end">
                <span class="text-body-secondary">{{ order?.formatted_total_amount?.tax }}</span>
              </CCol>
            </CRow>
            <hr class="bigHr" />
            <CRow>
              <CCol>
                <h3>{{ $t("total") }}</h3>
              </CCol>
              <CCol class="d-flex justify-content-end">
                <span class="priceTotal">{{ order?.formatted_total_amount?.total }}</span>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <!-- BUTTONS -->
    <div
      class="mt-4 pb-4 d-flex justify-content-between"
      :class="{ 'flex-row-reverse': !backButton?.enabled }"
    >
      <CButton v-if="backButton?.enabled" color="secondary" @click="backButton.onClick">
        <i class="fa fa-chevron-left" />
        {{ backButton.text }}
      </CButton>

      <!--
        The order may only be canceled by the person who
        has the right to set the order to the next status.
        So that's why I'm making the cancelation button's visibility
        dependent on whether the next button is enabled as well.
      -->
      <div v-if="nextButton?.enabled">
        <!-- Only allow cancel order on in_review, confirmed, quoted and ordered step -->
        <CButton v-if="['IN_REVIEW', 'CONFIRMED', 'QUOTED', 'ORDERED'].includes(order.status)" color="danger" @click="cancelOrder">
          {{ $t("cancel_order") }}
        </CButton>

        <CButton class="ms-3" color="success" @click="nextButton.onClick">
          {{ nextButton.text }}
          <i class="fa fa-chevron-right" />
        </CButton>
      </div>

      <!--
        We know the button would be to "uncancel",
        and we know that it is not enabled for the current user.
        That's why we show this text.
      -->
      <div v-else-if="nextButton?.text === $t('uncancel')">
        {{ $t("order_cancelled_by_other_party") }}
        <br />
        {{ $t("order_uncancel_text") }}
      </div>

      <!--
        If the nextButton is defined,
        but not enabled for this user,
        then we'll show a text that the user
        has to wait on the other party to move the order along
      -->
      <div v-else-if="nextButton?.enabled === false">
        <span v-if="nextButton.disabledText">
          {{ nextButton.disabledText }}
        </span>

        <span v-else-if="authStore.authenticatedUser?.is_customer">
          {{ $t("manufacturer_turn") }}
          <br />
          {{ $t("review_order_and_next") }}
        </span>

        <span v-else-if="authStore.authenticatedUser?.is_manufacturer">
          {{ $t("customer_turn") }}
          <br />
          {{ $t("review_order_and_next") }}
        </span>
      </div>

      <!--
        If there is no nextButton defined at all,
        then we must be in the completed stage.
      -->
      <div v-else-if="!nextButton">
        {{ $t("order_is_complete") }}
      </div>
    </div>
    <!-- END BUTTONS -->

    <!-- MODALS -->
    <!-- Please confirm if this edit batch modal is correct -->
    <EditBatchModal
      :show="showModals.editRow"
      :batch="editBatch"
      @close="() => (showModals.editRow = false)"
      @updated="updatedValues => (orderBatches[editedBatchIndex] = updatedValues)"
      @removed="() => orderBatches.splice(editedBatchIndex, 1)"
    />
    <!-- Pick shipping or pickup address (depends on the delivery type) -->
    <AddressPickerModal
      :selected="deliveryAddress"
      :show="showModals.pickAddress"
      :data="addressPickerOptionAddresses"
      @close="showModals.pickAddress = false"
      @open="showModals.pickAddress = true"
      @picked="v => orderStore.update({ id: order?.id, delivery_address: v.id })"
      @add="addAddress"
    />
    <!-- END MODALS -->
  </div>
</template>

<script lang="ts" setup>
import { ref, unref, watchEffect, watch, onMounted, computed } from "vue-demi"
import { useRouter, useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useMeta } from "vue-meta"
import sweetalert from "sweetalert2"
import { uniq } from "lodash-es"
import { format as dateFormat } from "date-fns"
import type {
  Address,
  Batch,
  OrderDeliveryTypeOptions,
  orderStatusOptions,
  Order
} from "@/interfaces"
import { batchStore, fileStore, orderStore, partStore, authStore, organizationStore, addressStore } from "@/store"
import { getId } from "@/interfaces"
import ReviewBatchTable from "./components/ReviewBatchTable.vue"
import ButtonSwitch from "./components/ButtonSwitch.vue"
import Stepper from "@/components/Stepper.vue"
import ExcelDownloader from "@/components/ExcelDownloader.vue"
import EditBatchModal from "@/components/modals/EditBatchModal.vue"
import AddressPickerModal from "@/components/modals/address/AddressPickerModal.vue"
import WrappedDatepicker from "@/components/WrappedDatepicker.vue"


type OrderStatusButton = {
  text: string
  disabledText?: string
  onClick: () => void
  enabled: boolean
}
type OrderStatusButtonOptions = Record<
  typeof orderStatusOptions[number],
  OrderStatusButton | null
>

const i18n = useI18n()
useMeta({ title: i18n.t("order_detail") })

const route = useRoute()
const router = useRouter()

const treeView = ref<boolean>(false)

const orderId = ref<number>(null)
watchEffect(() => {
  orderId.value = +route.params.id || null
  if (orderId.value) {
    orderStore.fetchByIds([orderId.value])
      .then(() => addressStore.fetchByOrganizationId(order.value?.customer?.id))
      .catch()
  }
})

const order = computed<Order>(() => orderStore.getOrderById(orderId.value))
const deliveryTypeInputDisabled = computed<boolean>(() =>
  ["ORDERED", "PRODUCED", "COMPLETED"].includes(order.value?.status)
)
watchEffect(() => {
  if (!order.value || !authStore.authenticatedUser) return

  if (
    !authStore.authenticatedUser.isCustomerOf(order.value) &&
    !authStore.authenticatedUser.isManufacturerOf(order.value) &&
    !authStore.authenticatedUser.isCreatorOf(order.value) && // access for creator, if inapropriate please remove
    !authStore.authenticatedUser.is_admin
  )
    return router.push("/403")

  if (order.value.status === "CREATED") orderStore.update({ id: order.value.id, status: "IN_REVIEW" })
})
const orderBadge = computed(() => {
  const badge = {
    color: "dark",
    text: i18n.t(order.value?.status.toLowerCase() || "created_status"),
  }
  switch (order.value?.status) {
    case "CREATED":
      badge.text = i18n.t("created_status")
      break
    case "IN_REVIEW":
    case "PRODUCED":
      badge.color = "secondary"
      break
    case "CONFIRMED":
    case "QUOTED":
      badge.color = "warning"
      break
    case "ORDERED":
    case "COMPLETED":
      badge.color = "success"
      break
    case "CANCELLED":
      badge.color = "danger"
      break
  }
  return badge
})
const stepper = computed(() => {
  const step = {
    CREATED: 0,
    IN_REVIEW: 1,
    QUOTED: 3,
    ORDERED: 4,
    COMPLETED: 5,
    PRODUCED: 4,
    CONFIRMED: 2,
  }

  return {
    items: [
      {
        title: i18n.t("created"),
        description: null,
        icon: "fa fa-plus",
      },
      {
        title: i18n.t("in_review"),
        description: null,
        icon: "fa fa-search",
      },
      {
        title: i18n.t("confirmed"),
        description: null,
        icon: "fa fa-clipboard-check",
      },
      {
        title: i18n.t("quoted"),
        description: null,
        icon: "fa fa-quote-right",
      },
      {
        title: i18n.t("ordered"),
        description: null,
        icon: "fa fa-shopping-cart",
      },
      {
        title: i18n.t("completed"),
        description: null,
        icon: "fa fa-check",
      },
    ],
    current: step[order.value?.status],
  }
})

const orderBatches = ref<Batch[]>([])
const batches = computed(() => batchStore.getBatchesByOrderId(orderId.value))
const isOrderValid = computed(() => batches.value.every(batch => batch.is_valid))
const fetchBatches = () => {
  if (!orderId.value) return
  const requests = [
    batchStore.fetchBatchesByOrderId(orderId.value),
    partStore.fetchPartsByOrderId(orderId.value)
  ]
  Promise.all(requests).then(([_, partsResponse]) => {
    const fileIds: number[] = batches.value.map(batch => getId(batch.part?.source))
    fileStore.fetchByIds(uniq(fileIds))
    const partIds: number[] = partsResponse.map(part => part.id)
    partStore.get3DImagesByIds(partIds)
  }).catch()
}
onMounted(() => {
  fetchBatches()
})

const showModals = ref({
  editRow: false,
  pickAddress: false
})
const editBatch = ref(null)
const editedBatchIndex = ref(null)
watch(
  () => showModals.value.editRow,
  v => {
    if (!v) {
      editBatch.value = null
      editedBatchIndex.value = null
    }
  }
)
const editRow = (data) => {
  if (authStore.authenticatedUser?.is_manufacturer) {
    showModals.value.editRow = true
    editBatch.value = data.row
    editedBatchIndex.value = orderBatches.value.findIndex(batch => batch.id === data.row.id)
  }
}

const backButton = computed(() => {
  const backMap: OrderStatusButtonOptions = {
    CANCELLED: null,
    COMPLETED: null,
    PRODUCED: null,
    ORDERED: {
      text: i18n.t("cancel_order_go_back_to_quote"),
      onClick: () => orderStore.update({ id: orderId.value, status: "QUOTED" }),
      enabled: authStore.authenticatedUser?.isManufacturerOf(order.value),
    },
    QUOTED: {
      text: i18n.t("back_to_review"),
      onClick: () => orderStore.update({ id: orderId.value, status: "IN_REVIEW" }),
      enabled: true,
    },
    CONFIRMED: {
      text: i18n.t("back_to_review"),
      onClick: () => orderStore.update({ id: orderId.value, status: "IN_REVIEW" }),
      enabled: true,
    },
    IN_REVIEW: {
      text: i18n.t("back_to_edit"),
      onClick: () => router.push(`/orders/${orderId.value}/edit`),
      enabled: true,
    },
    CREATED: null,
  }

  return backMap[order.value?.status]
})
const keyOfOrderLastStatus = (orderId: any) => `Last status of cancelled order ${unref(orderId)}`
const nextButton = computed(() => {
  // TODO: remove this as soon as the backend can store this information.
  const lastStatus = localStorage.getItem(
    keyOfOrderLastStatus(orderId)
  ) as typeof order.value["status"]

  const nextMap: OrderStatusButtonOptions = {
    CREATED: null,
    IN_REVIEW: {
      text: i18n.t("confirm_inquiry"),
      disabledText: i18n.t("order_invalid"),
      onClick: () => orderStore.update({ id: orderId.value, status: "CONFIRMED" }),
      enabled: isOrderValid.value,
    },
    CONFIRMED: {
      text: i18n.t("quote"),
      disabledText: i18n.t("wait_for_quotation"),
      onClick: () => orderStore.update({ id: orderId.value, status: "QUOTED" }),
      enabled: authStore.authenticatedUser?.isManufacturerOf(order.value),
    },
    QUOTED: {
      text: i18n.t("order"),
      onClick: () => orderStore.update({ id: orderId.value, status: "ORDERED" }),
      enabled: true,
    },
    ORDERED: {
      text: i18n.t("to_produced"),
      disabledText: i18n.t("wait_for_order_to_be_produced"),
      onClick: () => orderStore.update({ id: orderId.value, status: "PRODUCED" }),
      enabled: authStore.authenticatedUser?.isManufacturerOf(order.value),
    },
    PRODUCED: {
      text: i18n.t("complete"),
      disabledText: order.value?.delivery_type ? i18n.t(`order_is_produced_${order.value.delivery_type}`) : "",
      onClick: () => orderStore.update({ id: orderId.value, status: "COMPLETED" }),
      enabled: authStore.authenticatedUser?.isManufacturerOf(order.value),
    },
    CANCELLED: {
      text: i18n.t("uncancel"),
      onClick: async () => {
        await orderStore.update({
          id: orderId.value,
          status: lastStatus || "IN_REVIEW",
        })
        localStorage.removeItem(keyOfOrderLastStatus(orderId))
      },
      // The uncancel button should only be available to the user who canceled the order.
      // Which is by definition the user who has the last status saved in their localStorage.
      enabled: !!lastStatus,
    },
    COMPLETED: null,
  }

  return nextMap[order.value?.status]
})

const deliveryType = computed<OrderDeliveryTypeOptions>({
  get: () => order.value?.delivery_type,
  set: (v) => {
    // change delivery address upon delivery type change
    // because if delivery type is SHIPPING, delivery address is customer's address
    // if delivery type is PICKUP, delivery address is manufacturer's address
    const delivery_address = v === "SHIPPING"
      ? order.value?.customer?.addresses?.find(address => address.is_active && address.is_shipping)
      : order.value?.manufacturer?.addresses?.find(address => address.is_active && address.is_shipping)
    orderStore.update({ id: orderId.value, delivery_type: v, delivery_address })
  },
})
const shippingDate = computed<Date>({
  get: () => new Date(order.value?.delivery_date),
  set: (v) => orderStore.update({ id: orderId.value, delivery_date: v }),
})

const isOrderAddressActive = computed(() => {
  if (!order.value?.delivery_address) return false
  return addressPickerOptionAddresses.value.some(address => address.id === getId(order.value?.delivery_address))
})

const allowAddAddress = computed<boolean>(() => 
  authStore.authenticatedUser?.is_moderator
  && (
    authStore.authenticatedUser?.is_manufacturer
    || (authStore.authenticatedUser?.is_customer && order.value?.delivery_type === "SHIPPING")
  )
)

// Allow change address if current order address is not active (deleted)
// Whoever can add address, can change address
// For manufacturer, allow change address if address > 1
// For customer, allow change address if address > 1 and delivery type is SHIPPING
const allowChangeAddress = computed<boolean>(() =>
  !isOrderAddressActive.value
  || allowAddAddress.value
  || (
    addressPickerOptionAddresses.value.length > 1
     && (
      authStore.authenticatedUser?.is_manufacturer
      || (order.value?.delivery_type === "SHIPPING" && authStore.authenticatedUser?.is_customer)
    )
  )
)

const activeCustomerAddresses = computed<Address[]>(() => (order.value?.customer?.addresses || []).filter(address => address.is_active))
const activeManufacturerAddresses = computed<Address[]>(() => (order.value?.manufacturer?.addresses || []).filter(address => address.is_active))
const addAddress = (v: any) => {
  const { is_shipping, is_billing } = v
  if (Object.prototype.hasOwnProperty.call(v, "is_shipping")) delete v.is_shipping
  if (Object.prototype.hasOwnProperty.call(v, "is_billing")) delete v.is_billing
  organizationStore.addAddress(
    getId(order.value?.delivery_type === "SHIPPING" ? order.value?.customer : order.value?.manufacturer),
    v
  ).then(newAddress => {
    if (!newAddress.is_active) addressStore.update({ id: newAddress.id, is_active: true, is_shipping, is_billing })
    orderStore.update({ id: orderId.value, delivery_address: newAddress })
  }).catch()
}

// If delivery type is SHIPPING, pick address from customer's addresses
// If delivery type is PICKUP, pick from manufacturer's addresses
const addressPickerOptionAddresses = computed<Address[]>(() => (order.value?.delivery_type === "SHIPPING" ? activeCustomerAddresses.value : activeManufacturerAddresses.value) || [])

// To make sure delivery address of order matches the customer or manufacturer addresses
const deliveryAddress = computed<Address>(() => {
  if (!order.value?.delivery_address) return null
  const allAddresses = (order.value.delivery_type === "SHIPPING" ? order.value?.customer?.addresses : order.value?.manufacturer?.addresses) || []
  return allAddresses.find(address => address.id === getId(order.value.delivery_address))
})

const excelFields = computed(() => ({
  id: {
    key: "id",
  },
  filename: {
    key: "part",
    formatter: v => v?.filename,
  },
  name: {
    key: "part",
    formatter: v => v?.name,
  },
  reference: {
    key: "part",
    formatter: v => v?.reference,
  },
  stock: {
    key: "stock",
    formatter: v => v?.name,
  },
  quantity: {
    key: "quantity",
  },
  unit_amount: {
    key: "unit_amount",
    formatter: v => (v ? v.toFixed(2) : 0.0),
  },
  total_amount: {
    key: "total_amount",
    formatter: v => (v ? v.toFixed(2) : 0.0),
  },
  is_valid: {
    key: "is_valid",
  },
  created: {
    key: "created",
    formatter: v => (v ? dateFormat(new Date(v), "MMMM d yyyy, hh:mm:ss a") : ""),
  },
  updated: {
    key: "updated",
    formatter: v => (v ? dateFormat(new Date(v), "MMMM d yyyy, hh:mm:ss a") : ""),
  },
}))

const cancelOrder = () => {
  sweetalert
    .fire({
      title: i18n.t("cancel_order"),
      text: i18n.t("cancel_order_text"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: i18n.t("yes_please"),
      cancelButtonText: i18n.t("no_thanks"),
    })
    .then(async result => {
      if (!result.isConfirmed) return

      const lastStatus = order.value?.status
      await orderStore.update({ id: orderId.value, status: "CANCELLED" })

      // TODO: remove this as soon as the backend can store this information.
      localStorage.setItem(keyOfOrderLastStatus(orderId), lastStatus)
    })
    .catch()
}
</script>

<style lang="scss">
.wrapped-with-entpgmpdnq {
  .card-body h5 {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 40px;
  }
  .card-body h6 {
    font-size: 1em;
    font-weight: bold;
    line-height: 10px;
  }
  .blockquote {
    font-size: 0.8em;
    border-left: 5px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }
  .blockquote p {
    line-height: 0.4em;
  }
  .selectedCard {
    background-color: rgb(243, 243, 243);
  }
  .cardHover:hover {
    background-color: rgb(221, 221, 221);
    cursor: pointer;
  }
  .cardRadioButton {
    float: right;
    margin-top: 20px;
  }
  .successBlock {
    margin: auto;
    width: 100%;
    padding: 10px;
    text-align: center;
  }
  .successBlock h3 {
    padding-top: 20px;
    color: rgb(102, 101, 101);
  }
  .successBlock p {
    padding-top: 10px;
    color: rgb(56, 56, 56);
  }
  .progressbar {
    counter-reset: step;
  }
  .progressbar li {
    list-style-type: none;
    width: 20%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
    margin-bottom: 15px;
  }
  .progressbar li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
  }
  .progressbar li:after {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    background-color: #7d7d7d;
    top: 15px;
    left: -50%;
    z-index: -1;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li.active {
    color: green;
  }
  .progressbar li.active:before {
    border-color: #55b776;
  }
  .progressbar li.active + li:after {
    background-color: #55b776;
  }

  .img-container svg {
    max-width: 100%;
    max-height: 100%;
  }

  .dateInput {
    width: 250px !important;
    max-width: 250px !important;
  }
}
</style>
