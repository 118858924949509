<template>
  <div class="excel-downloader">
    <div v-if="props.type == 'excel'" @click="exportXLSX(exportableData)">
      <slot />
    </div>
    <DownloadCSV v-if="props.type == 'csv'" :data="exportableData" :name="filename">
      <slot />
    </DownloadCSV>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"
import DownloadCSV from "vue-json-csv"
import * as XLSX from "xlsx"

const types = ["csv", "excel"] as const

const props = withDefaults(
  defineProps<{
    type?: typeof types[number]
    data: any[]
    filename: string
    fields: any // this includes any[]
  }>(),
  {
    type: "excel"
  }
)

const exportableData = computed(() => {
  if (!props.fields) return props.data

  const formattedDatas = []
  for (let i = 0; i < props.data.length; i++) {
    const d = props.data[i]
    const formatted = {}
    for (const property in props.fields) {
      const field = props.fields[property]
      if (!Object.prototype.hasOwnProperty.call(d, field.key)) continue

      formatted[property] = field.formatter ? field.formatter(d[field.key]) : d[field.key]
    }
    formattedDatas.push(formatted)
  }

  return formattedDatas
})

const exportXLSX = (data: any) => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data, {
    WTF: true,
    cellStyles: true,
  })
  XLSX.utils.book_append_sheet(wb, ws, props.filename)
  XLSX.writeFile(wb, props.filename)
}
</script>
