<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" size="lg" @close="isVisible = false">
      <CModalHeader>
        <CModalTitle>{{ props.profile?.name }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div class="text-center">
          <p v-html="image" />
        </div>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" @click="isVisible = false">
          {{ $t("close") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { watchEffect, ref, computed } from "vue-demi"
import type { Profile } from "@/interfaces"
import { profileStore } from "@/store"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const props = defineProps<{ modelValue: boolean, profile?: Profile }>()

const emit = defineEmits(["update:modelValue"])

const image = ref(null)
const isVisible = computed<boolean>({
  get: () => props.modelValue,
  set: (v) => emit("update:modelValue", v),
})
watchEffect(() => {
  if (props.profile && isVisible.value) {
    image.value = null
    profileStore
      .getImage(props.profile.id)
      .then((data) => image.value = data)
      .catch()
  }
})
</script>
