import { defineStore } from "pinia"
import { cloneDeep, forEach } from "lodash-es"
import type { CreateSheet, Sheet } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import type { OptimisticAddOptionInterface, OptimisticUpdateOptionInterface } from "@/interfaces"
import { manufacturerStore, materialStore, userStore } from "."
import { getId, optimisticAdd, optimisticUpdate, paginatedFetch, optimisticDelete, addOrUpdate, bulkAddOrUpdate } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"

const cacheKey = "sheets"

const mapData = (sheet: Sheet) => ({
  ...sheet,
  material: materialStore.all.find(material => material.id === getId(sheet.material)),
})

export default defineStore("sheet", {
  state: () => ({
    all: [],
    manufacturerSheets: [],
  }),
  getters: {
    url: () => `/v1/sheets`,
    mappedData: state => state.all.map(mapData),
    mappedManufacturerSheets: state => state.manufacturerSheets.map(mapData),
  },
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Sheet[]
      this.manufacturerSheets = (getCache(`${cacheKey}-manufacturer`)?.data ||
        []) as Sheet[]
    },
    modifyData(data = []) {
      const isSameManufacturer = userStore.mappedCurrent.availableManufacturers?.includes(
        manufacturerStore.manufacturerNickname
      )
      const objects = [cloneDeep(this.all)]

      if (isSameManufacturer) objects.push(cloneDeep(this.manufacturerSheets))
      const updatedObjects = bulkAddOrUpdate(objects, data)

      this.all = updatedObjects[0]
      if (isSameManufacturer) this.manufacturerSheets = updatedObjects[1]
    },
    async fetchAll({ persist = false, prefetching = false } = {}) {
      await paginatedFetch({
        url: "/v1/sheets",
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchByManufacturerId(
      manufacturerId: string,
      { persist = false, prefetching = false } = {}
    ) {
      await paginatedFetch({
        url: `/v1/manufacturers/${manufacturerId}/sheets`,
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    async fetchManufacturerSheets({ persist = false, prefetching = false } = {}) {
      const manufacturerId = manufacturerStore.current?.id
      if (!manufacturerId) return console.error("No manufacturer detected")
      await paginatedFetch({
        url: `/v1/manufacturers/${manufacturerId}/sheets`,
        persist,
        prefetching,
        callback: data =>
          forEach(data || [], v => addOrUpdate(this.manufacturerSheets, v, ["id"])),
        runCallbackCondition: data =>
          this.manufacturerSheets.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(`${cacheKey}-manufacturer`, this.manufacturerSheets)
    },
    add(values: CreateSheet, options: OptimisticAddOptionInterface = {}) {
      values.material = getId(values.material)
      const objects = [this.all]
      if (
        userStore.mappedCurrent.availableManufacturers?.includes(
          manufacturerStore.manufacturerNickname
        )
      )
        objects.push(this.manufacturerSheets)
      return optimisticAdd({
        allObjects: objects,
        values,
        url: "/v1/sheets",
        onSuccess: () => {
          if (!options?.withoutOptimistic) {
            setCache(cacheKey, this.all)
            setCache(`${cacheKey}-manufacturer`, this.manufacturerSheets)
          }
        },
        ...options,
      })
    },
    update(values: Sheet, options: OptimisticUpdateOptionInterface = {}) {
      values.material = getId(values.material)
      const objects = [this.all]
      if (
        userStore.mappedCurrent.availableManufacturers?.includes(
          manufacturerStore.manufacturerNickname
        )
      )
        objects.push(this.manufacturerSheets)
      return optimisticUpdate({
        allObjects: objects,
        values,
        url: `/v1/sheets/${values.id}`,
        onSuccess: () => {
          if (!options?.withoutOptimistic) {
            setCache(cacheKey, this.all)
            setCache(`${cacheKey}-manufacturer`, this.manufacturerSheets)
          }
        },
        ...options,
      })
    },
    remove(id: number) {
      const objects = [this.all]
      if (
        userStore.mappedCurrent.availableManufacturers?.includes(
          manufacturerStore.manufacturerNickname
        )
      )
        objects.push(this.manufacturerSheets)
      return optimisticDelete({
        allObjects: objects,
        url: `/v1/sheets/${id}`,
        id,
        onSuccess: () => {
          setCache(cacheKey, this.all)
          setCache(`${cacheKey}-manufacturer`, this.manufacturerSheets)
        },
      })
    },
  },
})
