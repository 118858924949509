import type { QueryKey } from "@/interfaces"
import { isPlainObject } from "lodash-es"
import { unref } from "vue"
import {
  type MemCacheMessage,
  type MemCache,
  type MemCacheValue,
  type Jsonables,
  type MemCachePatchMessage,
  fetchMemCache,
  putCacheEntry,
  clearCache as clearIndexedDB,
} from "@/idb"
import { broadcastChannelName, queryCacheTime as cacheTime, accessTokenExpiredMessage } from "@/constants"
import { hash as hashData } from "@/hasher"
import { hashKey, ensureQueryKeyArray } from "@/interfaces"
import { sendCommand } from "@/libraries/helpers"

export type { MemCache }

// This is my in-memory cache,
// which I'll be using most of the time.
// Also, if you want to change these 3 lines,
// then also change the same lines in src/sw.ts
let memCache = {}
let memCacheHash = ""

const publicChannel = new BroadcastChannel(broadcastChannelName)
const privateChannel = getPrivateChannel()

publicChannel.onmessage = handleMessage
privateChannel.onmessage = handleMessage

type MessageDataType = MemCacheMessage | MemCachePatchMessage | typeof accessTokenExpiredMessage | true

async function handleMessage({ data }: { data: MessageDataType }) {
  if (data === true) return

  if (!data || typeof data !== "object") return console.error("Data was something unexpected", data)

  if (!("memCache" in data) && !("key" in data))
    return console.error("I only expect to receive either a full new memCache, or one updated memCache entry.", {
      data,
    })

  if ("key" in data) {
    if (memCache[data.key]?.hash === data.hash) return
    const { key, ...rest } = data
    memCache[key] = rest
    memCacheHash = await hashData(memCache)
    return
  }

  if (memCacheHash === data.memCacheHash) return
  memCacheHash = data.memCacheHash
  memCache = data.memCache
}

function getPrivateChannel() {
  if (navigator.serviceWorker?.controller) return navigator.serviceWorker

  const { port1, port2 } = new MessageChannel()
  import("@/idb").then(idb => idb.setupMessagePort(port2)).catch()
  return port1
}

export async function fillMemCache() {
  const response = await fetchMemCache()
  handleMessage({ data: response })
}

export async function clearCache() {
  memCache = {}
  memCacheHash = ""
  await sendCommand({ cmd: "clear" })
  clearIndexedDB()
}

const deleteFns = (vv: any /* this includes any[] */) => {
  const v = unref(vv)

  if (Array.isArray(v)) return v.map(deleteFns).filter(v => v !== void 0)

  if (typeof v === "function") return void 0

  if (v === null) return v
  if (!isPlainObject(v)) return v

  return Object.keys(v).reduce((newObj, key) => {
    newObj[key] = deleteFns(v[key])

    return newObj
  }, {})
}

export async function setCache(key: QueryKey, data: Jsonables) {
  const hashedKey = hashKey(key)

  if (data === undefined) {
    if (memCache[hashedKey] === undefined) return
    delete memCache[hashedKey]
    memCacheHash = await hashData(memCache)
    return await sendCommand({ cmd: "delete", key: hashedKey })
  }

  const cloneableData = deleteFns(data)

  const hash = await hashData(cloneableData)
  const { hash: oldHash } = memCache[hashedKey] || {}
  if (hash === oldHash) return

  const cache = { data: cloneableData, updatedAt: Date.now(), hash }
  memCache[hashedKey] = cache
  memCacheHash = await hashData(memCache)

  putCacheEntry({ key: hashedKey, ...cache })
}

export function getCache<T extends Jsonables>(key: QueryKey): MemCacheValue<T> {
  const notFound = { data: undefined, updatedAt: 0, hash: "" }
  const arrayKey = ensureQueryKeyArray(key)
  const hashedKey = hashKey(key)
  // As you can see, we're not asking the data from indexedDB,
  // but just from the in-memory cache variable. This makes sure
  // that this function can be synchronous and fast.
  let value = memCache[hashedKey] as MemCacheValue<T>

  if (!value) {
    const maybeId = +arrayKey.at(-1) || undefined
    const listKey = maybeId && arrayKey.slice(0, -1)
    value = listKey && (memCache[hashKey(listKey)] as MemCacheValue<T>)
    const { data: list, updatedAt, hash } = value || {}

    if (!maybeId || !value || !Array.isArray(list)) return notFound
    const item = list.find(item => (item as any).id === maybeId)
    if (!item) return notFound
    value = { data: item as T, updatedAt, hash }
  }

  const { data, updatedAt, hash } = value

  // If the cached result is older than the default cache time,
  // we'll consider it too old, and so we'll just delete it and return undefined.
  const result = Date.now() - updatedAt > cacheTime ? undefined : data

  if (result === undefined) setCache(hashedKey, undefined)

  return { data: result, updatedAt, hash }
}
