import axios from "axios"
import { forEach, pickBy, remove } from "lodash-es"
import type { OptimisticAddOptionInterface } from "@/interfaces"
import { generateTemporaryId } from ".."
import { requestStore } from "@/store"

interface Param extends OptimisticAddOptionInterface {
  allObjects: any[][] // this is pointer by reference
  values: Record<string, any>
  url: string
  tempId?: string
  addToRequestStore?: boolean
  optimisticAddCallback?: () => void
  onSuccess?: (data?: any) => void
  onError?: () => void
}
export default function ({
  allObjects, values, url, tempId = generateTemporaryId(),
  addToRequestStore = false,
  optimisticAddCallback = () => {}, onSuccess = () => {},
  onError = () => {}, withoutOptimistic = false
}: Param) {
  return new Promise((resolve, reject) => {
    if (addToRequestStore)
      requestStore.modifyRunningFetches(
        {
          url,
          persist: false,
          prefetching: false,
          abortController: new AbortController(),
        },
        true
      )

    values = pickBy(values, (v, k) => k !== "id")
    const indexes = []
    if (!withoutOptimistic) {
      forEach(allObjects, objects => {
        objects.push({ ...values, id: tempId })
        indexes.push(objects.length - 1)
      })
    }
    optimisticAddCallback()
    axios
      .post(url, values)
      .then(({ data }) => {
        if (!withoutOptimistic) {
          forEach(allObjects, (objects, i) => {
            objects[indexes[i]] = data
          })
        }

        onSuccess(data)
        resolve(data)
      })
      .catch(err => {
        const isCancelled = err?.message === "canceled"
        if (!withoutOptimistic) {
          forEach(allObjects, objects => {
            remove(objects, { id: tempId })
          })
        }
        onError()
        if (!isCancelled) reject(err)
        else resolve(null)
      })
      .finally(() => {
        requestStore.removeRunningFetch(url)
      })
  })
}