import axios from "axios"
import { cloneDeep, forEach, pickBy } from "lodash-es"
import type { OptimisticUpdateInterface } from "@/interfaces"
import { requestStore } from "@/store"

export default function ({
  allObjects, values, url,
  optimisticUpdateCallback = () => {},
  onSuccess = () => {},
  onError = () => {},
  onlyOptimistic = false,
  addToRequestStore = false,
  resetOnCancelled = true,
  withoutOptimistic = false
}: OptimisticUpdateInterface) {
  return new Promise((resolve, reject) => {
    if (addToRequestStore)
      requestStore.modifyRunningFetches(
        {
          url,
          persist: false,
          prefetching: false,
          abortController: new AbortController(),
        },
        true 
      )
    const request = requestStore.runningFetches.find(r => r.url === url)

    const { id } = values
    values = pickBy(values, (v, k) => k !== "id")
    const indexes = []
    const currentValues = []
    if (!withoutOptimistic) {
      forEach(allObjects, objects => {
        const index = objects.findIndex(o => o.id === id)
        indexes.push(index)
        const currentValue = cloneDeep(objects[index])
        currentValues.push(currentValue)
        objects[index] = { ...currentValue, ...values }
      })

      if (onlyOptimistic) {
        resolve(currentValues[0])
        return
      }
    }
    optimisticUpdateCallback()
    
    axios
      .patch(url, values, { signal: request?.abortController?.signal })
      .then(({ data }) => {
        if (!withoutOptimistic) {
          forEach(allObjects, (objects, i) => {
            if (indexes[i] !== -1) objects[indexes[i]] = { ...objects[indexes[i]], ...data }
            else objects.push(data)
          })
        }
        onSuccess(data)
        requestStore.removeRunningFetch(url)
        resolve(data)
      })
      .catch(err => {
        const isCancelled = err?.message === "canceled"
        if (!isCancelled || (resetOnCancelled && isCancelled)) {
          if (!withoutOptimistic) {
            forEach(allObjects, (objects, i) => {
              if (indexes[i] !== -1) objects[indexes[i]] = currentValues[i]
            })
          }
        }
        onError()
        if (!isCancelled) reject(err)
        else resolve(null)
      })
  })
}