export function hideBatchId(id: number, batches: any[], hideChildOfBatchIds: number[] = []) {
  const batch = batches.find(b => b.id === id)
  if (batch.childrenIds.length > 0) {
    for (let i = 0; i < batch.childrenIds.length; i++) {
      const childId = batch.childrenIds[i];
      hideBatchId(childId, batches, hideChildOfBatchIds)
    }
  }
  hideChildOfBatchIds.push(id)
  return hideChildOfBatchIds
}

export function handleBatchTree(batches: any[], hideChildOfBatchIds: number[]) {
  for (let i = 0; i < batches.length; i++) {
    const batch = batches[i]
    const rowElement = document.getElementById(batch.id)
    if (!rowElement) continue
    rowElement.classList.remove("d-none")
    if (batch.parent && hideChildOfBatchIds.includes(batch.parent.id)) {
      rowElement.classList.add("d-none")
    }
  }
}

export function calculateTotalVisibleChildren(batches: any[], totalVisibleChildren: any) {
  const hasChildrenBatches = batches.filter(b => b.allChildrenIds.length > 0)
  for (let i = 0; i < hasChildrenBatches.length; i++) {
    const batch = hasChildrenBatches[i];
    const queryString = batch.allChildrenIds.map(id => `tr[id='${id}']:not(.d-none)`).join(",")
    totalVisibleChildren[batch.id] = (document.querySelectorAll(queryString) || []).length
  }
  return totalVisibleChildren
}