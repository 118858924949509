export const BATCH_TABLE_COLUMNS = [
  "index",
  "filename",
  "name",
  "preview",
  "quantity",
  "material",
  "thickness",
  "process",
  "operations",
  "reference",
  "certificates",
  "boolean__is_valid",
  "price",
  "total",
  "actions",
]