<template>
  <div class="btn_row">
    <Popover
      :text="$t('import_and_merge_data')"
    >
      <CButton
        class="me-2"
        width="100%"
        color="secondary"
        variant="outline"
        :disabled="props.disableMerger"
        @click="open"
      >
        <i class="fa fa-clone fa-lg" />
      </CButton>
    </Popover>

    <Popover
      :text="$t('export_data')"
    >
      <CDropdown color="secondary">
        <CDropdownToggle
          class="no-caret"
          color="secondary"
          variant="outline"
          :disabled="props.show"
        >
          <i class="fa fa-download fa-lg" />
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem @click="exportXLSX(exportableData) && close()">
            {{ $t("merge-component-xlsx") }}
          </CDropdownItem>

          <DownloadCSV
            :data="exportableData"
            class="dropdown-item"
            :name="`${props.title}.csv`"
            @click="close"
          >
            {{ $t("merge-component-csv") }}
          </DownloadCSV>
        </CDropdownMenu>
      </CDropdown>
    </Popover>

    <CModal
      size="xl"
      :visible="isVisible"
      backdrop="static"
      @close="close"
    >
      <CModalHeader :close-button="!loading">
        <CModalTitle>{{ $t("import_and_merge_data") }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <Stepper
          v-model="step"
          class="mb-4 pb-3"
          :items="stepperItems"
          :is-done="step === STEPS.FINISHED"
          :is-finished="checkFinishedSteps"
          :is-current="item => item.key === step"
          style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
        />

        <template v-if="loading">
          <CProgress v-if="step === STEPS.PROCESSING" class="align-middle me-2 mb-3">
            <CProgressBar
              color="danger"
              variant="striped"
              animated
              :value="settledPercentages.failed"
            />
            <CProgressBar
              color="success"
              variant="striped"
              animated
              :value="settledPercentages.success"
            />
          </CProgress>
          <CSpinner v-else class="align-middle me-2 mb-3" />
          <strong v-if="!totalRequests" class="m-1">
            {{ $t("merge-component-loading") }}
          </strong>
          <strong v-else class="m-1">
            {{ Object.keys(failedRows).length + successfullyChanged }} /
            {{ totalRequests }}
          </strong>
        </template>
        <strong v-if="hint" class="mb-4 d-flex" v-html="hint" />

        <template v-if="step === STEPS.UPLOAD">
          <p v-html="$t('merger_component_explanation_text')" />
          <p v-html="$t('merger_component_warning_text', { idColumn: props.idColumn })" />
          <Dropzone
            :options="dropzoneOptions"
            @addedfile="uploadFile"
          />
        </template>

        <template v-else-if="step === STEPS.REVIEW">
          <div
            class="table-container"
          >
            <MergerTable
              :current-data="currentData"
              :uploaded-data="uploadedData"
              :columns="columns"
              :identifier="props.idColumn"
              :schema="props.schema"
              :allow-new-row="props.allowNewRow"
              :custom-validation="props.customValidation"
              @initialized="onTableInitialized"
            />
          </div>
        </template>

        <template v-else-if="step === STEPS.FINISHED">
          <p
            v-if="!Object.keys(failedRows).length"
            v-html="$t('merger_component_success_text')"
          />
          <div v-else>
            <p
              v-html="
                $t('merger_component_totals_text', {
                  totalSuccess: Object.keys(succeededRows).length,
                  totalFailed: Object.keys(failedRows).length,
                })
              "
            />
            <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell>
                    {{ $t("download_report_overview") }}
                  </CTableHeaderCell>
                  <CTableHeaderCell />
                  <CTableHeaderCell />
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableDataCell>CSV</CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="danger"
                      class="finalBtn"
                      @click="exportCSV(formatToExport(Object.values(failedRows)))"
                    >
                      {{ $t("failed") }}
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="success"
                      class="finalBtn"
                      @click="exportCSV(Object.values(succeededRows))"
                    >
                      {{ $t("succeeded") }}
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell>XLSX</CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="danger"
                      class="finalBtn"
                      @click="exportXLSX(formatToExport(Object.values(failedRows)))"
                    >
                      {{ $t("failed") }}
                    </CButton>
                  </CTableDataCell>
                  <CTableDataCell>
                    <CButton
                      color="success"
                      class="finalBtn"
                      @click="exportXLSX(Object.values(succeededRows))"
                    >
                      {{ $t("succeeded") }}
                    </CButton>
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </div>
        </template>
      </CModalBody>
      <CModalFooter class="d-flex justify-content-between">
        <CButton
          v-show="prevButton.show"
          :color="prevButton.color"
          :disabled="prevButton.disabled"
          @click="prevButton.onClick"
        >
          <i :class="prevButton.iconClass" />
          {{ prevButton.text }}
        </CButton>
        <span v-if="step === STEPS.REVIEW && hasErrors()">
          <CIcon icon="fa-left" />
          &nbsp;{{ $t("there_are_errors") }}&nbsp;
          <CIcon icon="fa-triangle-exclamation" />
        </span>
        <span style="flex-grow: 1" />
        <span v-if="step === STEPS.REVIEW">{{ totalData }} {{ $t("rows") }}</span>
        <CButton
          v-show="nextButton.show"
          :color="nextButton.color"
          :variant="nextButton.variant"
          :disabled="nextButton.disabled"
          @click="nextButton.onClick"
        >
          {{ nextButton.text }}
          <i v-if="nextButton.iconClass" :class="nextButton.iconClass" />
        </CButton>
      </CModalFooter>
    </CModal>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue-demi"
import { useI18n } from "vue-i18n"
import { omit, cloneDeep } from "lodash-es"
import DownloadCSV from "vue-json-csv"
import * as XLSX from "xlsx"
import Papa from "papaparse"
import Dropzone from "../Dropzone.vue"
import Stepper from "../Stepper.vue"
import { sleep } from "@/libraries/helpers"
import Popover from "@/components/Popover.vue"
import {
  type MergerSchema, mergerDefaultIdColumn, type MergerRawUploadedRows,
  type MergerParsedUploadedRows
} from "@/interfaces"
import MergerTable from "@/components/table/MergerTable.vue"

const i18n = useI18n()
const emit = defineEmits(["close", "open", "done"])

const props = withDefaults(
  defineProps<{
    title?: string
    idColumn?: string
    setRow: (id: number, data: any) => Promise<any>
    show?: boolean
    schema: MergerSchema
    data: any[]
    dataProcessor?: (data: any[]) => any[]
    valueProcessor?: (values: any[]) => any[]
    customValidation?: (value: any, errors: any) => { errors: any; values: any }
    allowNewRow?: boolean
    requestBatch?: number,
    disableMerger?: boolean
  }>(),
  {
    title: "data",
    idColumn: mergerDefaultIdColumn,
    show: false,
    dataProcessor: (data: any[]) => data,
    valueProcessor: (values: any[]) => values,
    customValidation: (value: any, errors: any) => ({ errors, values: value }),
    allowNewRow: true,
    requestBatch: 4,
    disableMerger: false
  }
)

const dropzoneOptions = {
  url: window.location.href,
  autoProcessQueue: false,
  acceptedFiles: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".csv",
  ].join(","),
  maxFiles: 1, // only allow 1 file
  maxFilesize: 10, // MB
  dictDefaultMessage: i18n.t("upload_modal_body"),
}

/** Handling Stepper */
const STEPS = {
  UPLOAD: "UPLOAD",
  REVIEW: "REVIEW",
  PROCESSING: "PROCESSING",
  FINISHED: "FINISHED",
}
const step = ref(STEPS.UPLOAD)

const stepperItems = [
  {
    key: STEPS.UPLOAD,
    title: i18n.t("upload"),
    description: null,
    icon: "fa fa-cloud-upload",
  },
  {
    key: STEPS.REVIEW,
    title: i18n.t("review"),
    description: null,
    icon: "fa fa-list-alt",
  },
  {
    key: STEPS.PROCESSING,
    title: i18n.t("processing"),
    description: null,
    icon: "fa fa-spinner",
  },
  {
    key: STEPS.FINISHED,
    title: i18n.t("finished"),
    description: null,
    icon: "fa fa-check",
  },
]

const checkFinishedSteps = (item: any) => {
  const finishedStepsBasedOnCurrentStep = {
    UPLOAD: [],
    REVIEW: [STEPS.UPLOAD],
    PROCESSING: [STEPS.UPLOAD, STEPS.REVIEW],
    FINISHED: [STEPS.UPLOAD, STEPS.REVIEW, STEPS.PROCESSING, STEPS.FINISHED]
  }

  return finishedStepsBasedOnCurrentStep[step.value].includes(item.key)
}

const hints = {
  REVIEW: i18n.t("double_check_your_changes_before_using_the_next_button"),
  PROCESSING: i18n.t("no_turning_back")
}
const hint = computed(() => hints[step.value] || "")
/** END Handling Stepper */

const isSure = ref(null)
const loading = ref(false)
const failedRows = ref({})
const uploadedFile = ref(null)
const returnedData = ref({})

const uploadedData = ref<MergerParsedUploadedRows>(null)
const processedUploadedData = ref({})
const uploadedDataErrors = ref({})
const changedData = ref([])
const totalData = ref(0)
const validatedData = ref([])
const successfullyChanged = ref(0)
const totalRequests = ref(0)

const settledPercentages = computed(() => ({
  success: (successfullyChanged.value / totalRequests.value) * 100,
  failed: (Object.keys(failedRows.value).length / totalRequests.value) * 100,
}))

const currentData = computed(() => 
  (props.dataProcessor ? props.dataProcessor(props.data) : props.data).reduce((object, d) => {
    object[d[props.idColumn]] = d
    return object
  }, {})
)

const formatToExport = (data: any[]) => {
  return data.map(row => {
    if (!row) return row

    // idColumn will be on the 1st column, then next to it is schema keys without the idColumn
    const keys = [props.idColumn, ...columns.value.filter(c => c != props.idColumn)]
    return keys.reduce((result, key) => {
      if (row[key] == null) {
        result[key] = ""
        return result
      }

      switch (props.schema[key]?.type) {
        case "array":
          result[key] = row[key].join(",")
          break
        case "namedObject":
          result[key] = row[key].name
          break
        case "boolean":
          result[key] = i18n.t(row[key] ? "yes" : "no").toUpperCase()
          break
        case "integer":
        case "number":
          result[key] = row[key].toString()
          break
        default:
          result[key] = row[key]
          break
      }

      return result
    }, {})
  })
}
const exportableData = computed(() => formatToExport(Object.values(currentData.value)))

const processedChangedData = computed(() => {
  const values = changedData.value.map(d => validatedData.value[d[props.idColumn] as number])
  return props.valueProcessor ? props.valueProcessor(values) : values
})

const succeededRows = computed(() => omit(processedUploadedData.value, Object.keys(failedRows.value)))
const columns = computed(() => Object.keys(props.schema))
const isVisible = computed<boolean>({
  get: () => props.show,
  set: (value) => emit(value === false ? "close" : "open"),
})

const prevButton = computed(() => ({
  show: [STEPS.REVIEW, STEPS.FINISHED].includes(step.value),
  text: i18n.t("merge-component-back"),
  disabled: loading.value,
  color: step.value === STEPS.REVIEW && hasErrors() ? "primary" : "secondary",
  iconClass: "fa fa-chevron-left me-2",
  onClick: () => (step.value = STEPS.UPLOAD),
}))
const nextButton = computed(() => {
  const button = {
    show: step.value !== STEPS.UPLOAD,
    text: i18n.t("merge-component-next"),
    disabled: loading.value,
    color: "success",
    variant: null,
    iconClass: "fa fa-chevron-right ms-2",
    onClick: () => {
      next()
    },
  }
  if (step.value === STEPS.REVIEW) {
    if (changedData.value.length == 0) {
      button.show = false
    } else if (isSure.value === false) {
      button.color = "danger"
      button.variant = "outline"
      button.text = i18n.t("merge-component-sure")
      button.onClick = () => (isSure.value = true && next())
    } else if (isSure.value === null) {
      button.show = !hasErrors()
      button.onClick = () => {
        isSure.value = false
      }
    }
  }
  if (step.value === STEPS.PROCESSING) {
    button.show = false
  }
  if (step.value === STEPS.FINISHED) {
    button.onClick = () => close()
    button.text = i18n.t("merge-component-finish")
    button.iconClass = "fa fa-check ms-2"
  }
  return button
})

const init = () => {
  step.value = STEPS.UPLOAD
  isSure.value = null
  loading.value = false
  uploadedData.value = null
  uploadedDataErrors.value = {}
  failedRows.value = {}
  returnedData.value = {}
}

const hasErrors = (): boolean => {
  for (const id in uploadedDataErrors.value) {
    const row = uploadedDataErrors.value[id]
    if (!row) continue
    if (Object.keys(row).length) return true
  }
  return false
}

const doRequests = async (
  rows: Record<string, unknown>[]
): Promise<Record<"failed" | "success", Record<string, unknown>[]>> => {
  totalRequests.value = rows.length
  const firstChunk = rows.splice(0, props.requestBatch)

  const toRequest = row =>
    row &&
    props.setRow(row[props.idColumn], row)
      .then((data) => (successfullyChanged.value++, delete failedRows.value[row[props.idColumn]], returnedData.value[row[props.idColumn]] = data))
      .catch(() => (failedRows.value[row[props.idColumn]] = row, delete returnedData.value[row[props.idColumn]]))
      .then(() => sleep(10).then(() => toRequest(rows.shift())))

  await Promise.allSettled(firstChunk.map(toRequest))

  return { failed: Object.values(failedRows.value), success: Object.values(returnedData.value) }
}

const next = async() => {
  const nextSteps = {
    UPLOAD: STEPS.REVIEW,
    REVIEW: STEPS.PROCESSING,
    PROCESSING: STEPS.FINISHED,
    FINISHED: STEPS.FINISHED
  }
  step.value = nextSteps[step.value]
  if (step.value === STEPS.PROCESSING) {
    failedRows.value = {}
    returnedData.value = {}
    successfullyChanged.value = 0
    totalRequests.value = 0

    loading.value = true
    
    await sleep(10) // This line allows the UI to actually update
    var { failed, success } = await doRequests(cloneDeep(processedChangedData.value))
    let retries = 0

    while (failed.length && retries++ < 3) {
      await sleep(3000)
      var { failed, success } = await doRequests(failed)
    }
    
    loading.value = false
    emit("done", success)
    next()
  }
}
const open = () => {
  init()
  emit("open")
}
const close = () => {
  init()
  emit("close")
}
const uploadFile = (file) => {
  loading.value = true
  uploadedFile.value = file
  const extension = file.name.split(".").pop().toLowerCase()

  const reader = new FileReader()
  reader.onload = function (event) {
    const file = event.target.result

    if (extension === "xlsx") {
      parseXLSX(file).then(result => {
        parseFileData(result)
      }).catch()
    } else if (extension === "csv") {
      parseCSV(file).then(result => {
        parseFileData(result)
      }).catch()
    }
  }

  extension === "xlsx" ? reader.readAsArrayBuffer(file) : reader.readAsText(file)
}
const parseFileData = (values: MergerRawUploadedRows) => {
  // Sometimes when a user deletes rows in excel,
  // the rows still exist in the file, but they're just empty.
  // With this code we just make sure that we ignore rows like that
  values = values.filter(
    columns => !Object.values(columns).every(v => v == null || v === "")
  )

  const newIds = values.map(row => +row[props.idColumn]).sort()
  const oldIds = Object.keys(currentData.value)
    .map(id => +id)
    .sort()

  // TODO: figure out why xlsx exported by the Apple Numbers app
  // completely fucks up the ids of many rows. For now
  // it means Apple Numbers is unusable with .xlsx files.

  uploadedData.value = values.reduce((obj, row) => {
    const id = +row[props.idColumn] || Math.round(Math.random() * 1e16)
    obj[id] = { ...row, [props.idColumn]: id }
    return obj
  }, {} as MergerParsedUploadedRows)
  loading.value = false
  next()
}
const parseXLSX = (file) => {
  return new Promise<MergerRawUploadedRows>((resolve, reject) => {
    const res = XLSX.read(file)
    const sheet = Object.values(res.Sheets)[0]
    if (!sheet) reject(i18n.t("no_sheet_found"))
    resolve(
      XLSX.utils.sheet_to_json(sheet, {
        blankrows: false,
        defval: null,
        raw: true,
        rawNumbers: true,
      })
    )
  })
}
const exportXLSX = (values) => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(values, {
    WTF: true,
    cellStyles: true,
  })
  XLSX.utils.book_append_sheet(wb, ws, props.title)
  XLSX.writeFile(wb, `${props.title}.xlsx`)
  return true
}
const parseCSV = (values) => {
  return new Promise<MergerRawUploadedRows>((resolve, reject) => {
    Papa.parse(values, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: results => {
        const theData = []
        if (results.data) {
          results.data.forEach(row => {
            const result = {}
            Object.keys(row).forEach(item => {
              result[item.toLowerCase()] = row[item]
            })
            theData.push(result)
          })
        }
        resolve(theData)
      },
      error(error) {
        reject(error)
      },
    })
  })
}
const exportCSV = (rawData) => {
  const results = []
  const headers = []
  Object.values(rawData).forEach(item => {
    const result = []
    // Assign data
    Object.keys(item).forEach(x => result.push(item[x]))

    results.push(result)
    // Create headers
    Object.keys(item).forEach(x => {
      if (!headers.includes(x)) {
        headers.push(x)
      }
    })
  })
  const rows = [headers, ...results]
  let csvContent = "data:text/csv;charset=utf-8,"
  csvContent += [...rows.map(item => Object.values(item).join(";"))]
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, "")

  const encodedUri = encodeURI(csvContent)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", `${props.title}.csv`)
  document.body.appendChild(link) // Required for FF
  link.click()
  document.body.removeChild(link)
}

const onTableInitialized = (
  data: Record<"processedUploadedData" | "errors" | "changedData" | "data" | "validatedData", any>
) => {
  processedUploadedData.value = data.processedUploadedData
  uploadedDataErrors.value = data.errors
  changedData.value = data.changedData
  totalData.value = data.data.length
  validatedData.value = data.validatedData
}
</script>

<style lang="scss" scoped>
.btn_row {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

table ul.dashed {
  margin: 0;
  padding-left: 0.5rem;
  list-style: none;

  & > li:before {
    content: "-";
    margin-right: 0.25rem;
  }
}

.changed {
  background: skyblue;
}

.center_row {
  flex: 1;
  justify-content: center;
}

.valid {
  border: 2px solid green;
  background-color: lightgreen;
}

.invalid {
  border: 2px solid red;
  background-color: salmon;
}

.unknown {
  border: 2px solid lightslategray;
  background-color: lightness($color: lightgray);
}

.finalBtn,
.exportBtn {
  width: 100%;
}

.modal-title {
  width: 100% !important;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}
.table-container {
  overflow-x: auto;
  max-height: 80vh;
}
</style>