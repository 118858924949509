<template>
  <div
    class="table-tree-expander"
    :class="{
      'table-tree-expander__expanded': props.isExpanded && props.totalChildren > 0,
      'table-tree-expander__children': props.isChild
    }"
    :style="`margin-left: ${props.deep * 2}rem;--total-children: ${props.totalVisibleChildren}`"
  >
    <template v-if="props.totalChildren > 0">
      <i
        v-if="!props.isExpanded"
        class="fa fa-chevron-right cursor-pointer"
        @click="emit('expand')"
      />
      <i
        v-else
        class="fa fa-chevron-down cursor-pointer"
        @click="emit('collapse')"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    isExpanded?: boolean
    isChild?: boolean
    deep?: number
    totalChildren?: number
    totalVisibleChildren?: number
  }>(),
  {
    isExpanded: false,
    isChild: false,
    deep: 0,
    totalChildren: 0,
    totalVisibleChildren: 0
  }
)

const emit = defineEmits(["expand", "collapse"])
</script>

<style lang="scss">
.table-tree-expander {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  &__expanded::before {
    border-left: 1px dashed #333;
    content: "";
    left: 0.4rem;
    position: absolute;
    top: 2.4rem;
    height: calc(100% + 0.5rem + ((var(--total-children) - 1) * 4.645rem));
  }
  &__children
  {
    position: relative;
    &::after {
      border-top: 1px dashed #333;
      content: "";
      left: -1.6rem;
      position: absolute;
      width: 1.5rem;
    }
  }
}
</style>
