import type { GetCommand, Command, MemCacheMessage } from "@/idb"

export default function sendCommand<
  C extends Command,
  Options extends { usePromise?: boolean },
  T = C extends GetCommand ? MemCacheMessage : void | "",
  Result = Options extends { usePromise: false } ? void : Promise<T>
>(message: C, { usePromise = true } = {} as Options): Result {
  if (!navigator.serviceWorker?.controller) {
    const result = import("@/idb").then(
      idb =>
        new Promise<T>(resolve => {
          const { port1, port2 } = new MessageChannel()

          port1.onmessage = ({ data }) => {
            resolve(data)
            port1.onmessage = null
          }

          const messageEvent = new MessageEvent<C>("message", {
            data: message,
            ports: [port2],
          })

          const handleMessage = idb.handleMessage.bind(port2)

          handleMessage(messageEvent)
        })
    )

    if (usePromise) return result as Result
  }

  if (!usePromise)
    return navigator.serviceWorker.controller.postMessage(message) as Result

  return new Promise<T>(resolve => {
    const { port1, port2 } = new MessageChannel()
    port1.onmessage = ({ data }) => resolve(data)
    navigator.serviceWorker.controller.postMessage(message, [port2])
  }) as Result
}
