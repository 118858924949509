<template>
  <div class="table-footer wrapped-with-zhndxrfisfsfs">
    <span v-html="props.text" />
    <div v-if="props.pagination.show && props.pagination.totalData > props.pagination.perPage">
      <CPagination
        aria-label="Page navigation example"
        class="ms-3 pagination"
      >
        <CPaginationItem
          aria-label="Previous"
          :disabled="!allowPrev"
          @click="allowPrev ? emit('page-changed', props.pagination.currentPage - 1) : () => ({})"
        >
          <span aria-hidden="true">&laquo;</span>
        </CPaginationItem>
        <CPaginationItem
          v-for="(page, index) in visiblePages"
          :active="page === props.pagination.currentPage"
          :key="index"
          @click="emit('page-changed', page)"
        >
          {{ page }}
        </CPaginationItem>
        <CPaginationItem
          aria-label="Next"
          :disabled="!allowNext"
          @click="allowNext ? emit('page-changed', props.pagination.currentPage + 1) : () => ({})"
        >
          <span aria-hidden="true">&raquo;</span>
        </CPaginationItem>
      </CPagination>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { last } from 'lodash-es'
import { computed } from "vue-demi"

interface Pagination {
  currentPage: number
  perPage: number
  totalData: number
  show: boolean
}

const props = withDefaults(
  defineProps<{
    pagination?: Pagination
    text?: string
  }>(),
  {
    pagination: () => ({
      currentPage: 1,
      perPage: 10,
      totalData: 0,
      show: false
    }),
    text: ''
  }
)
const emit = defineEmits(["page-changed"])

const allowPrev = computed(() => props.pagination.currentPage > 1)
const allowNext = computed(() => props.pagination.totalData > props.pagination.perPage * props.pagination.currentPage)
const visiblePages = computed(() => {
  // only show 2 pages before and after current page
  if (!props.pagination.show) return []
  const totalPage = Math.ceil(props.pagination.totalData / props.pagination.perPage)

  const { currentPage } = props.pagination
  let start = currentPage - 2
  if (start <= 0) start = 1

  let end = currentPage + 2
  if (end >= totalPage) end = totalPage
  
  const twoPagesBeforeAndAfterCurrentPage = []
  for (let i = start; i <= end; i++) {
    twoPagesBeforeAndAfterCurrentPage.push(i)
  }
  while (twoPagesBeforeAndAfterCurrentPage.length < 5 && !twoPagesBeforeAndAfterCurrentPage.includes(totalPage)) {
    twoPagesBeforeAndAfterCurrentPage.push((last(twoPagesBeforeAndAfterCurrentPage) || 0) + 1)  
  }

  return twoPagesBeforeAndAfterCurrentPage
})
</script>

<style lang="scss">
.wrapped-with-zhndxrfisfsfs {
  &.table-footer {
    display: flex;
    align-items: center;
    .pagination {
      ul {
        margin-bottom: 0px
      }
      .page-item {
        .page-link:not(:disabled) {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
