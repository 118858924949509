<template>
  <div>
    <ModalWrapper v-model="isVisible">
      <CModal :visible="isVisible" backdrop="static" @close="isVisible = false">
        <CModalHeader>
          <CModalTitle>{{ $t("company_settings") }}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <ImageInput
            class="mb-4"
            :text="$t('logo')"
            :upload-text="$t('change_logo')"
            :image-id="logo_id"
            default-image="/img/brand/logo_right_dark.png"
            @uploaded="data => (logo_id = data.id)"
          />
          <DynamicFormWrapper
            :form="form"
            @submit="onSubmit"
          />

          <div class="d-grid">
            <CButton
              variant="outline"
              color="success"
              class="mt-4"
              @click="showAddressModal = true"
            >
              <i class="fa fa-address-book me-2" />
              {{ $t("addresses") }}
            </CButton>
          </div>
        </CModalBody>

        <CModalFooter class="justify-content-between">
          <CButton color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
          <CButton color="success" type="submit" :form="form.id">
            {{ $t("save") }}
          </CButton>
        </CModalFooter>
      </CModal>
    </ModalWrapper>
    <AddressListModal
      :show="showAddressModal"
      :data="organizationStore.mappedCurrent?.addresses"
      :add="
        v => organizationStore.addAndActivateAddress(organizationStore.current?.id, v).then(
          async () => {
            await addressStore.fetchByOrganizationId(organizationStore.current?.id)
            organizationStore.current = organizationStore.all.find(o => o.id === organizationStore.current?.id)
          }
        )
      "
      :update="
        v => addressStore.update(v, {
          withoutOptimistic: true,
          onSuccess: () => addressStore.fetchByOrganizationId(organizationStore.current?.id)
        })
      "
      @close="showAddressModal = false"
      @open="showAddressModal = true"
    />
  </div>
</template>

<script lang="ts" setup>
import { watchEffect, ref, computed } from "vue-demi"
import { useI18n } from "vue-i18n"
import { organizationStore, addressStore, manufacturerStore } from "@/store"
import AddressListModal from "../address/AddressListModal.vue"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ImageInput from "@/components/inputs/ImageInput.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"
import { updateObjectWithProperties } from "@/libraries/helpers"

const i18n = useI18n()
const props = defineProps<{ show: boolean }>()
const emit = defineEmits(["close", "updated", "open"])

const showAddressModal = ref(false)
const logo_id = ref(null)

const isVisible = computed({
  get: () => props.show && !showAddressModal.value,
  set: (v: boolean) => emit(v ? "open" : "close"),
})

const form = computed(() => ({
  id: "organization-form",
  fields: {
    id: {
      type: "hidden",
      defaultValue: organizationStore.current?.id,
    },
    shortname: {
      type: "text",
      label: i18n.t("shortname"),
      defaultValue: organizationStore.current?.nickname,
      disabled: true,
    },
    name: {
      type: "text",
      label: i18n.t("company_name"),
      placeholder: "",
      validations: ["required"],
      defaultValue: organizationStore.current?.name,
    },
    phone: {
      type: "text",
      label: i18n.t("company_phone"),
      validations: ["required"],
      defaultValue: organizationStore.current?.phone,
    },
    email: {
      type: "email",
      label: i18n.t("email"),
      validations: ["required", "email"],
      defaultValue: organizationStore.current?.email,
    },
    tax_number: {
      type: "text",
      label: i18n.t("company_tax"),
      validations: ["required"],
      defaultValue: organizationStore.current?.tax_number,
    },
    company_number: {
      type: "text",
      label: i18n.t("company_number"),
      validations: ["required"],
      defaultValue: organizationStore.current?.company_number,
    },
  },
  valueProcessor: values => {
    if (logo_id.value) values.logo = logo_id.value
    return values
  },
}))

watchEffect(() => {
  if (!isVisible.value) logo_id.value = null
  if (organizationStore.current?.logo) logo_id.value = organizationStore.current.logo
})

const onSubmit = (values: any) => {
  isVisible.value = false
  organizationStore.update(values).then(async () => {
    updateObjectWithProperties(organizationStore.current, values, ["id", "shortname"])
    if (manufacturerStore.current?.id === organizationStore.current?.id) updateObjectWithProperties(organizationStore.current, values, ["id", "shortname"])
    manufacturerStore.logoKey = Date.now() // update logo
    await manufacturerStore.getLogoByNickname(manufacturerStore.manufacturerNickname)
    emit("updated")
  }).catch()
}
</script>
