<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" @close="close">
      <CModalHeader>
        <CModalTitle>{{ $t("add_customer") }}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div v-if="props.loading" class="d-flex justify-content-center">
          <CSpinner class="align-middle me-2" />
          <strong class="m-1">{{ $t("processing") }}</strong>
        </div>
        <template v-else>
          <template v-if="step == 'customer-details-form'">
            <h5 class="mb-4">Customer Details</h5>
            <DynamicFormWrapper :form="customerForm" @submit="onSubmit" />
          </template>
          <template v-if="step == 'address-form'">
            <h5 class="mb-4">Address</h5>
            <DynamicFormWrapper :form="addressForm" @submit="onSubmit" />
          </template>
        </template>
      </CModalBody>
      <CModalFooter class="justify-content-between">
        <template v-if="!props.loading">
          <CButton
            color="secondary"
            @click="
              step == 'customer-details-form' ? close() : (step = 'customer-details-form')
            "
          >
            <i v-if="step == 'address-form'" class="fa fa-chevron-left me-2" />
            <span>
              {{ step == "customer-details-form" ? $t("cancel") : $t("previous") }}
            </span>
          </CButton>
          <CButton color="success" type="submit" :form="step">
            <i v-if="step == 'address-form'" class="fa fa-plus me-2" />
            {{ step == "address-form" ? $t("add") : $t("next") }}
            <i v-if="step == 'customer-details-form'" class="fa fa-chevron-right ms-2" />
          </CButton>
        </template>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue-demi"
import { useI18n } from "vue-i18n"
import countries from "../datas/counties.json"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    show?: boolean
    loading?: boolean
  }>(),
  {
    show: false,
    loading: false,
  }
)

const emit = defineEmits(["close", "submit", "change"])

const isVisible = computed({
  get: () => props.show,
  set: (v: boolean) => {
    reset()
    if (v == false) emit("close")
  },
})
const close = () => {
  emit("change", {})
  isVisible.value = false
}

const customer = ref<any>({})
const customerForm = computed(() => ({
  id: "customer-details-form",
  fields: {
    name: {
      type: "text",
      label: i18n.t("company_name"),
      placeholder: "",
      validations: ["required"],
      defaultValue: customer.value?.name,
    },
    phone: {
      type: "text",
      label: i18n.t("company_phone"),
      validations: ["required"],
      defaultValue: customer.value?.phone,
    },
    email: {
      type: "email",
      label: i18n.t("email"),
      validations: ["required", "email"],
      defaultValue: customer.value?.email,
    },
    tax_number: {
      type: "text",
      label: i18n.t("company_tax"),
      validations: ["required"],
      defaultValue: customer.value?.tax_number,
    },
    company_number: {
      type: "text",
      label: i18n.t("company_number"),
      validations: ["required"],
      defaultValue: customer.value?.company_number,
    },
  },
}))

const address = ref<any>({})
const addressForm = computed(() => ({
  id: "address-form",
  fields: {
    line_1: {
      type: "text",
      label: i18n.t("address_line_1"),
      placeholder: i18n.t("address_line_1"),
      validations: ["required"],
      defaultValue: address.value?.line_1,
    },
    line_2: {
      type: "text",
      label: i18n.t("address_line_2"),
      placeholder: i18n.t("address_line_2"),
      defaultValue: address.value?.line_2,
    },
    city: {
      type: "text",
      label: i18n.t("city"),
      placeholder: "Amsterdam",
      validations: ["required"],
      defaultValue: address.value?.city,
    },
    province: {
      type: "text",
      label: i18n.t("province"),
      placeholder: "Noord Holland",
      defaultValue: address.value?.province,
    },
    postcode: {
      type: "text",
      label: i18n.t("zip_code"),
      placeholder: "1234 AA",
      validations: ["required"],
      defaultValue: address.value?.postcode,
    },
    country: {
      type: "select",
      label: i18n.t("country"),
      defaultValue: address.value?.country,
      validations: ["required"],
      options: [
        {
          label: `<${i18n.t("nothing")} ${i18n.t("selected")}}>`,
          value: "",
          disabled: true,
        },
        ...(countries || []).map((v): any => ({
          label: v,
          value: v,
          disabled: false,
        })),
      ],
    },
  },
}))

const step = ref<string>("customer-details-form")
const reset = () => {
  customer.value = {}
  address.value = {}
  step.value = "customer-details-form"
}

const onSubmit = (values: any) => {
  if (step.value == "customer-details-form") {
    customer.value = values
    step.value = "address-form"
    return
  }
  address.value = values

  emit("submit", { customer: customer.value, address: address.value })
}
</script>
