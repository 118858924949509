import { defineStore } from "pinia"
import axios from "axios"
import { cloneDeep, forEach } from "lodash-es"
import type { CreatePart, Part } from "@/interfaces"
import { materialStore, fileStore, organizationStore, partStore } from "."
import { addOrUpdate, getId, optimisticAdd, optimisticUpdate, retryableFetch } from "@/libraries/helpers"
import { getCache, setCache, JsonHelpers } from "@/libraries/helpers"

const cacheKey = "parts"

const mapPart = (part: Part) => {
  const mappedPart = {
    ...part,
    get parent() {
      const index = (partStore.mappedData || []).findIndex(d => d.id === getId(part.parent?.part))
      return index === -1
        ? null
        : {
            ...part.parent,
            part: partStore.mappedData[index],
          }
    },
    material: materialStore.all.find(material => material.id === getId(part.material)),
    source: fileStore.all.find(file => file?.id === getId(part.source)) || part.source,
    customer: organizationStore.mappedData.find(org => org.id === getId(part.customer)),
    toJSON() {
      return JsonHelpers.decycle(this)
    },
  }
  return mappedPart
}

export default defineStore("part", {
  state: () => ({
    all: [],
    all2DImages: {},
    all3DImages: {},
    all3DModels: {},
  }),
  getters: {
    getPartsByOrderId: state => (orderId: number) => {
      return state.all.filter(part => part.order === orderId)
    },
    mappedData: state => state.all.map(mapPart),
  },
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as Part[]
    },
    fetchPartsByOrderId(orderId: number) {
      return new Promise((resolve, reject) => {
        if (!orderId) return reject("Error: order not found")
        axios
          .get(`/v1/orders/${orderId}/parts`)
          .then(({ data }) => {
            const ids = data.map(b => b.id)
            const parts = cloneDeep(
              this.all.filter(
                b => (ids.includes(b.id) && b.order === orderId) || b.order !== orderId
              )
            )
            
            forEach(data || [], v => addOrUpdate(parts, v, ["id"]))

            this.all = parts

            resolve(data)
          })
          .catch(reject)
          .finally(() => setCache(cacheKey, this.all))
      })
    },
    get2DImagesByIds(ids: number[]) {
      return new Promise(resolve => {
        const existingIds = Object.keys(this.all2DImages)
        const newIds = ids.filter(id => !existingIds.includes(id.toString()))
        if (newIds.length === 0) return resolve(true)

        forEach(
          newIds,
          async id => {
            try {
              await retryableFetch({
                url: `/v1/parts/${id}/patterns/images`,
                callback: ({ data }) => (this.all2DImages[id] = data),
                config: {
                  headers: {
                    "Cache-Control": "no-cache",
                  },
                },
              })
            } catch (error) {}
          }
        )
        resolve(true)
      })
    },
    get3DImagesByIds(ids: number[]) {
      return new Promise(resolve => {
        const existingIds = Object.keys(this.all3DImages)
        const newIds = ids.filter(id => !existingIds.includes(id.toString()))
        if (newIds.length === 0) return resolve(true)

        forEach(
          newIds,
          async id => {
            try {
              await retryableFetch({
                url: `/v1/parts/${id}/images`,
                callback: ({ data }) => (this.all3DImages[id] = data),
                config: {
                  headers: {
                    "Cache-Control": "no-cache",
                  },
                },
              })
            } catch (error) {}
          }
        )
        resolve(true)
      })
    },
    get3DModelsByIds(ids: number[]) {
      return new Promise(resolve => {
        try {
          const existingIds = Object.keys(this.all3DModels)
          const newIds = ids.filter(id => !existingIds.includes(id.toString()))
          if (newIds.length === 0) return resolve(true)

          forEach(
            newIds,
            async id => {
              try {
                await retryableFetch({
                  url: `/v1/parts/${id}/models`,
                  callback: ({ data }) => {
                    const blob = new Blob([data], { type: "text/plain" })
                    this.all3DModels[id] = URL.createObjectURL(blob)
                  },
                  config: {
                    headers: {
                      "Cache-Control": "no-cache",
                    },
                  },
                })
              } catch (error) {}
            }
          )
          resolve(true)
        } catch (error) {}
      })
    },
    add(values: CreatePart) {
      if (values.source) values.source = getId(values.source)
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/parts",
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    addSubPart(parentId: number, values: CreatePart) {
      if (values.source) values.source = getId(values.source)
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: `/v1/parts/${parentId}/parts`,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    update(values: Partial<Part> & Required<Pick<Part, "id">>) {
      if (values.source) values.source = getId(values.source)
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/parts/${values.id}`,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
  },
})
