import axios from "axios"
import { cloneDeep, pullAt } from "lodash-es"

interface Param {
  allObjects: any[][] // this is pointer by reference
  id: string | number
  url: string
  optimisticDeleteCallback?: () => void
  onSuccess?: () => void
  onError?: () => void
}
export default function ({
  allObjects, id, url,
  optimisticDeleteCallback = () => {},
  onSuccess = () => {},
  onError = () => {}
}: Param) {
  return new Promise((resolve, reject) => {
    const indexes = []
    const currentValues = []
    for (let i = 0; i < allObjects.length; i++) {
      const objects = allObjects[i];

      const index = objects.findIndex(o => o.id === id)
      indexes.push(index)
      if (index === -1) {
        currentValues.push(null)
        continue
      }
      currentValues.push(cloneDeep(objects[index]))
      pullAt(objects, [index])
    }
    optimisticDeleteCallback()
    axios
      .delete(url)
      .then(({ data }) => {
        onSuccess()
        resolve(data)
      })
      .catch(err => {
        const isCancelled = err?.message === "canceled"
        for (let i = 0; i < allObjects.length; i++) {
          const objects = allObjects[i];
          if (indexes[i] === -1) continue

          objects.splice(indexes[i], 0, currentValues[i])
        }
        onError()
        if (!isCancelled) reject(err)
      })
  })
}