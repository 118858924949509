import { defineStore } from "pinia"
import axios from "axios"
import { forEach } from "lodash-es"
import type { CreateUser, Order, User } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import { manufacturerStore, organizationStore, requestStore } from "."
import { addOrUpdate, getId, optimisticAdd, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"
import { getCache, setCache } from "@/libraries/helpers"

const cacheKey = "users"

const mapData = (user: User) => ({
  ...user,
  organization:
    organizationStore.mappedData.find(organization => organization.id === getId(user?.organization)) ||
    user?.organization,
  isCustomerOf(order: Order) {
    return getId(user?.organization) === getId(order?.customer)
  },
  isManufacturerOf(order: Order) {
    return getId(user?.organization) === getId(order?.manufacturer)
  },
  isCreatorOf(order: Order) {
    return user.id === getId(order?.created_by)
  },
  get parentManufacturer() {
    const parent = manufacturerStore.mappedData.find(
      manufacturer => manufacturer.nickname === manufacturerStore.manufacturerNickname,
    )
    return parent?.id !== getId(user?.organization) ? parent : undefined
  },
  role: user.is_admin
    ? "admin"
    : getId(user?.organization) === getId(manufacturerStore.current)
      ? "manufacturer"
      : "customer",
  get availableManufacturers() {
    const nicknames = []
    if (this.organization?.is_manufacturer) nicknames.push(this.organization.nickname)
    if (this.organization?.manufacturers?.length > 0)
      forEach(this.organization.manufacturers, m => nicknames.push(m.nickname))
    return nicknames
  },
})

export default defineStore("user", {
  state: () => ({
    current: null,
    all: [],
  }),
  getters: {
    mappedCurrent: state => (state.current ? mapData(state.current) : null),
    mappedData: state => state.all.map(mapData),
  },
  actions: {
    init() {
      this.all = (getCache(cacheKey)?.data || []) as User[]
    },
    fetchCurrent() {
      return new Promise((resolve, reject) => {
        const url = `/v1/users/current`
        requestStore.modifyRunningFetches({
          url,
          persist: true,
          prefetching: true,
          abortController: new AbortController(),
        })
        axios
          .get(url)
          .then(({ data }) => {
            this.current = data
            resolve(this.current)
          })
          .catch(reject)
          .finally(() => requestStore.removeRunningFetch(url))
      })
    },
    async fetchAll({ persist = false, prefetching = false } = {}) {
      await paginatedFetch({
        url: "/v1/users",
        persist,
        prefetching,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data => this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
      setCache(cacheKey, this.all)
    },
    add(values: CreateUser) {
      if (values.organization) values.organization = getId(values.organization)
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/users",
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    update(values: User) {
      if (values.organization) values.organization = getId(values.organization)
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/users/${values.id}`,
        onSuccess: () => setCache(cacheKey, this.all),
      })
    },
    async resetPassword({ token, password }: Record<"token" | "password", string>) {
      return axios.patch(`/v1/users/reset`, { token, password }).catch()
    },
  },
})
