<template>
  <div class="container">
    <!-- x3d for displaying the model -->
    <x3d id="viewer" ref="viewer" show-stat="false" show-log="false">
      <scene>
        <TreeInline
          v-if="props.part.tree"
          :part-id="props.part.id"
          :tree="props.part.tree"
          @loaded="modelLoaded"
          @clicked="modelClicked"
        />
        <ModelInline v-else-if="model3D" ref="inlineModel" :part="props.part" />
      </scene>
    </x3d>

    <!-- x3d for displaying the xyz axes plane  -->
    <x3d id="origin" ref="origin" show-stat="false" show-log="false">
      <scene d-e-f="scene">
        <navigationinfo type="none" />
        <transform ref="transformation">
          <inline url="/models/CoordinateAxes.x3d" />
        </transform>
      </scene>
    </x3d>

    <!-- For displaying the list of items for assembly/folder -->
    <TreeItem
      v-if="props.part?.tree"
      class="assembly item"
      style="list-style-type: none"
      :tree="props.part?.tree"
      :selected="selectedTreeItem"
      @clicked="onTreeItemClicked"
      @toggled="onTreeItemToggled"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, watch, ref, type Ref, computed } from "vue-demi"
import { debounce } from "lodash-es"
import type { Part } from "@/interfaces"
import { partStore } from "@/store"
import TreeItem from "./TreeItem.vue"
import TreeInline from "./TreeInline.vue"
import ModelInline from "@/components/part-image/ModelInline.vue"

type X3dColor = `${number},${number},${number}`

interface X3dMaterial extends HTMLElement {
  diffuseColor: X3dColor
}

const props = defineProps<{ part: Part }>()

const inlineModel = ref<{ inlineElement: HTMLElement }>()
const origin = ref<Ref>(null)
const viewer = ref<Ref>(null)
const transformation = ref<Ref>(null)

const loading = ref<boolean>(!props.part.tree)
const selectedTreeItem = ref<number>(null)
const hiddenTreeItems = ref<number[]>([])

const model3D = computed(() => partStore.all3DModels[props.part?.id])

watch(inlineModel,
  (newer, older) => {
    const childNodeInsertedListener = () => {
      inlineModel.value.inlineElement.removeEventListener(
        "DOMNodeInserted",
        childNodeInsertedListener
      )

      modelLoaded()
    }

    if (older?.inlineElement == null && newer?.inlineElement != null) {
      return inlineModel.value.inlineElement.addEventListener(
        "DOMNodeInserted",
        childNodeInsertedListener,
        false
      )
    }
  },
  { immediate: true }
)

const fitAll = debounce(() => {
  if (loading.value) return
  if (!origin.value?.hasRuntime) return
  if (!viewer.value?.hasRuntime) return

  origin.value.runtime.fitAll()
  viewer.value.runtime.fitAll()
}, 250)
watch(() => [loading.value, origin.value, viewer.value], fitAll)

const modelLoaded = () => {
  const vp = viewer.value.querySelector("viewpoint")

  if (vp) {
    vp.addEventListener("viewpointChanged", updateView, false)
  }

  loading.value ? (loading.value = false) : fitAll()
}

const updateView = (event) => {
  const { orientation } = event
  transformation.value.rotation = `${[
    orientation[0].x,
    orientation[0].y,
    orientation[0].z,
    -orientation[1],
  ].join()} `
}

const onTreeItemClicked = debounce((index: number) => {
  const newElement = document.getElementById(`model_${index}`)
  const oldElement = document.getElementById(`model_${selectedTreeItem.value}`)
  selectedTreeItem.value = selectedTreeItem.value !== index ? index : null

  if (oldElement !== null) {
    const oldMaterialElement: X3dMaterial = oldElement.querySelector(
      `Shape.solid > Appearance > Material`
    )
    if (oldMaterialElement !== null) {
      oldMaterialElement.diffuseColor = "0.65,0.65,0.65"
    }
  }

  if (selectedTreeItem.value && newElement !== null) {
    const newMaterialElement: X3dMaterial = newElement.querySelector(
      `Shape > Appearance > Material`
    )
    if (newMaterialElement !== null) {
      newMaterialElement.diffuseColor = "1,0.65,0"
    }
  }
}, 100)

const onTreeItemToggled = (index: number) => {
  if (hiddenTreeItems.value.includes(index))
    hiddenTreeItems.value = hiddenTreeItems.value.filter(v => v != index)
  else hiddenTreeItems.value.push(index)

  const partElement = document.getElementById(`model_${index}`)

  if (partElement !== null) {
    partElement.setAttribute("render", hiddenTreeItems.value.includes(index) ? "false" : "true")
  }
}

const modelClicked = (e: X3dMouseEvent) => {
  if (!props.part?.tree) return

  const { hitObject } = e
  if (hitObject !== undefined) {
    const parts = hitObject.parentElement.id.split("_")
    const index = parts[1]

    onTreeItemClicked(parseInt(index))
  }
}

onMounted(() => x3dom.reload())
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

#viewer {
  position: absolute;
  border: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#origin {
  position: absolute;
  right: 0;
  bottom: 0;
  border: none;
  width: 200px;
  height: 200px;
  pointer-events: none;
}

.assembly {
  pointer-events: none;
  position: absolute;
  overflow: scroll;
  height: 100%;
  width: 100%;
  margin-left: -15px;
}
</style>
