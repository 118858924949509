<template>
  <div class="animated fadeIn">
    <CContainer>
      <CRow class="justify-content-center my-4">
        <CCol :md="10">
          <CCard>
            <CCardBody class="text-start">
              <h1>{{ $t("terms_and_conditions") }}</h1>

              <h3>Usage</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ex nisi,
                egestas vel lorem at, venenatis mattis nulla. Duis finibus nulla at nulla
                efficitur aliquet. Sed lobortis tincidunt lobortis. Donec pharetra nisi id
                laoreet tristique. Curabitur viverra at tortor at finibus. Proin libero
                elit, iaculis eu scelerisque sit amet, venenatis quis dui. Phasellus
                rhoncus neque nec sem malesuada rhoncus. Nunc ac vehicula mi, et lobortis
                mauris. Pellentesque nec odio sit amet erat facilisis porttitor. Cras
                pharetra metus sit amet ante sagittis, non lobortis nibh accumsan. Duis
                convallis feugiat imperdiet. Curabitur maximus velit nibh. Donec mi
                ligula, condimentum vel pellentesque in, suscipit at augue. Aliquam sed
                dictum sem. Phasellus ultricies sapien id bibendum imperdiet.
              </p>

              <h3>Storage</h3>
              <p>
                Fusce hendrerit ex magna, eget pharetra nisl viverra ac. Pellentesque
                habitant morbi tristique senectus et netus et malesuada fames ac turpis
                egestas. Aenean sit amet pulvinar ante. Morbi laoreet, erat vitae
                scelerisque volutpat, mi massa sodales quam, ut luctus arcu augue ac
                neque. Etiam et sodales tellus. Curabitur euismod leo ac sodales luctus.
                Donec diam enim, malesuada in tellus eget, tempor blandit erat. Quisque
                maximus fringilla arcu, eu tincidunt ex. Aliquam erat volutpat. Phasellus
                pretium libero sed quam pulvinar, non facilisis felis dapibus. Fusce
                elementum condimentum euismod. Etiam at eleifend sem. Praesent nec leo et
                ex pharetra gravida ac cursus neque.
              </p>

              <h3>Security</h3>
              <p>
                Vivamus scelerisque leo in orci sollicitudin auctor. Etiam ornare molestie
                porttitor. Etiam sit amet quam rutrum massa ultricies pellentesque.
                Maecenas consequat urna libero, ut condimentum elit sodales et. Cras
                fringilla quam bibendum mauris sagittis convallis. Interdum et malesuada
                fames ac ante ipsum primis in faucibus. Curabitur vitae nibh at orci
                sollicitudin porta molestie ac orci. Quisque auctor, enim et rhoncus
                dictum, tortor nisi vestibulum sapien, a tincidunt urna sapien sed erat.
                Curabitur a justo mauris. Sed congue neque vulputate lectus luctus
                ullamcorper non non ante. Cras ac nisi sed urna eleifend iaculis a sit
                amet lorem. Duis dignissim commodo libero, ut iaculis dui lacinia in. In
                scelerisque libero tortor, id rutrum nisi blandit sed.
              </p>

              <h3>Conditions</h3>
              <p>
                Donec elit leo, mattis in felis nec, sodales tempor dui. Suspendisse
                ultrices bibendum dolor vitae dapibus. Nam ligula ex, porttitor elementum
                sem placerat, dignissim pulvinar dui. Aliquam quis sodales libero.
                Pellentesque aliquam nisi dolor, in dignissim velit faucibus finibus.
                Curabitur molestie, lacus vitae dignissim imperdiet, arcu elit blandit
                tortor, sed lacinia nisl orci vitae ante. Vivamus mattis, nulla ac
                scelerisque vulputate, purus enim efficitur arcu, ut convallis lorem orci
                vitae neque. Ut consectetur, arcu nec rhoncus iaculis, mi risus lobortis
                sapien, et blandit ipsum neque non turpis. Maecenas aliquet nisi in
                vulputate bibendum. Etiam imperdiet, neque et interdum blandit, ante erat
                feugiat felis, id pharetra odio dui eu diam. Phasellus quis urna quam.
              </p>

              <h3>Legal</h3>
              <p>
                Mauris ac lectus lacinia elit eleifend iaculis. Maecenas vel mauris et
                neque efficitur porttitor in et sem. Quisque ac congue arcu. Proin finibus
                fermentum nibh tempor volutpat. Donec neque felis, feugiat tincidunt velit
                in, consectetur bibendum erat. Integer ut pharetra mi, sit amet tristique
                mauris. Phasellus ac erat eu mauris aliquam laoreet ac at enim. Nulla
                scelerisque sem in ipsum aliquam laoreet. Aenean ac massa ex. Curabitur
                vitae magna ligula. Duis suscipit felis vel iaculis laoreet. In gravida,
                enim nec dapibus egestas, odio dui condimentum arcu, at lacinia neque ex
                quis urna.
              </p>
              <table class="table">
                <thead />
                <tbody />
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script lang="ts" setup>
import { useMeta } from "vue-meta"
import { useI18n } from "vue-i18n"

const i18n = useI18n()

useMeta({ title: i18n.t("terms_and_conditions") })
</script>

<style scoped>
.cardWidth {
  width: 80%;
  margin: 40px auto;
}
</style>
