import { defineStore } from "pinia"
import { cloneDeep, forEach } from "lodash-es"
import type { CreateTool, Tool } from "@/interfaces"
import { UPDATE_DATA_THRESHOLD } from "@/constants"
import type { OptimisticAddOptionInterface, OptimisticUpdateOptionInterface } from "@/interfaces"
import { materialStore } from "."
import { addOrUpdate, bulkAddOrUpdate, getId, optimisticAdd, optimisticDelete, optimisticUpdate, paginatedFetch } from "@/libraries/helpers"

export default defineStore("tool", {
  state: () => ({
    all: [],
  }),
  getters: {
    mappedData: state =>
      state.all.map(obj => {
        return {
          ...obj,
          materials: materialStore.all.filter(material =>
            obj.materials.includes(material.id)
          ),
        }
      }),
  },
  actions: {
    modifyData(data = []) {
      const [ tools ] = bulkAddOrUpdate([cloneDeep(this.all)], data)

      this.all = tools
    },
    async fetchAll({ persist = false } = {}) {
      await paginatedFetch({
        url: "/v1/tools",
        persist,
        callback: data => forEach(data || [], v => addOrUpdate(this.all, v, ["id"])),
        runCallbackCondition: data =>
          this.all.length === 0 || data.length > UPDATE_DATA_THRESHOLD,
      })
    },
    add(values: CreateTool, options: OptimisticAddOptionInterface) {
      values.materials = values.materials?.map(getId)
      return optimisticAdd({
        allObjects: [this.all],
        values,
        url: "/v1/tools",
        ...options,
      })
    },
    update(values: Tool, options: OptimisticUpdateOptionInterface = {}) {
      values.materials = values.materials?.map(getId)
      return optimisticUpdate({
        allObjects: [this.all],
        values,
        url: `/v1/tools/${values.id}`,
        ...options,
      })
    },
    remove(id: number) {
      return optimisticDelete({
        allObjects: [this.all],
        url: `/v1/tools/${id}`,
        id,
      })
    },
  },
})
