import type { Limit, Setting } from "."
import type { Modify } from "."


export const machineTypeOptions = [
  "LABELING",
  "PREPARATION",
  "PACKING",
  "ASSEMBLY",
  "INSERT",
  "BRUSH",
  "DEBUR",
  "ROLL",
  "BEND",
  "CUT",
  "OTHER",
] as const

export const machinePartsOptions = ["ANY", "ASSEMBLY", "TUBE", "SHEET", "OTHER"] as const

export const machineProcessOptions = [
  "MILLING",
  "OXYACETYLENE",
  "PLASMA",
  "WATERJET",
  "LASER",
] as const

export type _CreateMachine = {
  name: string
  description?: string
  reference?: string
  type?: typeof machineTypeOptions[number]
  parts?: typeof machinePartsOptions[number]
  process?: typeof machineProcessOptions[number]
  cost: number // The machine cost in euro/s. Can not be negative number.

  limits?: number[] // limit_id[]
  settings?: number[] // setting_id[]
  priority?: number // Machine priority. Higher priority machines used by default.

  is_optional?: boolean
  is_available?: boolean
  is_visible?: boolean
}

export type _Machine = Modify<
  Required<_CreateMachine>,
  {
    id: number
    is_valid: boolean
    created: Date
    updated: Date
  }
>

type MachineRelations = { limits?: Limit[]; settings?: Setting[] }
export type CreateMachine = Modify<_CreateMachine, MachineRelations>
export type Machine = Modify<_Machine, Required<MachineRelations>>

