import type { Material, Modify } from "."

export const sheetCertificateOptions = ["TYPE_22", "TYPE_31", "TYPE_32"] as const

type _CreateSheet = {
  name: string
  reference?: string
  thickness: number
  width: number
  height: number
  material: number // material_id
  cost: number
  stock?: number
  is_available?: boolean
  is_visible?: boolean
  certificates?: typeof sheetCertificateOptions[number][]
}

type _Sheet = Modify<
  Required<_CreateSheet>,
  {
    id: number
    created: Date
    updated: Date
    is_in_stock: boolean
  }
>

export type Sheet = Modify<_Sheet, { material: Material }>
export type CreateSheet = Modify<_CreateSheet, { material: Material }>
