import { createI18n } from "vue-i18n"
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from "@/constants/trans"
import en from "@/lang/en"
import nl from "@/lang/nl"

const instance = createI18n({
  legacy: false,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { en, nl },
  warnHtmlMessage: false, // Don't show warning if HTML used as message
})

export default instance

export const i18n = instance.global
