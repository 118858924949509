import type { Batch } from "@/interfaces"
import type { MappedInjectedBatchInterface } from "@/interfaces"
import { generateFilterAlgorithm, getListColumns, toCurrency } from "@/libraries/helpers"

interface getTableOptionsParams {
  translator: Function
  columns: string[]
  loading: boolean
  treeView?: boolean
  batches?: MappedInjectedBatchInterface[]
}

export function getTableOptions({
  translator,
  columns,
  loading,
  treeView = false,
  batches = []
}: getTableOptionsParams) {
  const batchesSortStrings = getBatchesSortStrings(batches)

  return {
    selectable: {
      mode: "multiple",
      only: (row: Batch) => row.part?.type !== "ASSEMBLY",
      selectAllMode: "all",
      programmatic: false,
    },
    headings: {
      index: "#",
      filename: translator("file"),
      name: translator("name"),
      material: translator("material"),
      thickness: translator("thickness"),
      process: translator("process"),
      operations: translator("operations"),
      reference: translator("reference"),
      certificates: translator("certificates"),
      boolean__is_valid: translator("valid"),
      quantity: translator("quantity"),
      price: translator("price"),
      total: translator("total"),
      actions: translator("action"),
    },
    texts: {
      count: "Showing {from} to {to} of {count} records|{count} records|One record",
      first: "First",
      last: "Last",
      filter: "Filter:",
      filterPlaceholder: "Search query",
      limit: "Records:",
      page: "Page:",
      noResults: !loading ? "No matching records" : "",
      noRequest: "Please select at least one filter to fetch results",
      filterBy: "Filter by {column}",
      loading: "Loading...",
      defaultOption: "Select {column}",
      columns: "Columns",
    },
    pagination: { virtual: true },
    perPage: 20,
    page: 1,
    sortable: columns.filter(c => !["index", "preview", "actions"].includes(c)),
    filterByColumn: true,
    sortIcon: {
      base: "fa fa-lg",
      up: "fa-sort-asc",
      down: "fa-sort-desc",
      is: "fa-sort",
    },
    skin: "table table-hover",
    rowAttributesCallback: row => ({ id: row.id }),
    rowClassCallback: row => `${row.selected ? " selected" : ""}`,
    customFilters: [
      {
        name: "all",
        callback(row: MappedInjectedBatchInterface, query: string) {
          let values = [
            row.part?.filename || "",
            [row.part?.name || "", row.part?.id || "", row.id || ""].join("###"),
            row.quantity || 0,
            row.injected_material?.name || "",
            row.injected_thickness?.name || "",
            row.process?.name || "",
            (row.operations?.map(o => o.name || "") || []).join("###"),
            (row.certificates?.map(c => c.replace(/.*(\d)(\d)/, "$1.$2")) || []).join("###"),
            row.part?.reference || "",
            // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
            toCurrency(isNaN(row.unit_amount) || row.unit_amount === 0 ? 0 : row.unit_amount),
            // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
            toCurrency(isNaN(row.total_amount) || row.total_amount === 0 ? 0 : row.total_amount),
            row.is_valid,
          ]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch =>
                [
                  batch.part?.filename || "",
                  [batch.part?.name || "", batch.part?.id || "", batch.id || ""].join("###") || "",
                  row.quantity || 0,
                  row.injected_material?.name || "",
                  row.injected_thickness?.name || "",
                  row.process?.name || "",
                  batch.operations?.map(o => o.name || "").join("###") || "",
                  (batch.certificates?.map(c => c.replace(/.*(\d)(\d)/, "$1.$2")) || []).join("###") || "",
                  batch.part?.reference || "",
                  toCurrency(isNaN(row.unit_amount) || row.unit_amount === 0 ? 0 : row.unit_amount),
                  toCurrency(isNaN(row.total_amount) || row.total_amount === 0 ? 0 : row.total_amount),
                ].join("###")
              )
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
      },
    ],
    filterable: columns.filter(c => !["index", "preview", "actions"].includes(c)),
    get filterAlgorithm() {
      return {
        ...generateFilterAlgorithm(columns, "boolean"),
        filename(row: MappedInjectedBatchInterface, query: string) {
          let values = [row.part?.filename || ""]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => batch.part?.filename || "")
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        name(row: MappedInjectedBatchInterface, query: string) {
          let values = [row.part?.name || "", row.part?.id || "", row.id || ""]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => [batch.part?.name || "", batch.part?.id || "", batch.id || ""].join("###"))
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        quantity(row: MappedInjectedBatchInterface, query: string) {
          let values = [row.quantity || 0]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => batch.quantity || 0)
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        material(row: MappedInjectedBatchInterface, query: string) {
          let values = [row.injected_material?.name || ""]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => batch.injected_material?.name || "")
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        thickness(row: MappedInjectedBatchInterface, query: string) {
          let values = [row.injected_thickness?.name || ""]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => batch.injected_thickness?.name || "")
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        process(row: MappedInjectedBatchInterface, query: string) {
          let values = [row.process?.name || ""]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => batch.process?.name || "")
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        operations(row: MappedInjectedBatchInterface, query: string) {
          let values = row.operations?.map(o => o.name || "") || []
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => batch.operations?.map(o => o.name || "").join("###") || "")
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        certificates(row: MappedInjectedBatchInterface, query: string) {
          let values = row.certificates?.map(c => c.replace(/.*(\d)(\d)/, "$1.$2")) || []
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => (batch.certificates?.map(c => c.replace(/.*(\d)(\d)/, "$1.$2")) || []).join("###") || "")
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        reference(row: MappedInjectedBatchInterface, query: string) {
          let values = [row.part?.reference || ""]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              .map(batch => batch.part?.reference || "")
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        price(row: MappedInjectedBatchInterface, query: string) {
          // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
          let values = [toCurrency(isNaN(row.unit_amount) || row.unit_amount === 0 ? 0 : row.unit_amount)]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
              .map(batch => toCurrency(isNaN(batch.unit_amount) || batch.unit_amount === 0 ? 0 : batch.unit_amount))
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
        total(row: MappedInjectedBatchInterface, query: string) {
          // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
          let values = [toCurrency(isNaN(row.total_amount) || row.total_amount === 0 ? 0 : row.total_amount)]
          if (treeView) {
            const children = batches
              .filter(batch => row.allChildrenIds.includes(batch.id))
              // deepcode ignore UseNumberIsNan: isNaN is more suitable than solution from Snyk (Number.isNaN)
              .map(batch => toCurrency(isNaN(batch.total_amount) || batch.total_amount === 0 ? 0 : batch.total_amount))
            values = [...values, ...children]
          }
          return values.join("###").toLowerCase().includes(query.toLowerCase())
        },
      }
    },
    get listColumns() {
      return getListColumns(this.filterable)
    },
    customSorting: {
      filename(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = a.part.filename
          let bText = b.part.filename
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.filename || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.filename || ""}${!ascending ? "~" : ""}`
          }

          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },
      name(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = a.part.name
          let bText = b.part.name
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.name || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.name || ""}${!ascending ? "~" : ""}`
          }

          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },
      quantity(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = (a.quantity || "").toString().padStart(5, "0")
          let bText = (b.quantity || "").toString().padStart(5, "0")
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.quantity || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.quantity || ""}${!ascending ? "~" : ""}`
          }
          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },
      material(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = a.injected_material?.name || ""
          let bText = b.injected_material?.name || ""
          if (treeView) {
            aText = batchesSortStrings[a.id]?.[`material_${ascending ? "ascending" : "descending"}`] || ""
            bText = batchesSortStrings[b.id]?.[`material_${ascending ? "ascending" : "descending"}`] || ""
          }

          if (ascending) return aText >= bText ? 1 : -1

          return bText >= aText ? 1 : -1
        }
      },
      thickness(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = (a.injected_thickness?.value || "").toString().padStart(5, "0")
          let bText = (b.injected_thickness?.value || "").toString().padStart(5, "0")
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.thickness || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.thickness || ""}${!ascending ? "~" : ""}`
          }
          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },
      process(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = a.process?.name || ""
          let bText = b.process?.name || ""
          if (treeView) {
            aText = batchesSortStrings[a.id]?.[`process_${ascending ? "ascending" : "descending"}`] || ""
            bText = batchesSortStrings[b.id]?.[`process_${ascending ? "ascending" : "descending"}`] || ""
          }
          if (ascending) return aText >= bText ? 1 : -1

          return bText >= aText ? 1 : -1
        }
      },
      reference(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = a.part?.reference || ""
          let bText = b.part?.reference || ""
          if (treeView) {
            aText = batchesSortStrings[a.id]?.[`reference_${ascending ? "ascending" : "descending"}`] || ""
            bText = batchesSortStrings[b.id]?.[`reference_${ascending ? "ascending" : "descending"}`] || ""
          }
          if (ascending) return aText >= bText ? 1 : -1

          return bText >= aText ? 1 : -1
        }
      },
      operations(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = (a.operations?.length || "").toString().padStart(5, "0")
          let bText = (b.operations?.length || "").toString().padStart(5, "0")
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.operations || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.operations || ""}${!ascending ? "~" : ""}`
          }
          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },
      certificates(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = (a.certificates?.length || "").toString().padStart(5, "0")
          let bText = (b.certificates?.length || "").toString().padStart(5, "0")
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.certificates || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.certificates || ""}${!ascending ? "~" : ""}`
          }
          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },
      price(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = (a.unit_amount || "").toString().padStart(10, "0")
          let bText = (b.unit_amount || "").toString().padStart(10, "0")
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.unit_amount || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.unit_amount || ""}${!ascending ? "~" : ""}`
          }
          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },
      total(ascending: boolean) {
        return function (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) {
          let aText = (a.total_amount || "").toString().padStart(10, "0")
          let bText = (b.total_amount || "").toString().padStart(10, "0")
          if (treeView) {
            aText = `${batchesSortStrings[a.id]?.total_amount || ""}${!ascending ? "~" : ""}`
            bText = `${batchesSortStrings[b.id]?.total_amount || ""}${!ascending ? "~" : ""}`
          }
          if (ascending) return aText >= bText ? 1 : -1

          return aText <= bText ? 1 : -1
        }
      },

      boolean__is_valid(ascending: boolean) {
        return (a: MappedInjectedBatchInterface, b: MappedInjectedBatchInterface) => {
          let aText: string | number = +a.is_valid
          let bText: string | number = +b.is_valid
          if (treeView) {
            aText = batchesSortStrings[a.id]?.[`is_valid_${ascending ? "ascending" : "descending"}`] || ""
            bText = batchesSortStrings[b.id]?.[`is_valid_${ascending ? "ascending" : "descending"}`] || ""
          }
          if (ascending) return aText >= bText ? 1 : -1

          return bText >= aText ? 1 : -1
        }
      },
    },
    clientMultiSorting: false,
  }
}

export function getBatchesSortStrings(batches: MappedInjectedBatchInterface[]) {
  const getValueForSort = (value: any = {}, batch: any = {}) => {
    value = {
      filename: `${batch.part?.filename || ""}${batch.id || ""}${value.filename || ""}`,
      name: `${batch.part?.name || ""}${batch.id || ""}${value.name || ""}`,
      quantity: `${(batch.quantity || "").toString().padStart(5, "0")}${batch.id || ""}${value.quantity || ""}`,
      material_ascending: `${batch.injected_material?.name || ""}${
        (batch.part?.id || "") + (batch.part?.name || "") + batch.treeDeep.toString()
      }${value.material_ascending || ""}`,
      material_descending: `${batch.injected_material?.name || ""}${batch.part?.id || ""}${batch.part?.name || ""}${
        value.material_descending || ""
      }~`,
      thickness: `${(batch.injected_thickness?.value || "").toString().padStart(5, "0")}${batch.id || ""}${
        value.thickness || ""
      }`,
      process_ascending: `${batch.process?.name || ""}${
        (batch.part?.id || "") + (batch.part?.name || "") + batch.treeDeep.toString()
      }${value.process_ascending || ""}`,
      process_descending: `${batch.process?.name || ""}${batch.part?.id || ""}${batch.part?.name || ""}${
        value.process_descending || ""
      }~`,
      reference_ascending: `${batch.part?.reference || ""}${
        (batch.part?.id || "") + (batch.part?.name || "") + batch.treeDeep.toString()
      }${value.reference_ascending || ""}`,
      reference_descending: `${batch.part?.reference || ""}${batch.part?.id || ""}${batch.part?.name || ""}${
        value.reference_descending || ""
      }~`,
      operations: `${(batch.operations?.length || 0).toString().padStart(5, "0")}${batch.id || ""}${
        value.operations || ""
      }`,
      certificates: `${(batch.certificates?.length || 0).toString().padStart(5, "0")}${batch.id || ""}${
        value.certificates || ""
      }`,
      unit_amount: `${(batch.unit_amount || "").toString().padStart(10, "0")}${batch.id || ""}${
        value.unit_amount || ""
      }`,
      total_amount: `${(batch.total_amount || "").toString().padStart(10, "0")}${batch.id || ""}${
        value.total_amount || ""
      }`,
      is_valid_ascending: `${+batch.is_valid}${
        (batch.part?.id || "") + (batch.part?.name || "") + batch.treeDeep.toString()
      }${value.is_valid_ascending || ""}`,
      is_valid_descending: `${+batch.is_valid}${batch.part?.id || ""}${batch.part?.name || ""}${
        value.is_valid_descending || ""
      }~`,
      index: `${(getBatchIndex(batches, batch.id) || "").toString().padStart(5, "0")}${batch.id || ""}${value.index || ""}`,
      stock_ascending: `${batch.stock?.name || ""}${
        (batch.part?.id || "") + (batch.part?.name || "") + batch.treeDeep.toString()
      }${value.stock_ascending || ""}`,
      stock_descending: `${batch.stock?.name || ""}${batch.part?.id || ""}${batch.part?.name || ""}${
        value.stock_descending || ""
      }~`,
      times: `${(batch.times?.length || 0).toString().padStart(5, "0")}${batch.id || ""}${value.operations || ""}`,
    }
    if (batch.parent) return getValueForSort(value, batch.parent)

    return value
  }

  return batches.reduce((mapped, value) => {
    mapped[value.id] = getValueForSort(mapped[value.id], value)
    return mapped
  }, {})
}


export function getBatchIndex(batches: MappedInjectedBatchInterface[], id: number) {
  return batches.findIndex(x => x.id == id) + 1
}