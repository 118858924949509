<template>
  <group :id="`group_${props.tree?.index}`" ref="model_group">
    <Transform
      v-if="props.tree?.reference !== null && props.tree?.reference !== undefined"
      class="tree-inline"
      :translation="treeTranslationText"
      :rotation="treeOrientationText"
    >
      <inline
        v-show="modelUrl && props.tree?.reference === props.tree?.index"
        :id="`model_${props.tree?.index}`"
        ref="inline_model"
        :d-e-f="`model_${props.tree?.index}`"
        :map-d-e-f-to-i-d="false"
        onload="console.log"
        :name-space-name="props.tree?.index"
        :url="modelUrl"
        content-type="model/x3d+xml"
      />

      <shape
        v-show="!modelUrl || props.tree?.reference !== props.tree?.index"
        :id="`model_${props.tree?.index}`"
        :use="`model_${props.tree?.reference}`"
      />
    </Transform>

    <tree-inline
      v-for="(component, index) in props.tree?.components"
      :key="index"
      :tree="component"
      :part-id="props.partId"
      @loaded="id => emit('loaded', id)"
      @clicked="e => emit('clicked', e)"
    />
  </group>
</template>

<script lang="ts" setup>
import { onMounted, watchEffect, ref, computed, type Ref } from "vue-demi"
import axios from "axios"
import type { ComponentTree } from "@/interfaces"

const props = defineProps<{ tree: ComponentTree, partId: number }>()

const emit = defineEmits(["loaded", "clicked"])

const inline_model = ref<Ref>(null)
const model_group = ref<Ref>(null)
const modelUrl = ref(null)

const treeTranslationText = computed(() => props.tree?.translation.join(" "))
const treeOrientationText = computed(() => props.tree?.orientation.join(" "))

watchEffect(() => {
  if (props.partId && props.tree?.reference) {
    if (!modelUrl.value) generateModelUrl()
    else if (!props.tree?.components.length) emit("loaded", props.tree?.index)
  }
})

function generateModelUrl() {
  // no need to fetch if already defined
  if (modelUrl.value) return

  axios
    .get(`/v1/parts/${props.partId}/components/${props.tree?.reference}/models/0`)
    .then(({ data }) => {
      const x3dBlob = new Blob([data], { type: "text/plain" })
      modelUrl.value = URL.createObjectURL(x3dBlob)
    })
    .catch()
}

onMounted(() => {
  model_group.value.onclick = (e: X3dMouseEvent) => emit("clicked", e)
})
</script>
