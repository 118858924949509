import { cloneDeep, isPlainObject } from "lodash-es"
import dateToString from "./dateToString"

function mapDatesToStrings(doc: any) {
  if (doc instanceof Date) return dateToString(doc)

  if (Array.isArray(doc)) return doc.map(mapDatesToStrings)

  if (!isPlainObject(doc)) return doc
  const clone = cloneDeep(doc)

  for (const key in clone) clone[key] = mapDatesToStrings(clone[key])

  return clone
}

export default mapDatesToStrings
