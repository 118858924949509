<template>
  <ModalWrapper v-model="isVisible">
    <CModal :visible="isVisible" backdrop="static" @close="isVisible = false">
      <CModalHeader>
        <CModalTitle>
          {{ $t(`${props.address ? "edit" : "add"}_address`) }}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <DynamicFormWrapper :form="form" @submit="onSubmit" />
      </CModalBody>
      <CModalFooter class="justify-content-between">
        <div class="d-flex flex-grow-1">
          <CButton class="me-4" color="secondary" @click="isVisible = false">
            {{ $t("cancel") }}
          </CButton>
        </div>
        <CButton v-if="props.address" class="me-4" color="danger">
          {{ $t("remove") }}
        </CButton>
        <CButton color="success" type="submit" :form="form.id">
          {{ $t("save") }}
        </CButton>
      </CModalFooter>
    </CModal>
  </ModalWrapper>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"
import { useI18n } from "vue-i18n"
import type { Address } from "@/interfaces"
import countries from "../datas/counties.json"
import DynamicFormWrapper from "@/components/dynamicForm/DynamicFormWrapper.vue"
import ModalWrapper from "@/components/modals/ModalWrapper.vue"

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    show?: boolean
    address?: Address
    total?: number // total number of addresses
    showCheckboxes?: boolean
  }>(),
  {
    show: false,
    total: 0,
    showCheckboxes: true,
  }
)

const emit = defineEmits(["close", "submit"])

const form = computed(() => {
  const form: Record<string, any> = {
    id: "address-form",
    fields: {
      id: {
        type: "hidden",
        defaultValue: props.address?.id,
      },
      line_1: {
        type: "text",
        label: i18n.t("address_line_1"),
        placeholder: i18n.t("address_line_1"),
        validations: ["required"],
        defaultValue: props.address?.line_1,
      },
      line_2: {
        type: "text",
        label: i18n.t("address_line_2"),
        placeholder: i18n.t("address_line_2"),
        defaultValue: props.address?.line_2,
      },
      city: {
        type: "text",
        label: i18n.t("city"),
        placeholder: "Amsterdam",
        validations: ["required"],
        defaultValue: props.address?.city,
      },
      province: {
        type: "text",
        label: i18n.t("province"),
        placeholder: "Noord Holland",
        defaultValue: props.address?.province,
      },
      postcode: {
        type: "text",
        label: i18n.t("zip_code"),
        placeholder: "1234 AA",
        validations: ["required"],
        defaultValue: props.address?.postcode,
      },
      country: {
        type: "multiselect",
        label: i18n.t("country"),
        placeholder: i18n.t("select_or_start_typing"),
        defaultValue: props.address?.country ? { value: props.address?.country, label: props.address?.country } : null,
        options: (countries || []).map((v): any => ({ label: v, value: v })),
        selectOptions: {
          multiple: false,
          trackBy: "value",
          label: "label",
          hideSelected: true,
          searchable: true,
          selectLabel: i18n.t("press_enter_select"),
          selectedLabel: i18n.t("selected"),
          deselectLabel: i18n.t("press_enter_remove"),
          closeOnSelect: true,
        },
        validations: ["required"],
      },
    },
    valueProcessor: (values: any) => {
      values.country = values.country?.value || values.country
      return values
    },
  }
  if (props.showCheckboxes) {
    form.fields = {
      ...form.fields,
      // Hans requested that if no address exist, is_shipping and is_billing should be true and cannot be changed
      is_shipping: {
        type: "checkbox",
        label: i18n.t("this_is_default_shipping_address"),
        defaultValue: props.total !== 0 ? props.address?.is_shipping : true,
        disabled: props.total === 0,
      },
      is_billing: {
        type: "checkbox",
        label: i18n.t("this_is_billing_address"),
        defaultValue: props.total !== 0 ? props.address?.is_billing : true,
        disabled: props.total === 0,
      },
    }
  }
  return form
})

const isVisible = computed({
  get: () => props.show,
  set: (v: boolean) => {
    if (v == false) emit("close")
  },
})
const onSubmit = (values: any) => {
  if (props.total === 0) {
    values.is_billing = true
    values.is_shipping = true
  }
  isVisible.value = false
  emit("submit", values)
}
</script>
