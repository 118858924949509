import type { AxiosRequestConfig } from "axios"
import axios from "axios"
import { isArray, forEach } from "lodash-es"
import { FETCH_DATA_LIMIT } from "@/constants"
import { requestStore } from "@/store"
import { sleep } from "@/libraries/helpers"

interface Param {
  url: string
  queryParams?: any
  callback: (data: any[]) => void
  persist?: boolean
  prefetching?: boolean
  offset?: number
  addToRequestStore?: boolean
  allData?: any[]
  runCallbackCondition?: (data?: any[]) => boolean
}

export const paginatedFetch = async ({
  url,
  offset = 0,
  queryParams,
  persist,
  prefetching = false,
  callback,
  addToRequestStore = true,
  allData = [],
  runCallbackCondition = () => true
}: Param) => {
  if (addToRequestStore)
      requestStore.modifyRunningFetches({
        url,
        persist,
        prefetching,
        abortController: new AbortController(),
      })
  const request = requestStore.runningFetches.find(r => r.url === url)
  if (!request) return new Promise(resolve => resolve)

  const params = new URLSearchParams()
  params.append("limit", String(FETCH_DATA_LIMIT))
  params.append("offset", String(offset))
  if (queryParams) {
    for (const key in queryParams) {
      if (!isArray(queryParams[key])) {
        params.append(key, queryParams[key])
        continue
      }
      forEach(queryParams[key], (v) => params.append(key, v))
    }
  }

  const config: AxiosRequestConfig = {
    params,
    signal: request.abortController.signal,
  }
  return await axios.get(url, config).then(async ({ data }) => {
    allData = allData.concat(data)
    if (runCallbackCondition(allData) || data.length < FETCH_DATA_LIMIT) {
      callback(allData)
      allData = []
    }
    if ((data || []).length === FETCH_DATA_LIMIT) {
      await sleep(500) // to give some time for UI update
      await paginatedFetch({
        url,
        offset: offset + FETCH_DATA_LIMIT,
        queryParams,
        persist,
        callback,
        addToRequestStore: false,
        allData,
        runCallbackCondition
      })
    }
  }).catch((err) => err)
  .finally(() => requestStore.removeRunningFetch(url))
}