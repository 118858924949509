<template>
  <div class="wrapped-with-asdffyvxqssvw button-switch">
    <CButton
      v-for="(item, index) in props.items"
      :key="index"
      :color="item.value === inputValue ? 'primary' : 'secondary'"
      type="button"
      @click="inputValue = item.value"
    >
      {{ item.text }}
    </CButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue-demi"

const props = withDefaults(
  defineProps<{
    items?: {
      text: string
      value: boolean
    }[]
    value?: boolean
  }>(),
  {
    items: () => [],
    value: false
  }
)

const emit = defineEmits(["update:model-value"])

const inputValue = computed({
  get: () => props.value,
  set: (v) => emit("update:model-value", v)
})
</script>
